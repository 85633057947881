import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://sirbmdbackend.gaweyan.com/api/pengajuan-barang-jasa";
class PengajuanBarangJasaService {
  async getPengajuanBarangJasaAll() {
    return await axios
      .get(API_URL, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getPengajuanBarangJasaById(data) {
    return await axios
      .get(API_URL + "/" + data.id_pengajuan_barang_jasa, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getPengajuanBarangJasaBySearch(search) {
    return await axios
      .get(
        API_URL,
        { search: search },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getPengajuanBarangJasaByKodeAkun(kode) {
    return await axios
      .get(
        API_URL+"-akun/"+kode,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async submitNewPengajuanBarangJasa(data) {
    console.log(data);
    return await axios
      .post(
        API_URL,
        {
          id_rekening_neraca_rincian_objek: data.id_rekening_neraca_rincian_objek,
          id_rekening_neraca_sub_rincian_objek: data.id_rekening_neraca_sub_rincian_objek,
          ba_srt_nomor: data.ba_srt_nomor,
          ba_srt_tanggal: data.ba_srt_tanggal,
          sertifikat_tkdn_pengajuan_barang_jasa : data.sertifikat_tkdn_pengajuan_barang_jasa,
          persentase_pdn_pengajuan_barang_jasa : data.persentase_pdn_pengajuan_barang_jasa,
          nama_barang_pengajuan_barang_jasa:data.nama_barang_pengajuan_barang_jasa,
          keterangan_pengajuan_barang_jasa:data.keterangan_pengajuan_barang_jasa,
          volume_barang_pengajuan_barang_jasa:data.volume_barang_pengajuan_barang_jasa,
          harga_satuan_barang_pengajuan_barang_jasa:data.harga_satuan_barang_pengajuan_barang_jasa,
          satuan_barang_pengajuan_barang_jasa:data.satuan_barang_pengajuan_barang_jasa,
          kategori_pengajuan_barang_jasa:data.kategori_pengajuan_barang_jasa,
          sumber_dana_pengajuan_barang_jasa:data.sumber_dana_pengajuan_barang_jasa,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error.toJSON();
      });
  }

  async submitEditedPengajuanBarangJasa(data) {
    return await axios
      .put(
        API_URL + "/" + data.id_pengajuan_barang_jasa,
        {
            id_pengajuan_barang_jasa:data.id_pengajuan_barang_jasa,
            id_rekening_neraca_rincian_objek: data.id_rekening_neraca_rincian_objek,
            id_rekening_neraca_sub_rincian_objek: data.id_rekening_neraca_sub_rincian_objek,
            ba_srt_nomor: data.ba_srt_nomor,
            ba_srt_tanggal: data.ba_srt_tanggal,
            sertifikat_tkdn_pengajuan_barang_jasa : data.sertifikat_tkdn_pengajuan_barang_jasa,
            persentase_pdn_pengajuan_barang_jasa : data.persentase_pdn_pengajuan_barang_jasa,
            nama_barang_pengajuan_barang_jasa:data.nama_barang_pengajuan_barang_jasa,
            keterangan_pengajuan_barang_jasa:data.keterangan_pengajuan_barang_jasa,
            volume_barang_pengajuan_barang_jasa:data.volume_barang_pengajuan_barang_jasa,
            harga_satuan_barang_pengajuan_barang_jasa:data.harga_satuan_barang_pengajuan_barang_jasa,
            satuan_barang_pengajuan_barang_jasa:data.satuan_barang_pengajuan_barang_jasa,
            kategori_pengajuan_barang_jasa:data.kategori_pengajuan_barang_jasa,
            sumber_dana_pengajuan_barang_jasa:data.sumber_dana_pengajuan_barang_jasa,
            status_persetujuan_satker_pengajuan_barang_jasa:data.status_persetujuan_satker_pengajuan_barang_jasa,
            status_persetujuan_bpkad_pengajuan_barang_jasa:data.status_persetujuan_bpkad_pengajuan_barang_jasa,
            status_persetujuan_laporan_pengajuan_barang_jasa:data.status_persetujuan_laporan_pengajuan_barang_jasa,
            kode_rekening_neraca_akun:data.kode_rekening_neraca_akun,
            kode_rekening_neraca_kelompok:data.kode_rekening_neraca_kelompok,
            kode_rekening_neraca_jenis:data.kode_rekening_neraca_jenis,
            kode_rekening_neraca_objek:data.kode_rekening_neraca_objek,
            kode_rekening_neraca_rincian_objek:data.kode_rekening_neraca_rincian_objek,
            kode_rekening_neraca_sub_rincian_objek:data.kode_rekening_neraca_sub_rincian_objek,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async submitUploadTemplatePengajuanBarangJasa(data) {
    return await axios
      .post(API_URL + "-template", data, {
        headers: authHeader(),
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async submitDownloadTemplatePengajuanBarangJasa() {
    return await axios
      .get(API_URL + "-template", {
        headers: authHeader(),
        responseType: "blob",
      })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async deletePengajuanBarangJasaById(data) {
    return await axios
      .delete(API_URL + "-delete/" + data.id_pengajuan_barang_jasa, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async deletePengajuanBarangJasaBySelectedIds(ids) {
    console.log(ids);
    return await axios
      .post(
        API_URL + "-delete",
        {
          selected_ids: JSON.stringify(ids),
        },
        {
          headers: authHeader(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // 'Access-Control-Allow-Headers': '*',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async submitDataBulkStorePengajuanBarangJasa(data) {
    return await axios
      .post(API_URL + "-bulk-store", data, {
        headers: authHeader(),
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async submitDataBulkCheckPengajuanBarangJasa(data) {
    return await axios
      .post(API_URL + "-bulk-check", data, {
        headers: authHeader(),
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async konfirmasiBPKADPengajuanBarangJasaBySelectedIds(ids, status) {
    // console.log(ids);
    return await axios
      .post(
        API_URL + "-konfirmasi-bpkad",
        {
          selected_ids: JSON.stringify(ids),
          status_bpkad: status,
        },
        {
          headers: authHeader(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // 'Access-Control-Allow-Headers': '*',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async konfirmasiLaporanPengajuanBarangJasaBySelectedIds(ids, status) {
    // console.log(ids);
    return await axios
      .post(
        API_URL + "-konfirmasi-laporan",
        {
          selected_ids: JSON.stringify(ids),
          status_laporan: status,
        },
        {
          headers: authHeader(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // 'Access-Control-Allow-Headers': '*',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
}
export default new PengajuanBarangJasaService();
