<template>
  <TabView style="margin-top: 20px" scrollable>
    <!-- Panel Akun -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-file-arrow-up" />
        <span>&nbsp; Jurnal Akun</span>
      </template>
      <div class="card">
        <Toolbar
          class="mb-4 flex flex-column md:flex-row md:justify-content-between"
        >
          <template #start>
            <Button
              icon="pi pi-plus"
              label="Tambah Rekening Neraca Akun"
              class="mr-2 my-1 p-button-info"
              @click="rekeningNeracaAkunDialog = true"
            ></Button>
            <Button
              label="Delete"
              icon="pi pi-trash"
              class="p-button-danger my-1"
              @click="rekeningNeracaAkunConfirmationDeleteSelected"
              :disabled="
                !rekeningNeracaAkunSelectingRows ||
                !rekeningNeracaAkunSelectingRows.length
              "
            ></Button>
          </template>

          <template #end>
            <FileUpload
              mode="basic"
              name="rekening_neraca_akun_file[]"
              :maxFileSize="1000000"
              :customUpload="true"
              @uploader="uploadTemplateRekeningNeracaAkun"
              class="my-2 mr-2 p-button-help"
              chooseLabel="Upload Template Akun"
              chooseIcon="pi pi-upload"
            />
            <Button
              label="Download Template Akun"
              icon="pi pi-download"
              class="p-button-help my-1"
              @click="downloadTemplateRekeningNeracaAKun()"
            ></Button>
          </template>
        </Toolbar>
        <DataTable
          :value="rekeningNeracaAkunData"
          v-model:filters="rekeningNeracaAkunFilterPencarian"
          dataKey="id_rekening_neraca_akun"
          filterDisplay="menu"
          :globalFilterFields="[
            'kode_rekening_neraca_akun',
            'nama_rekening_neraca_akun',
            'detail_rekening_neraca_akun',
          ]"
          v-model:editingRows="rekeningNeracaAkunEditingRows"
          @row-edit-save="onRowEditSaveRekeningNeracaAkun"
          responsiveLayout="scroll"
          editMode="row"
          v-model:selection="rekeningNeracaAkunSelectingRows"
          ref="dt"
          :paginator="true"
          :rows="10"
        >
          <template #empty> Belum ada data Rekening Neraca Akun </template>
          <template #loading> Loading . . . </template>
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between"
            >
              <h5 class="mb-2 md:m-0 p-as-md-center">
                Daftar Rekening Neraca Akun
              </h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="rekeningNeracaAkunFilterPencarian['global'].value"
                  placeholder="Cari kode, nama atau detail dari rekening neraca akun"
                />
              </span>
            </div>
          </template>
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            field="kode_rekening_neraca_akun"
            header="Kode Akun"
            footer="Kode Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="nama_rekening_neraca_akun"
            header="Nama Akun"
            footer="Nama Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="detail_rekening_neraca_akun"
            header="Detail Akun"
            footer="Detail Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            :rowEditor="true"
            style="width: 20%; min-width: 10rem"
            bodyStyle="text-align:center"
          >
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Edit Rekening Neraca Akun</Button
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!-- End Panel Akun -->
    <!-- Panel Kelompok -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-file-arrow-up" />
        <span>&nbsp; Jurnal Kelompok</span>
      </template>
      <div class="card">
        <Toolbar
          class="mb-4 flex flex-column md:flex-row md:justify-content-between"
        >
          <template #start>
            <Button
              icon="pi pi-plus"
              label="Tambah Rincian Kelompok"
              class="mr-2 my-1 p-button-info"
              @click="rekeningNeracaKelompokDialog = true"
            ></Button>
            <Button
              label="Delete"
              icon="pi pi-trash"
              class="p-button-danger my-1"
              @click="rekeningNeracaKelompokConfirmationDeleteSelected"
              :disabled="
                !rekeningNeracaKelompokSelectingRows ||
                !rekeningNeracaKelompokSelectingRows.length
              "
            ></Button>
          </template>

          <template #end>
            <FileUpload
              mode="basic"
              name="rekening_neraca_kelompok_file[]"
              :maxFileSize="1000000"
              :customUpload="true"
              @uploader="uploadTemplateRekeningNeracaKelompok"
              class="my-2 mr-2 p-button-help"
              chooseLabel="Upload Template Kelompok"
              chooseIcon="pi pi-upload"
            />
            <Button
              label="Download Template Kelompok"
              icon="pi pi-download"
              class="p-button-help my-1"
              @click="downloadTemplateRekeningNeracaKelompok()"
            ></Button>
          </template>
        </Toolbar>
        <DataTable
          :value="rekeningNeracaKelompokData"
          v-model:filters="rekeningNeracaKelompokFilterPencarian"
          dataKey="id_rekening_neraca_kelompok"
          filterDisplay="menu"
          :globalFilterFields="[
            'kode_rekening_neraca_akun',
            'kode_rekening_neraca_jenis',
            'kode_rekening_neraca_kelompok',
            'nama_rekening_neraca_kelompok',
            'detail_rekening_neraca_kelompok',
          ]"
          v-model:editingRows="rekeningNeracaKelompokEditingRows"
          @row-edit-save="onRowEditSaveRekeningNeracaKelompok"
          responsiveLayout="scroll"
          editMode="row"
          v-model:selection="rekeningNeracaKelompokSelectingRows"
          ref="dt_kelompok"
          :paginator="true"
          :rows="10"
        >
          <template #empty> Belum ada data Rekening Neraca Kelompok </template>
          <template #loading> Loading . . . </template>
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between"
            >
              <h5 class="mb-2 md:m-0 p-as-md-center">
                Daftar Kode dan Detail Rekening Neraca Kelompok
              </h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="
                    rekeningNeracaKelompokFilterPencarian['global'].value
                  "
                  placeholder="Cari kode atau detail rekening neraca kelompok"
                />
              </span>
            </div>
          </template>
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            field="kode_rekening_neraca_akun"
            header="Kode Akun"
            footer="Kode Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_kelompok"
            header="Nama Kelompok"
            footer="Nama Kelompok"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="nama_rekening_neraca_kelompok"
            header="Nama Kelompok"
            footer="Nama Kelompok"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="detail_rekening_neraca_kelompok"
            header="Detail Kelompok"
            footer="Detail Kelompok"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>

          <Column
            :rowEditor="true"
            style="width: 20%; min-width: 10rem"
            bodyStyle="text-align:center"
          >
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Edit Pekerjaan</Button
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!-- End Panel Kelompok -->
    
    <!-- Panel Jenis -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-file-arrow-up" />
        <span>&nbsp; Jurnal Jenis</span>
      </template>
      <div class="card">
        <Toolbar
          class="mb-4 flex flex-column md:flex-row md:justify-content-between"
        >
          <template #start>
            <Button
              icon="pi pi-plus"
              label="Tambah Rekening Neraca Jenis"
              class="mr-2 my-1 p-button-info"
              @click="rekeningNeracaJenisDialog = true"
            ></Button>
            <Button
              label="Delete"
              icon="pi pi-trash"
              class="p-button-danger my-1"
              @click="rekeningNeracaJenisConfirmationDeleteSelected"
              :disabled="
                !rekeningNeracaJenisSelectingRows ||
                !rekeningNeracaJenisSelectingRows.length
              "
            ></Button>
          </template>

          <template #end>
            <FileUpload
              mode="basic"
              name="rekening_neraca_jenis_file[]"
              :maxFileSize="1000000"
              :customUpload="true"
              @uploader="uploadTemplateRekeningNeracaJenis"
              class="my-2 mr-2 p-button-help"
              chooseLabel="Upload Template Jenis"
              chooseIcon="pi pi-upload"
            />
            <Button
              label="Download Template Jenis"
              icon="pi pi-download"
              class="p-button-help my-1"
              @click="downloadTemplateRekeningNeracaJenis()"
            ></Button>
          </template>
        </Toolbar>
        <DataTable
          :value="rekeningNeracaJenisData"
          v-model:filters="rekeningNeracaJenisFilterPencarian"
          dataKey="id_rekening_neraca_jenis"
          filterDisplay="menu"
          :globalFilterFields="[
            'kode_rekening_neraca_akun',
            'kode_rekening_neraca_jenis',
            'nama_rekening_neraca_jenis',
            'detail_rekening_neraca_jenis',
          ]"
          v-model:editingRows="rekeningNeracaJenisEditingRows"
          @row-edit-save="onRowEditSaveRekeningNeracaJenis"
          responsiveLayout="scroll"
          editMode="row"
          v-model:selection="rekeningNeracaJenisSelectingRows"
          ref="dt"
          :paginator="true"
          :rows="10"
        >
          <template #empty> Belum ada data Rekening Neraca Akun </template>
          <template #loading> Loading . . . </template>
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between"
            >
              <h5 class="mb-2 md:m-0 p-as-md-center">
                Daftar Rekening Neraca Akun
              </h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="rekeningNeracaJenisFilterPencarian['global'].value"
                  placeholder="Cari kode, nama atau detail dari rekening neraca akun"
                />
              </span>
            </div>
          </template>
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            field="kode_rekening_neraca_akun"
            header="Kode Akun"
            footer="Kode Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_kelompok"
            header="Kode Kelompok"
            footer="Kode Kelompok"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_jenis"
            header="Kode Jenis"
            footer="Kode Jenis"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="nama_rekening_neraca_jenis"
            header="Nama Jenis"
            footer="Nama Jenis"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="detail_rekening_neraca_jenis"
            header="Detail Jenis"
            footer="Detail Jenis"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            :rowEditor="true"
            style="width: 20%; min-width: 10rem"
            bodyStyle="text-align:center"
          >
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Edit Rekening Neraca Jenis</Button
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!-- End Panel Jenis -->

    <!-- Panel Objek -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-file-arrow-up" />
        <span>&nbsp; Jurnal Objek</span>
      </template>
      <div class="card">
        <Toolbar
          class="mb-4 flex flex-column md:flex-row md:justify-content-between"
        >
          <template #start>
            <Button
              icon="pi pi-plus"
              label="Tambah Rekening Neraca Objek"
              class="mr-2 my-1 p-button-info"
              @click="rekeningNeracaObjekDialog = true"
            ></Button>
            <Button
              label="Delete"
              icon="pi pi-trash"
              class="p-button-danger my-1"
              @click="rekeningNeracaObjekConfirmationDeleteSelected"
              :disabled="
                !rekeningNeracaObjekSelectingRows ||
                !rekeningNeracaObjekSelectingRows.length
              "
            ></Button>
          </template>

          <template #end>
            <FileUpload
              mode="basic"
              name="rekening_neraca_objek_file[]"
              :maxFileSize="1000000"
              :customUpload="true"
              @uploader="uploadTemplateRekeningNeracaObjek"
              class="my-2 mr-2 p-button-help"
              chooseLabel="Upload Template Objek"
              chooseIcon="pi pi-upload"
            />
            <Button
              label="Download Template Objek"
              icon="pi pi-download"
              class="p-button-help my-1"
              @click="downloadTemplateRekeningNeracaObjek()"
            ></Button>
          </template>
        </Toolbar>
        <DataTable
          :value="rekeningNeracaObjekData"
          v-model:filters="rekeningNeracaObjekFilterPencarian"
          dataKey="id_rekening_neraca_objek"
          filterDisplay="menu"
          :globalFilterFields="[
            'kode_rekening_neraca_akun',
            'kode_rekening_neraca_kelompok',
            'kode_rekening_neraca_jenis',
            'kode_rekening_neraca_objek',
            'nama_rekening_neraca_objek',
            'detail_rekening_neraca_objek',
          ]"
          v-model:editingRows="rekeningNeracaObjekEditingRows"
          @row-edit-save="onRowEditSaveRekeningNeracaObjek"
          responsiveLayout="scroll"
          editMode="row"
          v-model:selection="rekeningNeracaObjekSelectingRows"
          ref="dt_rekening_objek"
          :paginator="true"
          :rows="10"
        >
          <template #empty> Belum ada data Rekening Neraca Objek </template>
          <template #loading> Loading . . . </template>
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between"
            >
              <h5 class="mb-2 md:m-0 p-as-md-center">
                Daftar Rekening Neraca Akun
              </h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="rekeningNeracaObjekFilterPencarian['global'].value"
                  placeholder="Cari kode, nama atau detail dari rekening neraca objek"
                />
              </span>
            </div>
          </template>
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            field="kode_rekening_neraca_akun"
            header="Kode Akun"
            footer="Kode Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_kelompok"
            header="Kode Kelompok"
            footer="Kode Kelompok"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_jenis"
            header="Kode Jenis"
            footer="Kode Jenis"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_objek"
            header="Kode Objek"
            footer="Kode Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="nama_rekening_neraca_objek"
            header="Nama Objek"
            footer="Nama Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="detail_rekening_neraca_objek"
            header="Detail Objek"
            footer="Detail Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            :rowEditor="true"
            style="width: 20%; min-width: 10rem"
            bodyStyle="text-align:center"
          >
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Edit Rekening Neraca Objek</Button
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!-- End Panel Objek -->

    <!-- Panel Rincian Objek -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-file-arrow-up" />
        <span>&nbsp; Jurnal Rincian Objek</span>
      </template>
      <div class="card">
        <Toolbar
          class="mb-4 flex flex-column md:flex-row md:justify-content-between"
        >
          <template #start>
            <Button
              icon="pi pi-plus"
              label="Tambah Rekening Neraca Rincian Objek"
              class="mr-2 my-1 p-button-info"
              @click="rekeningNeracaRincianObjekDialog = true"
            ></Button>
            <Button
              label="Delete"
              icon="pi pi-trash"
              class="p-button-danger my-1"
              @click="rekeningNeracaRincianObjekConfirmationDeleteSelected"
              :disabled="
                !rekeningNeracaRincianObjekSelectingRows ||
                !rekeningNeracaRincianObjekSelectingRows.length
              "
            ></Button>
          </template>

          <template #end>
            <FileUpload
              mode="basic"
              name="rekening_neraca_rincian_objek_file[]"
              :maxFileSize="1000000"
              :customUpload="true"
              @uploader="uploadTemplateRekeningNeracaRincianObjek"
              class="my-2 mr-2 p-button-help"
              chooseLabel="Upload Template Objek"
              chooseIcon="pi pi-upload"
            />
            <Button
              label="Download Template Objek"
              icon="pi pi-download"
              class="p-button-help my-1"
              @click="downloadTemplateRekeningNeracaRincianObjek()"
            ></Button>
          </template>
        </Toolbar>
        <DataTable
          :value="rekeningNeracaRincianObjekData"
          v-model:filters="rekeningNeracaRincianObjekFilterPencarian"
          dataKey="id_rekening_neraca_rincian_objek"
          filterDisplay="menu"
          :globalFilterFields="[
            'kode_rekening_neraca_akun',
            'kode_rekening_neraca_kelompok',
            'kode_rekening_neraca_jenis',
            'kode_rekening_neraca_objek',
            'kode_rekening_neraca_rincian_objek',
            'nama_rekening_neraca_rincian_objek',
            'detail_rekening_neraca_rincian_objek',
          ]"
          v-model:editingRows="rekeningNeracaRincianObjekEditingRows"
          @row-edit-save="onRowEditSaveRekeningNeracaRincianObjek"
          responsiveLayout="scroll"
          editMode="row"
          v-model:selection="rekeningNeracaRincianObjekSelectingRows"
          ref="dt_rekening_rincian_objek"
          :paginator="true"
          :rows="10"
        >
          <template #empty> Belum ada data Rekening Neraca Rincian Objek </template>
          <template #loading> Loading . . . </template>
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between"
            >
              <h5 class="mb-2 md:m-0 p-as-md-center">
                Daftar Rekening Neraca Rincian Objek
              </h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="rekeningNeracaRincianObjekFilterPencarian['global'].value"
                  placeholder="Cari kode, nama atau detail dari rekening neraca Rincian objek"
                />
              </span>
            </div>
          </template>
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            field="kode_rekening_neraca_akun"
            header="Kode Akun"
            footer="Kode Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_kelompok"
            header="Kode Kelompok"
            footer="Kode Kelompok"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_jenis"
            header="Kode Jenis"
            footer="Kode Jenis"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_objek"
            header="Kode Objek"
            footer="Kode Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_rincian_objek"
            header="Kode Rincian Objek"
            footer="Kode Rincian Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="nama_rekening_neraca_rincian_objek"
            header="Nama Rincian Objek"
            footer="Nama Rincian Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="detail_rekening_neraca_rincian_objek"
            header="Detail Rincian Objek"
            footer="Detail Rincian Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            :rowEditor="true"
            style="width: 20%; min-width: 10rem"
            bodyStyle="text-align:center"
          >
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Edit Rekening Neraca Rincian Objek</Button
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!-- End Panel Objek -->

    <!-- Panel Rincian Objek -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-file-arrow-up" />
        <span>&nbsp; Jurnal Sub Rincian Objek</span>
      </template>
      <div class="card">
        <Toolbar
          class="mb-4 flex flex-column md:flex-row md:justify-content-between"
        >
          <template #start>
            <Button
              icon="pi pi-plus"
              label="Tambah Rekening Neraca Sub Rincian Objek"
              class="mr-2 my-1 p-button-info"
              @click="rekeningNeracaSubRincianObjekDialog = true"
            ></Button>
            <Button
              label="Delete"
              icon="pi pi-trash"
              class="p-button-danger my-1"
              @click="rekeningNeracaSubRincianObjekConfirmationDeleteSelected"
              :disabled="
                !rekeningNeracaSubRincianObjekSelectingRows ||
                !rekeningNeracaSubRincianObjekSelectingRows.length
              "
            ></Button>
          </template>

          <template #end>
            <FileUpload
              mode="basic"
              name="rekening_neraca_sub_rincian_objek_file[]"
              :maxFileSize="1000000"
              :customUpload="true"
              @uploader="uploadTemplateRekeningNeracaSubRincianObjek"
              class="my-2 mr-2 p-button-help"
              chooseLabel="Upload Template Objek"
              chooseIcon="pi pi-upload"
            />
            <Button
              label="Download Template Objek"
              icon="pi pi-download"
              class="p-button-help my-1"
              @click="downloadTemplateRekeningNeracaSubRincianObjek()"
            ></Button>
          </template>
        </Toolbar>
        <DataTable
          :value="rekeningNeracaSubRincianObjekData"
          v-model:filters="rekeningNeracaSubRincianObjekFilterPencarian"
          dataKey="id_rekening_neraca_sub_rincian_objek"
          filterDisplay="menu"
          :globalFilterFields="[
            'kode_rekening_neraca_akun',
            'kode_rekening_neraca_kelompok',
            'kode_rekening_neraca_jenis',
            'kode_rekening_neraca_objek',
            'kode_rekening_neraca_rincian_objek',
            'kode_rekening_neraca_sub_rincian_objek',
            'nama_rekening_neraca_sub_rincian_objek',
            'detail_rekening_neraca_sub_rincian_objek',
          ]"
          v-model:editingRows="rekeningNeracaSubRincianObjekEditingRows"
          @row-edit-save="onRowEditSaveRekeningNeracaSubRincianObjek"
          responsiveLayout="scroll"
          editMode="row"
          v-model:selection="rekeningNeracaSubRincianObjekSelectingRows"
          ref="dt_rekening_sub_rincian_objek"
          :paginator="true"
          :rows="10"
        >
          <template #empty> Belum ada data Rekening Neraca Sub Rincian Objek </template>
          <template #loading> Loading . . . </template>
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between"
            >
              <h5 class="mb-2 md:m-0 p-as-md-center">
                Daftar Rekening Neraca Sub Rincian Objek
              </h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="rekeningNeracaSubRincianObjekFilterPencarian['global'].value"
                  placeholder="Cari kode, nama atau detail dari rekening neraca Sub Rincian objek"
                />
              </span>
            </div>
          </template>
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            field="kode_rekening_neraca_akun"
            header="Kode Akun"
            footer="Kode Akun"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_kelompok"
            header="Kode Kelompok"
            footer="Kode Kelompok"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_jenis"
            header="Kode Jenis"
            footer="Kode Jenis"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_objek"
            header="Kode Objek"
            footer="Kode Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_rincian_objek"
            header="Kode Rincian Objek"
            footer="Kode Rincian Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="kode_rekening_neraca_sub_rincian_objek"
            header="Kode Sub Rincian Objek"
            footer="Kode Sub Rincian Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="nama_rekening_neraca_sub_rincian_objek"
            header="Nama Sub Rincian Objek"
            footer="Nama Sub Rincian Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="detail_rekening_neraca_sub_rincian_objek"
            header="Detail Sub Rincian Objek"
            footer="Detail Sub Rincian Objek"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            :rowEditor="true"
            style="width: 20%; min-width: 10rem"
            bodyStyle="text-align:center"
          >
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Edit Rekening Neraca Sub Rincian Objek</Button
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!-- End Panel Objek -->
  </TabView>

  <!-- Component Bebas Pakai -->
  <ConfirmDialog></ConfirmDialog>
  <!-- End -->

  <!-- Component untuk Rekening Neraca Akun -->
  <Dialog
    v-model:visible="rekeningNeracaAkunDialog"
    :style="{ width: '80vh' }"
    :header="
      rekeningNeracaAkunBaru
        ? 'Pendaftaran Rekening Neraca Akun'
        : 'Pemutakhiran Data Rekening Neraca Akun'
    "
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!rekeningNeracaAkunBaru">
      <label for="id_rekening_neraca_akun">ID</label>
      <InputText
        id="id_rekening_neraca_akun"
        v-model="rekeningNeracaAkun.id_rekening_neraca_akun"
        disabled
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_akun">Kode Akun</label>
      <InputText
        id="kode_rekening_neraca_akun"
        v-model="rekeningNeracaAkun.kode_rekening_neraca_akun"
        required="true"
      />
    </div>
    <div class="field">
      <label for="nama_rekening_neraca_akun">Nama</label>
      <InputText
        id="nama_rekening_neraca_akun"
        v-model="rekeningNeracaAkun.nama_rekening_neraca_akun"
        required="true"
      />
    </div>
    <div class="field">
      <label for="detail_rekening_neraca_akun">Detail</label>
      <InputText
        id="detail_rekening_neraca_akun"
        v-model="rekeningNeracaAkun.detail_rekening_neraca_akun"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="rekeningNeracaAkunDialog = false"
        style="color: red"
      />
      <Button
        v-if="rekeningNeracaAkunBaru"
        label="Submit Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataBaruRekeningNeracaAkun"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitEditedRekeningNeracaAkun"
      />
    </template>
  </Dialog>
  <!-- End Componen untuk Rekening Neraca Akun -->

  <!-- Component untuk Rekening Neraca Kelompok -->
  <Dialog
    v-model:visible="rekeningNeracaKelompokDialog"
    :style="{ width: '80vh' }"
    :header="
      rekeningNeracaKelompokBaru
        ? 'Pendaftaran Rekening Neraca Kelompok'
        : 'Pemutakhiran Data Rekening Neraca Kelompok'
    "
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!rekeningNeracaKelompokBaru">
      <label for="id_rekening_neraca_kelompok">ID</label>
      <InputText
        id="id_rekening_neraca_kelompok"
        v-model="rekeningNeracaKelompok.id_rekening_neraca_kelompok"
        disabled
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_akun">Kode Akun</label>
      <InputText
        id="kode_rekening_neraca_akun"
        v-model="rekeningNeracaKelompok.kode_rekening_neraca_akun"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_kelompok">Kode Kelompok</label>
      <InputText
        id="kode_rekening_neraca_kelompok"
        v-model="rekeningNeracaKelompok.kode_rekening_neraca_kelompok"
        required="true"
      />
    </div>
    <div class="field">
      <label for="nama_rekening_neraca_kelompok">Nama Kelompok</label>
      <InputText
        id="nama_rekening_neraca_kelompok"
        v-model="rekeningNeracaKelompok.nama_rekening_neraca_kelompok"
        required="true"
      />
    </div>
    <div class="field">
      <label for="detail_rekening_neraca_kelompok">Detail Kelompok</label>
      <InputText
        id="detail_rekening_neraca_kelompok"
        v-model="rekeningNeracaKelompok.detail_rekening_neraca_kelompok"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="rekeningNeracaKelompokDialog = false"
        style="color: red"
      />
      <Button
        v-if="rekeningNeracaKelompokBaru"
        label="Submit Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataBaruRekeningNeracaKelompok"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitEditedRekeningNeracaKelompok"
      />
    </template>
  </Dialog>
  <!-- End Componen untuk Rekening Neraca Jenis -->

  <!-- Component untuk Rekening Neraca Jenis -->
  <Dialog
    v-model:visible="rekeningNeracaJenisDialog"
    :style="{ width: '80vh' }"
    :header="
      rekeningNeracaJenisBaru
        ? 'Pendaftaran Rekening Neraca Jenis'
        : 'Pemutakhiran Data Rekening Neraca Jenis'
    "
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!rekeningNeracaJenisBaru">
      <label for="id_rekening_neraca_jenis">ID</label>
      <InputText
        id="id_rekening_neraca_jenis"
        v-model="rekeningNeracaJenis.id_rekening_neraca_jenis"
        disabled
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_akun">Kode Akun</label>
      <InputText
        id="kode_rekening_neraca_akun"
        v-model="rekeningNeracaJenis.kode_rekening_neraca_akun"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_kelompok">Kode Kelompok</label>
      <InputText
        id="kode_rekening_neraca_kelompok"
        v-model="rekeningNeracaJenis.kode_rekening_neraca_kelompok"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_jenis">Kode Jenis</label>
      <InputText
        id="kode_rekening_neraca_jenis"
        v-model="rekeningNeracaJenis.kode_rekening_neraca_jenis"
        required="true"
      />
    </div>
    <div class="field">
      <label for="nama_rekening_neraca_jenis">Nama Jenis</label>
      <InputText
        id="nama_rekening_neraca_jenis"
        v-model="rekeningNeracaJenis.nama_rekening_neraca_jenis"
        required="true"
      />
    </div>
    <div class="field">
      <label for="detail_rekening_neraca_jenis">Detail Jenis</label>
      <InputText
        id="detail_rekening_neraca_jenis"
        v-model="rekeningNeracaJenis.detail_rekening_neraca_jenis"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="rekeningNeracaJenisDialog = false"
        style="color: red"
      />
      <Button
        v-if="rekeningNeracaJenisBaru"
        label="Submit Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataBaruRekeningNeracaJenis"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitEditedRekeningNeracaJenis"
      />
    </template>
  </Dialog>
  <!-- End Componen untuk Rekening Neraca Jenis -->

  <!-- Component untuk Rekening Neraca Objek -->
  <Dialog
    v-model:visible="rekeningNeracaObjekDialog"
    :style="{ width: '80vh' }"
    :header="
      rekeningNeracaObjekBaru
        ? 'Pendaftaran Rekening Neraca Objek'
        : 'Pemutakhiran Data Rekening Neraca Objek'
    "
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!rekeningNeracaObjekBaru">
      <label for="id_rekening_neraca_objek">ID</label>
      <InputText
        id="id_rekening_neraca_objek"
        v-model="rekeningNeracaObjek.id_rekening_neraca_objek"
        disabled
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_akun">Kode Akun</label>
      <InputText
        id="kode_rekening_neraca_akun"
        v-model="rekeningNeracaObjek.kode_rekening_neraca_akun"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_kelompok">Kode Kelompok</label>
      <InputText
        id="kode_rekening_neraca_kelompok"
        v-model="rekeningNeracaObjek.kode_rekening_neraca_kelompok"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_jenis">Kode Jenis</label>
      <InputText
        id="kode_rekening_neraca_jenis"
        v-model="rekeningNeracaObjek.kode_rekening_neraca_jenis"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_objek">Kode Objek</label>
      <InputText
        id="kode_rekening_neraca_objek"
        v-model="rekeningNeracaObjek.kode_rekening_neraca_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="nama_rekening_neraca_objek">Nama Objek</label>
      <InputText
        id="nama_rekening_neraca_objek"
        v-model="rekeningNeracaObjek.nama_rekening_neraca_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="detail_rekening_neraca_objek">Detail Objek</label>
      <InputText
        id="detail_rekening_neraca_objek"
        v-model="rekeningNeracaObjek.detail_rekening_neraca_objek"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="rekeningNeracaObjekDialog = false"
        style="color: red"
      />
      <Button
        v-if="rekeningNeracaObjekBaru"
        label="Submit Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataBaruRekeningNeracaObjek"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitEditedRekeningNeracaObjek"
      />
    </template>
  </Dialog>
  <!-- End Componen untuk Rekening Neraca Objek -->

  <!-- Component untuk Rekening Neraca Rincian Objek -->
  <Dialog
    v-model:visible="rekeningNeracaRincianObjekDialog"
    :style="{ width: '80vh' }"
    :header="
      rekeningNeracaRincianObjekBaru
        ? 'Pendaftaran Rekening Neraca Rincian Objek'
        : 'Pemutakhiran Data Rekening Neraca Rincian Objek'
    "
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!rekeningNeracaRincianObjekBaru">
      <label for="id_rekening_neraca_rincian_objek">ID</label>
      <InputText
        id="id_rekening_neraca_rincian_objek"
        v-model="rekeningNeracaRincianObjek.id_rekening_neraca_rincian_objek"
        disabled
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_akun">Kode Akun</label>
      <InputText
        id="kode_rekening_neraca_akun"
        v-model="rekeningNeracaRincianObjek.kode_rekening_neraca_akun"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_kelompok">Kode Kelompok</label>
      <InputText
        id="kode_rekening_neraca_kelompok"
        v-model="rekeningNeracaRincianObjek.kode_rekening_neraca_kelompok"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_jenis">Kode Jenis</label>
      <InputText
        id="kode_rekening_neraca_jenis"
        v-model="rekeningNeracaRincianObjek.kode_rekening_neraca_jenis"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_objek">Kode Objek</label>
      <InputText
        id="kode_rekening_neraca_objek"
        v-model="rekeningNeracaRincianObjek.kode_rekening_neraca_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_rincian_objek">Kode Rincian Objek</label>
      <InputText
        id="kode_rekening_neraca_rincian_objek"
        v-model="rekeningNeracaRincianObjek.kode_rekening_neraca_rincian_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="nama_rekening_neraca_rincian_objek">Nama Rincian Objek</label>
      <InputText
        id="nama_rekening_neraca_rincian_objek"
        v-model="rekeningNeracaRincianObjek.nama_rekening_neraca_rincian_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="detail_rekening_neraca_rincian_objek">Detail Rincian Objek</label>
      <InputText
        id="detail_rekening_neraca_rincian_objek"
        v-model="rekeningNeracaRincianObjek.detail_rekening_neraca_rincian_objek"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="rekeningNeracaRincianObjekDialog = false"
        style="color: red"
      />
      <Button
        v-if="rekeningNeracaRincianObjekBaru"
        label="Submit Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataBaruRekeningNeracaRincianObjek"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitEditedRekeningNeracaRincianObjek"
      />
    </template>
  </Dialog>
  <!-- End Componen untuk Rekening Neraca Rincian Objek -->

  <!-- Component untuk Rekening Neraca Sub Rincian Objek -->
  <Dialog
    v-model:visible="rekeningNeracaSubRincianObjekDialog"
    :style="{ width: '80vh' }"
    :header="
      rekeningNeracaSubRincianObjekBaru
        ? 'Pendaftaran Rekening Neraca Sub Rincian Objek'
        : 'Pemutakhiran Data Rekening Neraca Sub Rincian Objek'
    "
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!rekeningNeracaSubRincianObjekBaru">
      <label for="id_rekening_neraca_sub_rincian_objek">ID</label>
      <InputText
        id="id_rekening_neraca_sub_rincian_objek"
        v-model="rekeningNeracaSubRincianObjek.id_rekening_neraca_sub_rincian_objek"
        disabled
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_akun">Kode Akun</label>
      <InputText
        id="kode_rekening_neraca_akun"
        v-model="rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_kelompok">Kode Kelompok</label>
      <InputText
        id="kode_rekening_neraca_kelompok"
        v-model="rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_jenis">Kode Jenis</label>
      <InputText
        id="kode_rekening_neraca_jenis"
        v-model="rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_objek">Kode Objek</label>
      <InputText
        id="kode_rekening_neraca_objek"
        v-model="rekeningNeracaSubRincianObjek.kode_rekening_neraca_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_rincian_objek">Kode Rincian Objek</label>
      <InputText
        id="kode_rekening_neraca_rincian_objek"
        v-model="rekeningNeracaSubRincianObjek.kode_rekening_neraca_rincian_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="kode_rekening_neraca_sub_rincian_objek">Kode Sub Rincian Objek</label>
      <InputText
        id="kode_rekening_neraca_sub_rincian_objek"
        v-model="rekeningNeracaSubRincianObjek.kode_rekening_neraca_sub_rincian_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="nama_rekening_neraca_sub_rincian_objek">Nama Sub Rincian Objek</label>
      <InputText
        id="nama_rekening_neraca_sub_rincian_objek"
        v-model="rekeningNeracaSubRincianObjek.nama_rekening_neraca_sub_rincian_objek"
        required="true"
      />
    </div>
    <div class="field">
      <label for="detail_rekening_neraca_sub_rincian_objek">Detail Sub Rincian Objek</label>
      <InputText
        id="detail_rekening_neraca_sub_rincian_objek"
        v-model="rekeningNeracaSubRincianObjek.detail_rekening_neraca_sub_rincian_objek"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="rekeningNeracaSubRincianObjekDialog = false"
        style="color: red"
      />
      <Button
        v-if="rekeningNeracaSubRincianObjekBaru"
        label="Submit Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataBaruRekeningNeracaSubRincianObjek"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitEditedRekeningNeracaSubRincianObjek"
      />
    </template>
  </Dialog>
  <!-- End Componen untuk Rekening Neraca Sub Rincian Objek -->
</template>
<script>
import UserService from "@/services/user.service";
import RekeningNeracaAkunService from "@/services/rekening-neraca-akun.service";
import RekeningNeracaJenisService from "@/services/rekening-neraca-jenis.service";
import RekeningNeracaKelompokService from "@/services/rekening-neraca-kelompok.service";
import RekeningNeracaObjekService from "@/services/rekening-neraca-objek.service";
import RekeningNeracaRincianObjekService from "@/services/rekening-neraca-rincian-objek.service";
import RekeningNeracaSubRincianObjekService from "@/services/rekening-neraca-sub-rincian-objek.service";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  components: {},
  data() {
    return {
      // base
      user: {
        id: null,
        name: null,
        email: null,
      },
      loaderOverlay: null,
      //basic
      rekeningNeracaDialog: false,
      rekeningNeracaBaru: true,
      rekeningNeracaData: [],
      rekeningNeracaEditingRows: [],
      rekeningNeracaSelectingRows: [],
      rekeningNeracaFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_aset: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        detail_aset: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        jenis_aset: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      // Variabel Rekening Neraca Akun
      rekeningNeracaAkunDialog: false,
      rekeningNeracaAkunBaru: true,
      rekeningNeracaAkunData: [],
      rekeningNeracaAkunEditingRows: [],
      rekeningNeracaAkunSelectingRows: [],
      rekeningNeracaAkunFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        detail_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaAkunDatum: {
        kode_rekening_neraca_akun: null,
        nama_rekening_neraca_akun: null,
        detail_rekening_neraca_akun: null,
      },
      rekeningNeracaAkun: {
        kode_akun: null,
        nama_akun: null,
        detail_akun: null,
      },
      // end
      // Variabel Rekening Neraca Jenis
      rekeningNeracaJenisDialog: false,
      rekeningNeracaJenisBaru: true,
      rekeningNeracaJenisData: [],
      rekeningNeracaJenisEditingRows: [],
      rekeningNeracaJenisSelectingRows: [],
      rekeningNeracaJenisFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        detail_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaJenisDatum: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_jenis: null,
        nama_rekening_neraca_jenis: null,
        detail_rekening_neraca_jenis: null,
      },
      rekeningNeracaJenis: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_jenis: null,
        nama_rekening_neraca_jenis: null,
        detail_rekening_neraca_jenis: null,
      },
      // end
      // Variabel Rekening Neraca Kelompok
      rekeningNeracaKelompokDialog: false,
      rekeningNeracaKelompokBaru: true,
      rekeningNeracaKelompokData: [],
      rekeningNeracaKelompokEditingRows: [],
      rekeningNeracaKelompokSelectingRows: [],
      rekeningNeracaKelompokFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        detail_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaKelompokDatum: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        nama_rekening_neraca_kelompok: null,
        detail_rekening_neraca_kelompok: null,
      },
      rekeningNeracaKelompok: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        nama_rekening_neraca_kelompok: null,
        detail_rekening_neraca_kelompok: null,
      },

      // end
      // Variabel Rekening Neraca Objek
      rekeningNeracaObjekDialog: false,
      rekeningNeracaObjekBaru: true,
      rekeningNeracaObjekData: [],
      rekeningNeracaObjekEditingRows: [],
      rekeningNeracaObjekSelectingRows: [],
      rekeningNeracaObjekFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        detail_rekening_neraca_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaObjekDatum: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        nama_rekening_neraca_objek: null,
        detail_rekening_neraca_objek: null,
      },
      rekeningNeracaObjek: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        nama_rekening_neraca_objek: null,
        detail_rekening_neraca_objek: null,
      },
      // end
      // Variabel Rekening Neraca Rincian Objek
      rekeningNeracaRincianObjekDialog: false,
      rekeningNeracaRincianObjekBaru: true,
      rekeningNeracaRincianObjekData: [],
      rekeningNeracaRincianObjekEditingRows: [],
      rekeningNeracaRincianObjekSelectingRows: [],
      rekeningNeracaRincianObjekFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        detail_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaRincianObjekDatum: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        detail_rekening_neraca_rincian_objek: null,
      },
      rekeningNeracaRincianObjek: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        detail_rekening_neraca_rincian_objek: null,
      },
      // end
      // Variabel Rekening Neraca Sub Rincian Objek
      rekeningNeracaSubRincianObjekDialog: false,
      rekeningNeracaSubRincianObjekBaru: true,
      rekeningNeracaSubRincianObjekData: [],
      rekeningNeracaSubRincianObjekEditingRows: [],
      rekeningNeracaSubRincianObjekSelectingRows: [],
      rekeningNeracaSubRincianObjekFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kode_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        detail_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaSubRincianObjekDatum: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      },
      rekeningNeracaSubRincianObjek: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      },
      // end
    };
  },
  async created() {},
  async mounted() {
    this.loaderOverlay = this.$loading.show();
    // await this.initUsers();

    await this.rekeningNeracaAkunInitialization();
    await this.rekeningNeracaKelompokInitialization();
    await this.rekeningNeracaJenisInitialization();
    await this.rekeningNeracaObjekInitialization();
    await this.rekeningNeracaRincianObjekInitialization();
    await this.rekeningNeracaSubRincianObjekInitialization();

    this.$toast.add({
      severity: "success",
      summary: "Sukses dung",
      detail: "Berhasil loading",
      life: 3000,
    });
    this.loaderOverlay.hide();
    // setTimeout(() => {
    //   this.$toast.add({severity:'success', summary: 'Sukses dung', detail: "Berhasil loading", life: 3000});
    // },
    // 5000);
  },
  methods: {
    //Start bagian Rekening Neraca Akun
    //inisiasi data Rekening Neraca Akun
    async rekeningNeracaAkunInitialization() {
      var data = await RekeningNeracaAkunService.getRekeningNeracaAkunAll();
      this.rekeningNeracaAkunData = data.data;
    },
    //clear variabel dialog
    rekeningNeracaAkunClear() {
      this.rekeningNeracaAkun = {
        id_rekening_neraca_akun: null,
        kode_rekening_neraca_akun: null,
        nama_rekening_neraca_akun: null,
        detail_rekening_neraca_akun: null,
      };
    },
    //fungsi untuk hapus baris yang dipilih
    rekeningNeracaAkunConfirmationDeleteSelected() {
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data terkait 'Rekening Neraca Akun' terpilih?",
        header: "Konfirmas Penghapusan Rekening Neraca Akun",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var selected_ids = [];
          this.rekeningNeracaAkunSelectingRows.forEach((element) => {
            selected_ids.push(element.id_rekening_neraca_akun);
          });
          var response_temp =
            await RekeningNeracaAkunService.deleteRekeningNeracaAkunBySelectedIds(
              selected_ids
            );

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.rekeningNeracaAkunInitialization();
            this.rekeningNeracaAkunDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data 'Rekening Neraca Akun'",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi upload template Rekening Neraca Akun
    async uploadTemplateRekeningNeracaAkun(event) {
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("rekening_neraca_akun_file", event.files[0]);
      var response_temp =
        await RekeningNeracaAkunService.submitUploadTemplateRekeningNeracaAkun(
          formData
        );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.rekeningNeracaAkunInitialization();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data 'Rekening Neraca Akun'",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Akun
    async downloadTemplateRekeningNeracaAKun() {
      this.loaderOverlay = this.$loading.show();
      var response_temp =
        await RekeningNeracaAkunService.submitDownloadTemplateRekeningNeracaAkun();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rekening_neraca_akun_template.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loaderOverlay.hide();
    },
    //fungsi untuk Submit Baru Rekening Neraca Akun
    async submitDataBaruRekeningNeracaAkun() {
      this.loaderOverlay = this.$loading.show();
      var response_data =
        await RekeningNeracaAkunService.submitNewRekeningNeracaAkun(
          this.rekeningNeracaAkun
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaAkunInitialization();
        this.rekeningNeracaAkunDialog = false;
        this.rekeningNeracaAkunClear();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah data 'Rekening Neraca Akun'",
          life: 5000,
        });
      } else {
        this.rekeningNeracaAkunDialog = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi untuk edit tiap baris akun
    async onRowEditSaveRekeningNeracaAkun(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      var response_data =
        await RekeningNeracaAkunService.submitEditedRekeningNeracaAkun(newData);
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaAkunInitialization();
        this.rekeningNeracaAkunData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data 'Rekening Neraca Akun'",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide()
    },
    //End
    //Start bagian Rekening Neraca Jenis
    //inisiasi data Rekening Neraca Jenis
    async rekeningNeracaJenisInitialization() {
      var data = await RekeningNeracaJenisService.getRekeningNeracaJenisAll();
      this.rekeningNeracaJenisData = data.data;
    },
    rekeningNeracaJenisClear() {
      this.rekeningNeracaJenis = {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        nama_rekening_neraca_jenis: null,
        detail_rekening_neraca_jenis: null,
      };
    },
    //fungsi untuk hapus baris yang dipilih Neraca Jenis
    rekeningNeracaJenisConfirmationDeleteSelected() {
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data terkait Rekening Neraca Jenis terpilih?",
        header: "Konfirmasi Penghapusan Rekening Neraca Jenis",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var selected_ids = [];
          this.rekeningNeracaJenisSelectingRows.forEach((element) => {
            selected_ids.push(element.id_rekening_neraca_jenis);
          });
          var response_temp =
            await RekeningNeracaJenisService.deleteRekeningNeracaJenisBySelectedIds(
              selected_ids
            );

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.rekeningNeracaJenisInitialization();
            this.rekeningNeracaJenisDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data 'Rekening Neraca Akun'",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi upload template Rekening Neraca Jenis
    async uploadTemplateRekeningNeracaJenis(event) {
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("rekening_neraca_jenis_file", event.files[0]);
      var response_temp =
        await RekeningNeracaJenisService.submitUploadTemplateRekeningNeracaJenis(
          formData
        );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.rekeningNeracaJenisInitialization();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data Rekening Neraca Jenis",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Jenis
    async downloadTemplateRekeningNeracaJenis() {
      this.loaderOverlay = this.$loading.show();
      var response_temp =
        await RekeningNeracaJenisService.submitDownloadTemplateRekeningNeracaJenis();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rekening_neraca_jenis_template.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loaderOverlay.hide();
    },
    //fungsi untuk Submit Baru Rekening Neraca Jenis
    async submitDataBaruRekeningNeracaJenis() {
      this.loaderOverlay = this.$loading.show();
      var response_data =
        await RekeningNeracaJenisService.submitNewRekeningNeracaJenis(
          this.rekeningNeracaJenis
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaJenisInitialization();
        this.rekeningNeracaJenisDialog = false;
        this.rekeningNeracaJenisClear();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah data Rekening Neraca Jenis",
          life: 5000,
        });
      } else {
        this.rekeningNeracaJenisDialog = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi untuk edit tiap baris Neraca Jenis
    async onRowEditSaveRekeningNeracaJenis(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      var response_data =
        await RekeningNeracaJenisService.submitEditedRekeningNeracaJenis(
          newData
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaJenisInitialization();
        this.rekeningNeracaJenisData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data 'Rekening Neraca Akun'",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    //End
    //Start bagian Rekening Neraca Kelompok
    //inisiasi data Rekening Neraca Kelompok
    async rekeningNeracaKelompokInitialization() {
      var data =
        await RekeningNeracaKelompokService.getRekeningNeracaKelompokAll();
      this.rekeningNeracaKelompokData = data.data;
    },
    rekeningNeracaKelompokClear() {
      this.rekeningNeracaKelompok = {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        nama_rekening_neraca_kelompok: null,
        detail_rekening_neraca_kelompok: null,
      };
    },
    //fungsi untuk hapus baris yang dipilih Neraca Kelompok
    rekeningNeracaKelompokConfirmationDeleteSelected() {
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data terkait Rekening Neraca Kelompok terpilih?",
        header: "Konfirmasi Penghapusan Rekening Neraca Kelompok",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var selected_ids = [];
          this.rekeningNeracaKelompokSelectingRows.forEach((element) => {
            selected_ids.push(element.id_rekening_neraca_kelompok);
          });
          var response_temp =
            await RekeningNeracaKelompokService.deleteRekeningNeracaKelompokBySelectedIds(
              selected_ids
            );

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.rekeningNeracaKelompokInitialization();
            this.rekeningNeracaKelompokDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data Rekening Neraca Kelompok",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi upload template Rekening Neraca Kelompok
    async uploadTemplateRekeningNeracaKelompok(event) {
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("rekening_neraca_kelompok_file", event.files[0]);
      var response_temp =
        await RekeningNeracaKelompokService.submitUploadTemplateRekeningNeracaKelompok(
          formData
        );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.rekeningNeracaKelompokInitialization();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data Rekening Neraca Kelompok",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Kelompok
    async downloadTemplateRekeningNeracaKelompok() {
      this.loaderOverlay = this.$loading.show();
      var response_temp =
        await RekeningNeracaKelompokService.submitDownloadTemplateRekeningNeracaKelompok();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rekening_neraca_kelompok_template.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loaderOverlay.hide();
    },
    //fungsi untuk Submit Baru Rekening Neraca Kelompok
    async submitDataBaruRekeningNeracaKelompok() {
      this.loaderOverlay = this.$loading.show();
      var response_data =
        await RekeningNeracaKelompokService.submitNewRekeningNeracaKelompok(
          this.rekeningNeracaKelompok
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaKelompokInitialization();
        this.rekeningNeracaKelompokDialog = false;
        this.rekeningNeracaKelompokClear();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah data Rekening Neraca Kelompok",
          life: 5000,
        });
      } else {
        this.rekeningNeracaKelompokDialog = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi untuk edit tiap baris Neraca Kelompok
    async onRowEditSaveRekeningNeracaKelompok(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      var response_data =
        await RekeningNeracaKelompokService.submitEditedRekeningNeracaKelompok(
          newData
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaKelompokInitialization();
        this.rekeningNeracaKelompokData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data Rekening Neraca Kelompok",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    //End
    //Start bagian Rekening Neraca Objek
    //inisiasi data Rekening Neraca Objek
    async rekeningNeracaObjekInitialization() {
      var data =
        await RekeningNeracaObjekService.getRekeningNeracaObjekAll();
      this.rekeningNeracaObjekData = data.data;
    },
    rekeningNeracaObjekClear() {
      this.rekeningNeracaObjek = {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        nama_rekening_neraca_objek: null,
        detail_rekening_neraca_objek: null,
      };
    },
    //fungsi untuk hapus baris yang dipilih Neraca Objek
    rekeningNeracaObjekConfirmationDeleteSelected() {
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data terkait Rekening Neraca Objek terpilih?",
        header: "Konfirmasi Penghapusan Rekening Neraca Objek",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var selected_ids = [];
          this.rekeningNeracaObjekSelectingRows.forEach((element) => {
            selected_ids.push(element.id_rekening_neraca_objek);
          });
          var response_temp =
            await RekeningNeracaObjekService.deleteRekeningNeracaObjekBySelectedIds(
              selected_ids
            );

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.rekeningNeracaObjekInitialization();
            this.rekeningNeracaObjekDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data Rekening Neraca Objek",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi upload template Rekening Neraca Objek
    async uploadTemplateRekeningNeracaObjek(event) {
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("rekening_neraca_objek_file", event.files[0]);
      var response_temp =
        await RekeningNeracaObjekService.submitUploadTemplateRekeningNeracaObjek(
          formData
        );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.rekeningNeracaObjekInitialization();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data Rekening Neraca Kelompok",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Objek
    async downloadTemplateRekeningNeracaObjek() {
      this.loaderOverlay = this.$loading.show();
      var response_temp =
        await RekeningNeracaObjekService.submitDownloadTemplateRekeningNeracaObjek();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rekening_neraca_objek_template.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loaderOverlay.hide();
    },
    //fungsi untuk Submit Baru Rekening Neraca Objek
    async submitDataBaruRekeningNeracaObjek() {
      this.loaderOverlay = this.$loading.show();
      var response_data =
        await RekeningNeracaObjekService.submitNewRekeningNeracaObjek(
          this.rekeningNeracaObjek
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaObjekInitialization();
        this.rekeningNeracaObjekDialog = false;
        this.rekeningNeracaObjekClear();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah data Rekening Neraca Objek",
          life: 5000,
        });
      } else {
        this.rekeningNeracaObjekDialog = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi untuk edit tiap baris Neraca Objek
    async onRowEditSaveRekeningNeracaObjek(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      var response_data =
        await RekeningNeracaObjekService.submitEditedRekeningNeracaObjek(
          newData
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaObjekInitialization();
        this.rekeningNeracaObjekData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data Rekening Neraca Objek",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    //End
    //Start bagian Rekening Neraca Rincian Objek
    //inisiasi data Rekening Neraca Rincian Objek
    async rekeningNeracaRincianObjekInitialization() {
      var data =
        await RekeningNeracaRincianObjekService.getRekeningNeracaRincianObjekAll();
      this.rekeningNeracaRincianObjekData = data.data;
    },
    rekeningNeracaRincianObjekClear() {
      this.rekeningNeracaRincianObjek = {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        detail_rekening_neraca_rincian_objek: null,
      };
    },
    //fungsi untuk hapus baris yang dipilih Neraca Rincian Objek
    rekeningNeracaRincianObjekConfirmationDeleteSelected() {
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data terkait Rekening Neraca Rincian Objek terpilih?",
        header: "Konfirmasi Penghapusan Rekening Neraca Rincian Objek",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var selected_ids = [];
          this.rekeningNeracaRincianObjekSelectingRows.forEach((element) => {
            selected_ids.push(element.id_rekening_neraca_rincian_objek);
          });
          var response_temp =
            await RekeningNeracaRincianObjekService.deleteRekeningNeracaRincianObjekBySelectedIds(
              selected_ids
            );

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.rekeningNeracaRincianObjekInitialization();
            this.rekeningNeracaRincianObjekDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data Rekening Neraca Rincian Objek",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi upload template Rekening Neraca Rincian Objek
    async uploadTemplateRekeningNeracaRincianObjek(event) {
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("rekening_neraca_rincian_objek_file", event.files[0]);
      var response_temp =
        await RekeningNeracaRincianObjekService.submitUploadTemplateRekeningNeracaRincianObjek(
          formData
        );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.rekeningNeracaRincianObjekInitialization();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data Rekening Neraca Kelompok",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Rincian Objek
    async downloadTemplateRekeningNeracaRincianObjek() {
      this.loaderOverlay = this.$loading.show();
      var response_temp =
        await RekeningNeracaRincianObjekService.submitDownloadTemplateRekeningNeracaRincianObjek();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rekening_neraca_rincian_objek_template.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loaderOverlay.hide();
    },
    //fungsi untuk Submit Baru Rekening Neraca Rincian Objek
    async submitDataBaruRekeningNeracaRincianObjek() {
      this.loaderOverlay = this.$loading.show();
      var response_data =
        await RekeningNeracaRincianObjekService.submitNewRekeningNeracaRincianObjek(
          this.rekeningNeracaRincianObjek
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaRincianObjekInitialization();
        this.rekeningNeracaRincianObjekDialog = false;
        this.rekeningNeracaRincianObjekClear();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah data Rekening Neraca Rincian Objek",
          life: 5000,
        });
      } else {
        this.rekeningNeracaRincianObjekDialog = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi untuk edit tiap baris Neraca Rincian Objek
    async onRowEditSaveRekeningNeracaRincianObjek(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      var response_data =
        await RekeningNeracaRincianObjekService.submitEditedRekeningNeracaRincianObjek(
          newData
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaRincianObjekInitialization();
        this.rekeningNeracaRincianObjekData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data Rekening Neraca Rincian Objek",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    //End
    //Start bagian Rekening Neraca Sub Rincian Objek
    //inisiasi data Rekening Neraca Sub Rincian Objek
    async rekeningNeracaSubRincianObjekInitialization() {
      var data =
        await RekeningNeracaSubRincianObjekService.getRekeningNeracaSubRincianObjekAll();
      this.rekeningNeracaSubRincianObjekData = data.data;
    },
    rekeningNeracaSubRincianObjekClear() {
      this.rekeningNeracaSubRincianObjek = {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      };
    },
    //fungsi untuk hapus baris yang dipilih Neraca Sub Rincian Objek
    rekeningNeracaSubRincianObjekConfirmationDeleteSelected() {
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data terkait Rekening Neraca Sub Rincian Objek terpilih?",
        header: "Konfirmasi Penghapusan Rekening Neraca Sub Rincian Objek",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var selected_ids = [];
          this.rekeningNeracaSubRincianObjekSelectingRows.forEach((element) => {
            selected_ids.push(element.id_rekening_neraca_sub_rincian_objek);
          });
          var response_temp =
            await RekeningNeracaSubRincianObjekService.deleteRekeningNeracaSubRincianObjekBySelectedIds(
              selected_ids
            );

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.rekeningNeracaSubRincianObjekInitialization();
            this.rekeningNeracaSubRincianObjekDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data Rekening Neraca Sub Rincian Objek",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi upload template Rekening Neraca Sub Rincian Objek
    async uploadTemplateRekeningNeracaSubRincianObjek(event) {
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("rekening_neraca_sub_rincian_objek_file", event.files[0]);
      var response_temp =
        await RekeningNeracaSubRincianObjekService.submitUploadTemplateRekeningNeracaSubRincianObjek(
          formData
        );
      if (response_temp.status != null && response_temp.status == "success") {
        await this.rekeningNeracaSubRincianObjekInitialization();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data Rekening Neraca Kelompok",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Sub Rincian Objek
    async downloadTemplateRekeningNeracaSubRincianObjek() {
      this.loaderOverlay = this.$loading.show();
      var response_temp =
        await RekeningNeracaSubRincianObjekService.submitDownloadTemplateRekeningNeracaSubRincianObjek();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "rekening_neraca_sub_rincian_objek_template.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loaderOverlay.hide();
    },
    //fungsi untuk Submit Baru Rekening Neraca Sub Rincian Objek
    async submitDataBaruRekeningNeracaSubRincianObjek() {
      this.loaderOverlay = this.$loading.show();
      var response_data =
        await RekeningNeracaSubRincianObjekService.submitNewRekeningNeracaSubRincianObjek(
          this.rekeningNeracaSubRincianObjek
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaSubRincianObjekInitialization();
        this.rekeningNeracaSubRincianObjekDialog = false;
        this.rekeningNeracaSubRincianObjekClear();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambah data Rekening Neraca Sub Rincian Objek",
          life: 5000,
        });
      } else {
        this.rekeningNeracaSubRincianObjekDialog = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    // fungsi untuk edit tiap baris Neraca Sub Rincian Objek
    async onRowEditSaveRekeningNeracaSubRincianObjek(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      var response_data =
        await RekeningNeracaSubRincianObjekService.submitEditedRekeningNeracaSubRincianObjek(
          newData
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaSubRincianObjekInitialization();
        this.rekeningNeracaSubRincianObjekData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data Rekening Neraca Sub Rincian Objek",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    //End
  },
};
</script>
<style lang="css" scoped>
/** tabs **/
ul.p-tabview-nav {
  height: 200px;
}
/** panels **/
.ui-tabs.ui-tabs-left > .ui-tabs-panels {
  width: 90%;
}
</style>
