import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://sirbmdbackend.gaweyan.com/api";
class UserService {
  async getUserDetail() {
    return await axios
      .get(API_URL + "/me", { headers: authHeader() })
      .then((response) => {
        localStorage.setItem("details", JSON.stringify(response.data));
        return response;
      })
      .catch(function (error) {
        // console.log(error.toJSON());
        return 0;
      });
  }
  async getUsers() {
    return await axios
      .get(API_URL + "/users", {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        // console.log(error.toJSON());
        return 0;
      });
  }
  async getUsersBySearch(search = null, assigned = null) {
    return await axios
      .get(API_URL + `/users-search/?search=${search}&assigned=${assigned}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        // console.log(error.toJSON());
        return 0;
      });
  }
  async submitUserBaru(user) {
    return await axios
      .post(
        API_URL + "/users",
        {
          name: user.name,
          email: user.email,
          password: user.password,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error.toJSON();
      });
  }

  async submitPemutakhiranUser(user) {
    return await axios
      .put(
        API_URL + "/users/" + user.id,
        {
          id: user.id,
          name: user.name,
          email: user.email,
          password: user.password,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async deleteUserById(user) {
    return await axios
      .delete(API_URL + "/users/" + user.id, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
}
export default new UserService();
