<template>
  <main id="app">
    <header id="header">
      <div class="logo">
        <img id="header-img" src="@/assets/logo.png" alt="logo">
        <p class="title">SIR BMD NEO</p>
      </div>
      <nav id="nav-bar">
        <ul class="nav-link">
          <li><a href="#home">Home</a></li>
          <li><a href="#tujuan_sirbmd">Tujuan</a></li>
          <li><a href="#fitur_sirbmd">Fitur</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header>
    <section id="home">
      <div class="home"> 
        <div class="home_text">
          <h1>Aplikasi Manajemen dan Pengajuan Barang Jasa</h1>
          <p>Sistem terintegrasi dalam pengajuan barang jasa disertakan pengaturan jurnal dan rekap data. Tangkap informasi cepat dalam pengajuan barang dan jasa untuk pemulihan ekonomi melalui program domestik yang terawasi!</p>
            <a @click="login" class="home_btn">Login ke Aplikasi</a>
          
        </div>
        <div class="home_img">
          <img src="https://www.wordstream.com/wp-content/uploads/2021/11/asset-1.png" alt="succulent">
        </div>
      </div>
    </section>
    <div id="tujuan_sirbmd"></div>
    <div class="container">
      <section class="tujuan_sirbmd">
        <div class="column">
          <div class="icon"><img src="@/assets/accounting.png" alt="akuntabilitas">
          </div>
          <div class="detail">
            <h3>Akuntabel</h3>
            <p>Data yang tepat serta dapat dipertanggungjawabi</p>
          </div>
        </div>
        <div class="column">
          <div class="icon"><img src="@/assets/distributed.png" alt="integritas">
          </div>
          <div class="detail">
            <h3>Kolaboratif</h3>
            <p>Kolaborasi satuan kerja</p>
          </div>
        </div>
        <div class="column">
          <div class="icon"><img src="@/assets/accuracy.png" alt="akurasi">
          </div>
          <div class="detail">
            <h3>Akurat</h3>
            <p>Tepat sasaran guna dan manfaat untuk masyarakat</p>
          </div>
        </div>
      </section>
      <section class="video" id="video">
        <a class="play_btn" target="_blank">
          <!-- <img alt="noun_play"> -->
        </a>    
      </section>
      
      <section id="fitur_sirbmd">
        <div>
          <h2>Fitur</h2>
        </div>
        <div id="products">
        <div class="product" id="succulent1">
          <img src="https://images.unsplash.com/photo-1519915028121-7d3463d20b13?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80" alt="">
          <div class="plants">Dashboard</div>
          <p>Pengamatan cepat terhadap realisasi penggunaan berdasarkan komponen dan waktu</p>
          <!-- <h3>105 TL</h3>
          <button class="btn">Buy</button> -->
        </div>
        <div class="product" id="succulent2">
          <img src="https://images.unsplash.com/photo-1579847695062-a62e5b78ad4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80" alt="">
          <div class="plants">Jurnal Rekening Neraca</div>
          <p>Pemutakhiran Jurnal dari rekening neraca terbaru</p>
          <!-- <h3>100 TL</h3>
          <button class="btn">Buy</button> -->
        </div>
        <div class="product" id="succulent3">
          <img src="https://images.unsplash.com/photo-1551894116-15e3812343f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80" alt="">
          <div class="plants">Manajemen Pengajuan</div>
          <p>Pengajuan, Konfirmasi dan Perekapan dari berbagai pengajuan barang dan jasa.</p>
          <!-- <h3>96 TL</h3>
          <button class="btn">Buy</button> -->
        </div>
        <div class="product" id="succulent4">
          <img src="https://images.unsplash.com/photo-1513682121497-80211f36a7d3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1376&q=80" alt="">
          <div class="plants">Manajemen Pengguna</div>
          <p>Pengaturan pengguna dari berbagai satuan kerja</p>
          <!-- <h3>87 TL</h3>
          <button class="btn">Buy</button> -->
        </div>
        </div>
      </section>
      <div id="contact"></div>
      <section id="contact_us">
        <div>
          <h2>Contact us</h2>
        </div>
        <div>
          <!-- <form id="form" class="contact_form" action="https://www.freecodecamp.com/email-submit">
            <div class="row">
              <input type="text" name="name" id="name" placeholder="Name" required>
              <input type="email" name="email" id="email" placeholder="Email address" required></div>
            <div><textarea name="message" id="message" placeholder="Your message..."></textarea></div>
            <div><button type="submit" id="submit" class="btn">Send</button></div>
          </form> -->
          <!-- <div class="cards"> -->
            <div class="services">
                    <div class="content content-1">
                      <div class="pi pi-facebook">
            </div>
            <h2>
            Facebook</h2>
            <p>
            Facebook is a social networking site that easy to connect with family and friends and created in 2004. </p>
            <a href="#">Read More</a>
                    </div>
            <div class="content content-2">
                      <div class="pi pi-whatsapp">
            </div>
            <h2>
            WhatsApp</h2>
            <p>
            WhatsApp is a messaging app that lets users text, chat, and share media, including voice messages and video</p>
            <a href="#">Read More</a>
                    </div>
            <div class="content content-3">
                      <div class="pi pi-youtube">
            </div>
            <h2>
            Youtube</h2>
            <p>
            YouTube is a free video sharing site that easy to watch online and create and upload your own videos.</p>
            <a href="#">Read More</a>
                    </div>
            </div>
            <!-- </div> -->
        </div>
      </section>
      <footer>
        <ul>
          <li><a href="#">Privacy</a></li>
          <li><a href="#">Terms</a></li>
        </ul>
        <span>&copy 2022 Jhonathan Putro Siahaan</span>
      </footer>
    </div>
  </main>
</template>

<script>
  export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    login() {
      this.changeRoute("/login");
    },

    changeRoute(route) {
      // `route` is either a string or object
      this.$router.push(route);
    },
  },
};
</script>

<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->
<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500;700&family=Montserrat:ital,wght@0,400;0,500;1,400;1,500&display=swap');

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    max-width: 100%;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #000;
}

a {
    text-decoration: none;
    color: #ecfef8;
}

li { 
    list-style: none;
}

header {
    background-color:#061d1a;
    width: 100%;
    display: flex;
    position: fixed;
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: 400;
    padding: 0.5em 1.5em;
    z-index: 10;
    box-shadow: 0 0.2em 0.5em #061d1a71;
}

#nav-bar { 
    align-self: center; 
}

.nav-link {
    display: flex;
    flex-flow: row wrap;
}

.nav-link li { 
    padding: 0 1em;
}

@media screen and (max-width: 62em) { 
    .nav-link { 
        justify-content: space-evenly; 
    } 
}

.logo {
    display: flex;
    align-items: center;
    padding: 0.2em 1em;
}

#header-img {
    width: 2em;
    margin-right: 7em;
}

.title {
    color: #ecfef8;
    position: absolute;
    text-transform: capitalize;
    margin: 0 1em 0 2.2em;
    font-size: 24px;
}

.home {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
    align-items: center;
    background: #f2d986;
    z-index: 1;
}

.home_text {
    background-color: #00919b91;
    color: #ecfef8;
    text-align: left;
    width: 60%;
    margin: 3em 3em 1em 3em;
    padding: 2.2em 1.6em 0 3em;
}

.home_text h1 {
    font-family: 'Caveat', cursive;
    font-size: 70px;
    z-index: 1;
    padding-bottom: 0.25em;
}

.home_text p {
    z-index: 1;
    margin-bottom: 3.2em;
    line-height: 1.5;
}

.home_img {
    display: flex;
    position: absolute;
    top: 3em;
    bottom: 0;
    right: 0.3em;
    z-index: -1;
}

.home_btn {
    background-color: #aa5d27;
    padding: 0.625em;
    border-radius: 4px;
    text-transform: capitalize;
}

.home_btn:hover {
    background-color: #9b531f;
}

@media screen and (max-width: 62em) { 
    .home_text {
        width: 75%;
        margin: 1em 2em;
        padding: 2.2em 1.6em 0 2em;
    }
    .home_text h1{
        font-size: 60px;
        line-height: 1;
    }
}

@media screen and (max-width: 32em) { 
    .home_text {
        width: 90%;
        margin: 5em 1em 1em 1em;
        padding: 2.2em 1.6em 0 1.6em;
    }
    .home_text h1 {
        font-size: 50px;
    }
}

.container {
    width: 100%;
    position: relative;
    
}

.tujuan_sirbmd {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 6em 3em;
    background-color:#ecfef8;
}

.icon img {
    width: 2.5em;
    display: flex;
    position: absolute;
}

.detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4em;
}

@media screen and (max-width: 62em) { 
    .tujuan_sirbmd {
        display: inline-flex;
        flex-wrap: wrap;
    }
    .column {
        display: flex;
        justify-content: center;
        margin-top: 2em;
        margin-bottom: 2em;
        padding-right: 1em;
    }
}

/* Video section*/
.video {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    background: url('https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Jembatan_Kahayan_Palangkaraya.jpg/1920px-Jembatan_Kahayan_Palangkaraya.jpg') center center no-repeat;
    background-size: cover;
}

.play_btn {
    display: flex;
    z-index: 1;
    margin: 20% auto;
    justify-content: center;
}

.play_btn img {
    width: 100px;
    border: 2px solid transparent;
    border-radius: 50%;
    outline: #ecfef8 solid 2px;
}

.play_btn > img:hover{
    transform: scale(1.1);
    transition: transform 0.5s ease;
}

@media screen and (max-width: 62em) { 
    .play_btn img{
        width: 75px;
    }
}

/* product section */ 
#fitur_sirbmd h2 {
    display: flex;
    position: relative;
    font-family: 'Caveat', cursive;
    font-size: 60px;
    text-align: center;
    padding-top: 2em;
    justify-content: center;
    background-color: #fafafa;
}

#products {
    display: flex;
    position: relative;
    justify-content: space-evenly;
    width: 100%;
    background-color: #fafafa;
    padding: 5em 3em 10em 3em;
    gap: 2em;
}

.product {
    width: 22%;
    border: 1px solid #ecfef8;
    border-radius: 5px;
    text-align: center;
    background-color: #ecfef8;
    box-shadow: 0 0.2em 0.5em #061d1a71;
}

.product img {
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    padding-bottom: 0.8em;
}

.plants {
    font-size: 20px;
    font-weight: 500;
    margin: 1em 0;
}

.product h3 {
    margin: 1.1em 0 0.8em 0;
    font-weight: 500;
    font-size: 20px;
}

.product p {
    margin: 0.5em 1em;
}

.btn {
    width: 40%;
    height: 2.5em;
    background-color: #00919bd0;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 1em;
    border-color: #00919b91;
    color: #ffffff;
}

.btn:hover {
    background-color: #00919b;
}

@media screen and (max-width: 62em) { 
    #products {
        flex-wrap: wrap;
        padding: 3em 1em 8em 1em;
    }
    .product {
        width: 40%;
    }
}

@media screen and (max-width: 32em) {
    .product {
        width: 70%;
    }
}

#contact_us {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #e0edeb;
    padding-top: 5%;
    padding-bottom: 20%;
    background-size: cover;
    background-image: url('https://images.unsplash.com/photo-1528732262645-b06fa3a79c9e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80');
}

#contact_us h2 {
    font-family: 'Caveat', cursive;
    color: #fafafa;
    font-size: 60px;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 1em;
    text-shadow: 0.06em 0.06em 0.12em #061d1a71;
}

.contact_form {
    width: 60%;
    height: 75%;
    background-color: transparent;
    border-radius: 7px;
    padding: 3em 1.5em 2em 1.5em;
    margin: 0 auto;
    box-shadow: 0.1em 0.1em 2em #fab76a;
}

@media screen and (max-width: 62em) {
    #contact_us {
        padding-bottom: 25%;
    }
    .contact_form {
        width: 80%;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

input,textarea {
    padding: 0.8em;
    font-size: 16px;
    border-radius: 5px;
    outline: none;
    background-color: #fafafa;
    font-family: 'Montserrat', sans-serif;
}
input {
    width: 40%;
    margin-bottom: 2em;
}

textarea {
    width: 86.7%;
    height: 30vh;
    resize: vertical;
    max-height: 300px;
    margin: 0 6.625%;
}

#submit {
    margin-top: 2em;
    margin-left: 58.3%;
    width: 35%;
    background-color: #aa5d27;
    color: #fafafa;
    border: none;
    box-shadow: 0 0.1em 0.3em #061d1a71;
}

#submit:hover {
    background-color: #9b531f;
}

@media screen and (max-width: 62em) { 
    .row {
        flex-direction: column;
        margin-top: 1em;
    }
    input {
        width: 86.7%;
        margin: 0 6.625% 4% 6.625%;
    }
}

footer {
    width: 100%;
    display: flex;
    position: relative;
    margin-top: auto;
    padding: 0.5em 1em;
    background-color: #061d1a;
    text-align: left;
    justify-content: space-between;
}

footer li {
    color: #ecfef8;
    display: inline-block;
    padding: 1em 0.5em;
}
span {
    color: #ecfef8;
    padding: 1em 0.2em 1em 0.5em;
}

// BAGIAN social media
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800|Poppins&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat',sans-serif;
}
.cards{
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}
.cards h1{
  font-size: 40px;
  margin: 0 0 30px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.services{
  display: flex;
  align-items: center;
}
.content{
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: 20px;
  padding: 10px;
  border: 2px solid black;
  border-radius: 4px;
  transition: all .3s ease;
}
.content .pi{
  font-size: 70px;
  // margin: 16px 0;
}
.content > *{
  flex: 1 1 100%;
}
.content:hover{
  color: white;
}
.content:hover a{
  border-color: white;
  background: white;
}
.content-1:hover{
  border-color: #4267B2;
  background: #4267B2;
}
.content-1:hover a{
  color: #4267B2;
}
.content-2:hover{
  border-color: #075e54;
  background: #075e54;
}
.content-2:hover a{
  color: #075e54;
}
.content-3:hover{
  border-color: #ff0000;
  background: #ff0000;
}
.content-3:hover a{
  color: #ff0000;
}
.content h2{
  font-size: 30px;
  margin: 16px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.content p{
  font-size: 17px;
  font-family: 'Poppins',sans-serif;
}
.content a{
  margin: 22px 0;
  background: black;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 15px 0;
  border-radius: 25px;
  transition: .3s ease;
}
.content a:hover{
  border-radius: 4px;
}
@media (max-width: 900px) {
  .services{
    display: flex;
    flex-direction: column;
  }
}
</style>