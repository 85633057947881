import { createApp, ref } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config"; // This imports the PrimeVue library.
import "primeicons/primeicons.css";
import ConfirmationService from "primevue/confirmationservice";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ToastService from "primevue/toastservice";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCity,
  faBoxesStacked,
  faFileArrowUp,
} from "@fortawesome/free-solid-svg-icons";
library.add(faCity, faBoxesStacked, faFileArrowUp);

// komponen primevue
import BadgeDirective from "primevue/badgedirective";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import ConfirmDialog from "primevue/confirmdialog";
import RadioButton from "primevue/radiobutton";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import Toast from "primevue/toast";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Password from "primevue/password";
import Toolbar from "primevue/toolbar";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import TextArea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import Skeleton from 'primevue/skeleton';
import Divider from 'primevue/divider'
import Timeline from 'primevue/timeline';
import "primeflex/primeflex.scss";


const app = createApp(App);
app.config.globalProperties.$isLoadingGlobal = ref(false);

app.use(store);
app.use(router);
app.directive("badge", BadgeDirective);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Avatar", Avatar);
app.component("AvatarGroup", AvatarGroup);
app.component("DataTable", DataTable);
app.component("Divider", Divider);
app.component("Column", Column);
app.component("Button", Button);
app.component("Dialog", Dialog);
app.component("InputText", InputText);
app.component("ConfirmDialog", ConfirmDialog);
app.component("RadioButton", RadioButton);
app.component("FileUpload", FileUpload);
app.component("Calendar", Calendar);
app.component("Toast", Toast);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Password", Password);
app.component("Toolbar", Toolbar);
app.component("Dropdown", Dropdown);
app.component("InputNumber", InputNumber);
app.component("Skeleton", Skeleton);
app.component("TextArea", TextArea);
app.component("Timeline", Timeline);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(PrimeVue, {
  ripple: true,
  locale: {
    dayNames: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
    dayNamesShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
    dayNamesMin: ["Mi", "Sn", "Sl", "Rb", "Km", "Jm", "Sb"],
    monthNames: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
    today: "Hari ini",
    dateFormat: "yy/mm/dd",
  },
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(
  Loading,
  {
    // props
    color: "green",
    loader: "dots",
    backgroundColor: "#ffffff",
    opacity: 0.5,
  },
  {
    // slots
  }
);
app.mount("#app");
// createApp(App).use(store).use(router).mount("#app");
