import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import HomeBaseView from "../views/HomeBaseView.vue";
// import HorasManajemenMitra from "../views/HorasManajemenMitra.vue";
// import HorasManajemenPerjanjianKerja from "../views/HorasManajemenPerjanjianKerja.vue";
// import HorasManajemenKegiatan from "../views/HorasManajemenKegiatan.vue";
// import HorasManajemenUser from "../views/HorasManajemenUser.vue";
import HorasPageNotFoundView from "../views/HorasPageNotFoundView.vue";
import AMSManajemenPengguna from "@/views/AMSManajemenPengguna.vue";
import AMSManajemenKodeAset from "@/views/AMSManajemenKodeAset.vue";
import AMSManajemenPengajuanBarangJasa from "@/views/AMSManajemenPengajuanBarangJasa.vue";
import AMSManajemenPengajuanBarangJasaAset from "@/views/AMSManajemenPengajuanBarangJasaAset.vue";
import AMSManajemenKonfirmasiBPKAD from "@/views/AMSManajemenKonfirmasiBPKAD.vue";
import AMSManajemenRekap from "@/views/AMSManajemenRekap.vue";
import AMSDashboard from "@/views/AMSDashboard.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "home-base",
    component: HomeBaseView,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: {
          default: AMSDashboard,
          MainView: HomeBaseView,
        },
      },
      {
        path: "/manajemen-pengguna",
        name: "manajemen-pengguna",
        components: {
          default: AMSManajemenPengguna,
          MainView: HomeBaseView,
        },
      },
      {
        path: "/manajemen-kode-aset",
        name: "manajemen-kode-aset",
        components: {
          default: AMSManajemenKodeAset,
          MainView: HomeBaseView,
        },
      },
      {
        path: "/manajemen-pengajuan-barang-jasa",
        name: "manajemen-pengajuan-barang-jasa",
        components: {
          default: AMSManajemenPengajuanBarangJasa,
          MainView: HomeBaseView,
        },
      },
      {
        path: "/manajemen-pengajuan-barang-jasa-aset",
        name: "manajemen-pengajuan-barang-jasa-aset",
        components: {
          default: AMSManajemenPengajuanBarangJasaAset,
          MainView: HomeBaseView,
        },
      },
      {
        path: "/manajemen-konfirmasi-bpkad",
        name: "manajemen-konfirmasi-bpkad",
        components: {
          default: AMSManajemenKonfirmasiBPKAD,
          MainView: HomeBaseView,
        },
      },
      {
        path: "/manajemen-rekap",
        name: "manajemen-rekap",
        components: {
          default: AMSManajemenRekap,
          MainView: HomeBaseView,
        },
      },
    ],
  },
  { path: "/:catchAll(.*)", component: HorasPageNotFoundView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
