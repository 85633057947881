<template>
  <div id="dashboard">
    <div class="content-section implementation">
        <div class="card flex justify-content-center grid col-12">
          <div class="card col-3 my-3 mx-3 counter-board" style="min-width: 300px;">
            <h3>Pengajuan Disetujui</h3>
            <div class="dashboard-number-big" id="counter-disetujui">{{ total_pengajuan_disetujui }}</div>
            <h5>pengajuan</h5>
          </div>
          <div class="card col-3 my-3 mx-3 counter-board" style="min-width: 300px;">
            <h3>Pengajuan Ditolak</h3>
            <div class="dashboard-number-big" id="counter-ditolak">{{ total_pengajuan_ditolak }}</div>
            <h5>pengajuan</h5>
          </div>
          <div class="card col-3 my-3 mx-3 counter-board" style="min-width: 300px;">
            <h3>Pengajuan Proses</h3>
            <div class="dashboard-number-big" id="counter-diproses">{{ total_pengajuan_diproses }}</div>
            <h5>pengajuan</h5>
          </div>
        </div>

        <Divider align="center">
            <h3>REKAP SUB RINCIAN</h3>
        </Divider>

        <div class="card flex justify-content-center grid col-12">
          <div class="card col-4 grid-4-custom my-4 mx-4">
            <h5>Distribusi Nilai Pengajuan Persediaan Berdasarkan Sub Rincian</h5>
            <Chart v-if="computedLoadedChartSubRincianPersediaan" type="pie" :data="dataChartSubRincianPersediaan" :options="optionChartSubRincianPersediaan"/>
            <Skeleton v-else id="skeleton-pertama" shape="circle" width="35vw" height="35vw"/>
          </div>
          <div class="card col-4 grid-4-custom my-4 mx-4">
            <h5>Distribusi Nilai Pengajuan Aset Tetap Berdasarkan Sub Rincian</h5>
            <Chart v-if="computedLoadedChartSubRincianAsetTetap" type="pie" :data="dataChartSubRincianAsetTetap" :options="optionChartSubRincianAsetTetap"/>
            <Skeleton v-else id="skeleteon-kedua" shape="circle" width="35vw" height="35vw"/>
          </div>
        </div>

        <Divider align="center">
            <h3>REKAP TAHUNAN</h3>
        </Divider>

        <div>
          <h5>Penyerapan Persediaan untuk Tahun {{ tahun_rekap_persediaan }}</h5>
          <div class="card flex justify-content-center grid col-12">
            <!-- <div class="card col-4 grid-4-custom my-4 mx-4"> -->
              <Chart v-if="computedLoadedChartTahunanPersediaan" type="bar" :data="dataChartTahunanPersediaan" :options="optionChartTahunanPersediaan" :width="900" :height="400"/>
              <Skeleton v-else id="skeleton-ketiga" shape="square" width="75vw" height="75vh"/>
            <!-- </div> -->
          </div>
        </div>

        <div>
            <h5 class="mt-2">Penyerapan Aset Tetap untuk Tahun {{ tahun_rekap_persediaan }}</h5>
            <div class="card flex justify-content-center grid col-12">
              <!-- <div class="card col-4 grid-4-custom my-4 mx-4"> -->
                <Chart v-if="computedLoadedChartTahunanAsetTetap" type="bar" :data="dataChartTahunanAsetTetap" :options="optionChartTahunanAsetTetap" :width="900" :height="400"/>
                <Skeleton v-else id="skeleton-keempat" shape="square" width="75vw" height="75vh"/>
              <!-- </div> -->
            </div>
        </div>
        
        <div class="card flex justify-content-center grid col-12">
          <h5>WordCloud Pengajuan Barang Jasa</h5>
          <canvas id="wordcloud-dashboard" class="wordcloud-dashboard flex" ref="wordclouddashboard" ></canvas>
        </div>
        <!-- <div class="card flex justify-content-center grid col-12">
          <div class="card col-6 full-layout my-4 mx-4">
            <h5>Tanggal Pengajuan</h5>
            <Chart type="pie" :data="chartData" :options="lightOptions"/>
          </div>
          <div class="card col-6 full-layout my-4 mx-4">
            <h5>Persentase Pengajuan Ditolak</h5>
            <Chart type="pie" :data="chartData" :options="lightOptions"/>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import Chart from "primevue/chart";
import WordCloud from "wordcloud";
import RekapPengajuanBarangJasaService from "@/services/rekap-pengajuan-barang-jasa.service";
// import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "dashboard",
  components: {
    Chart,
  },
  computed: {
    computedLoadedChartSubRincianPersediaan(){
      return this.loadedChartSubRincianPersediaan;
    },
    computedLoadedChartSubRincianAsetTetap(){
      return this.loadedChartSubRincianAsetTetap;
    },
    computedLoadedChartNamaBarang(){
      return this.loadedChartNamaBarang;
    },
    computedLoadedChartTahunanPersediaan(){
      return this.loadedChartTahunanPersediaan;
    },
    computedLoadedChartTahunanAsetTetap(){
      return this.loadedChartTahunanAsetTetap;
    },
  },
  data() {
        return {
          // basic
          warna_hijau : ['#6B8E23', '#2E8B57', '#ADFF2F', '#32CD32', '#808000', '#90EE90', '#20B2AA', '#98FB98', '#808000', '#7CFC00', '#6B8F23', '#2E8BF7'],
          warna_reoren : [],
          // data
          dataRekapSubRincian : [],
          dataNamaBarang: [],
          dataRekapTahunan: [],
          tahun_rekap_persediaan : 0,
          // dasboard number
          total_pengajuan_disetujui: 0,
          total_pengajuan_ditolak: 0,
          total_pengajuan_diproses: 0,
          total_pengajuan_disetujui_temp: 0,
          total_pengajuan_ditolak_temp: 0,
          total_pengajuan_diproses_temp: 0,
          // wordcloud/chart nama barang
          loadedChartNamaBarang : false,
          dataChartNamaBarang : null,
          optionChartNamaBarang: null,
          // chart Pertama (Sub Rincian Persediaan)
          loadedChartSubRincianPersediaan : false,
          dataChartSubRincianPersediaan : null,
          optionChartSubRincianPersediaan: null,
          // chart Kedua (Sub Rincian Aset)
          loadedChartSubRincianAsetTetap : false,
          dataChartSubRincianAsetTetap : null,
          optionChartSubRincianAsetTetap: null,
          // bar chart untuk rekap tahunan persediaan by Bulan
          loadedChartTahunanPersediaan : false,
          dataChartTahunanPersediaan : null,
          optionChartTahunanPersediaan: null,
          // bar chart untuk rekap tahunan aset tetap by Bulan
          loadedChartTahunanAsetTetap : false,
          dataChartTahunanAsetTetap : null,
          optionChartTahunanAsetTetap: null,
          chartData: {
              labels: ['A','B','C'],
              datasets: [
                  {
                      label: 'KEBODOHAN',
                      data:[300, 50, 100],
                      backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                      hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
                  }
              ],
          },
          basicData:{},
          lightOptions: {
              // responsive: true,
              plugins: {
                  legend: {
                      labels: {
                          color: '#495057'
                      }
                  }
              }
          }
        }
    },
  async created(){
    this.tahun_rekap_persediaan = new Date().getFullYear();
  },  
  async mounted() {
    await this.dataRekapSubRincianInitialization();
    await this.dataNamaBarangInitialization();
    await this.dataRekapTahunanInitialization();

    WordCloud.minFontSize = "30px"
    WordCloud(document.getElementById('wordcloud-dashboard'), { 
      list: this.dataNamaBarang,
      shrinkToFit: true,
      gridSize: 10,
      minSize: "100px",
      weightFactor: 12,
      fontFamily: 'Finger Paint, cursive, sans-serif',
      color: '#f0f0c0',
      hover: window.drawBox,
      click: function(item) {
        alert(item[0] + ': ' + item[1]);
      },
      backgroundColor: '#001f00'
    });
    await this.chartBuilder();
    
  },
  methods: {
    async dataRekapSubRincianInitialization(){
      var response_temp = await RekapPengajuanBarangJasaService.getRekapBySubRincianObjek();
      if (
        response_temp.status != null &&
        response_temp.status == "success" && (response_temp.data).length > 0
      ) {
        this.dataRekapSubRincian = response_temp.data;
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Sukses",
        //   detail: "Berhasil mengambil data, proses pembuatan grafik",
        //   life: 5000,
        // });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    async dataRekapTahunanInitialization(){
      var response_temp = await RekapPengajuanBarangJasaService.getRekapTahunan();
      if (
        response_temp.status != null &&
        response_temp.status == "success" && (response_temp.data).length > 0
      ) {
        this.dataRekapTahunan = response_temp.data;
        console.log(this.dataRekapTahunan);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Sukses",
        //   detail: "Berhasil mengambil data rekap tahunan, proses pembuatan grafik",
        //   life: 5000,
        // });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    async dataNamaBarangInitialization(){
      var response_temp = await RekapPengajuanBarangJasaService.getNamaBarang();
      if (
        response_temp.status != null &&
        response_temp.status == "success" && (response_temp.data).length > 0
      ) {
        var data_temp = [];
        response_temp.data.forEach(datum => {
          data_temp.push([datum.nama_barang, datum.jumlah_barang])
        });
        this.dataNamaBarang = data_temp;
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Sukses",
        //   detail: "Berhasil mengambil data nama barang, proses pembuatan grafik",
        //   life: 5000,
        // });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    async chartBuilder(){
      await this.chartBuilderRekapSubRincianPersediaan();
      await this.chartBuilderRekapSubRincianAsetTetap();
      await this.chartBuilderRekapTahunanPersediaan();
      await this.chartBuilderRekapTahunanAsetTetap();

      this.animateValue('counter-disetujui', 0, parseInt(this.total_pengajuan_disetujui_temp), 300);
      this.animateValue('counter-diproses', 0, parseInt(this.total_pengajuan_diproses_temp), 300);
      this.animateValue('counter-ditolak', 0, parseInt(this.total_pengajuan_ditolak_temp), 300);
    },
    async chartBuilderRekapSubRincianPersediaan(){
      // this.loadedChartSubRincianPersediaan = false;
      // this.loadedChartSubRincianAsetTetap = false;
      if (
        this.dataRekapSubRincian.length > 0
      ) {
          var index = 0;
          // variabel subrincian persediaan
          var subrincian_persediaan_index = 0;
          var subrincian_persediaan_label = [];
          var subrincian_persediaan_nilai = [];
          var subrincian_persediaan_jumlah = [];
          var subrincian_persediaan_warna = [];
          var subrincian_persediaan_hover = [];
          var subrincian_persediaan_label_akhir = 'Lainnya';
          var subrincian_persediaan_nilai_akhir = 0;
          var subrincian_persediaan_jumlah_akhir = 0;
          var subrincian_persediaan_warna_akhir = '#ffffff'

          var disetujui = 0;
          var ditolak = 0;
          var diproses = 0;

          await this.dataRekapSubRincian.forEach(subrincian => {
            diproses = diproses + parseInt(subrincian.pengajuan_diproses)
            disetujui = disetujui + parseInt(subrincian.pengajuan_disetujui)
            ditolak = ditolak + parseInt(subrincian.pengajuan_ditolak)

            if(subrincian_persediaan_index < 20 && parseInt(subrincian.kode_rekening_neraca_akun) == 5){
              subrincian_persediaan_label.push(subrincian.nama_rekening_neraca_sub_rincian_objek);
              subrincian_persediaan_nilai.push(subrincian.total_nilai_pengajuan_barang_jasa);
              subrincian_persediaan_jumlah.push(subrincian.jumlah_pengajuan_barang_jasa);
              subrincian_persediaan_warna.push(index < 12 ? this.warna_hijau[index] : this.warna_hijau[Number(String(num2).slice(-1))]);
              subrincian_persediaan_hover.push(index < 12 ? this.warna_hijau[index] : this.warna_hijau[Number(String(num2).slice(-1))]);
              subrincian_persediaan_index  = subrincian_persediaan_index  + 1;
            }else if(parseInt(subrincian.kode_rekening_neraca_akun) == 1){
              subrincian_persediaan_jumlah_akhir = subrincian_persediaan_jumlah_akhir + subrincian.jumlah_pengajuan_barang_jasa;
              subrincian_persediaan_nilai_akhir = subrincian_persediaan_nilai_akhir + subrincian.total_nilai_pengajuan_barang_jasa;
              subrincian_persediaan_index  = subrincian_persediaan_index  + 1;
            }

            if(index === this.dataRekapSubRincian.length - 1 && subrincian_persediaan_index >= 20){
              subrincian_persediaan_label.push(subrincian_persediaan_label_akhir);
              subrincian_persediaan_nilai.push(subrincian_persediaan_nilai_akhir);
              subrincian_persediaan_jumlah.push(subrincian_persediaan_jumlah_akhir);
              subrincian_persediaan_warna.push(subrincian_persediaan_warna_akhir);
              subrincian_persediaan_hover.push(subrincian_persediaan_warna_akhir);
            }

            index = index + 1;
          });

          this.total_pengajuan_diproses_temp = this.total_pengajuan_diproses_temp + diproses
          this.total_pengajuan_disetujui_temp = this.total_pengajuan_disetujui_temp + disetujui
          this.total_pengajuan_ditolak_temp = this.total_pengajuan_ditolak_temp + ditolak
          

          if(subrincian_persediaan_index > 0){
            console.log("BUILD CHART PERSEDIAAN");
            this.dataChartSubRincianPersediaan = {
              labels: subrincian_persediaan_label,
              datasets: [
                  {
                      data: subrincian_persediaan_nilai,
                      backgroundColor: subrincian_persediaan_warna,
                      hoverBackgroundColor: subrincian_persediaan_warna
                  }
              ],
            };
            this.optionChartSubRincianPersediaan =  {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            };
            this.loadedChartSubRincianPersediaan = true;
          }

      } else {
        this.loadedChartSubRincianPersediaan = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Grafik Rekap Sub Rincian untuk Persediaan mengalami galat",
          life: 3000,
        });
      }
    },
    async chartBuilderRekapSubRincianAsetTetap(){
      // this.loadedChartSubRincianPersediaan = false;
      // this.loadedChartSubRincianAsetTetap = false;
      if (
        this.dataRekapSubRincian.length > 0
      ) {
          var index = 0;
          // variabel subrincian aset tetap
          var subrincian_aset_tetap_index = 0;
          var subrincian_aset_tetap_label = [];
          var subrincian_aset_tetap_nilai = [];
          var subrincian_aset_tetap_jumlah = [];
          var subrincian_aset_tetap_warna = [];
          var subrincian_aset_tetap_hover = [];
          var subrincian_aset_tetap_label_akhir = 'Lainnya';
          var subrincian_aset_tetap_nilai_akhir = 0;
          var subrincian_aset_tetap_jumlah_akhir = 0;
          var subrincian_aset_tetap_warna_akhir = '#ffffff'

          var disetujui = 0;
          var ditolak = 0;
          var diproses = 0;

          await this.dataRekapSubRincian.forEach(subrincian => {
            diproses = diproses + parseInt(subrincian.pengajuan_diproses)
            disetujui = disetujui + parseInt(subrincian.pengajuan_disetujui)
            ditolak = ditolak + parseInt(subrincian.pengajuan_ditolak)

           if(subrincian_aset_tetap_index < 20 && parseInt(subrincian.kode_rekening_neraca_akun) == 1){
              subrincian_aset_tetap_label.push(subrincian.nama_rekening_neraca_sub_rincian_objek);
              subrincian_aset_tetap_nilai.push(subrincian.total_nilai_pengajuan_barang_jasa);
              subrincian_aset_tetap_jumlah.push(subrincian.jumlah_pengajuan_barang_jasa);
              subrincian_aset_tetap_warna.push(index < 12 ? this.warna_hijau[index] : this.warna_hijau[Number(String(num2).slice(-1))]);
              subrincian_aset_tetap_hover.push(index < 12 ? this.warna_hijau[index] : this.warna_hijau[Number(String(num2).slice(-1))]);
              subrincian_aset_tetap_index  = subrincian_aset_tetap_index  + 1;
            }else if(parseInt(subrincian.kode_rekening_neraca_akun) == 1){
              subrincian_aset_tetap_jumlah_akhir = subrincian_aset_tetap_jumlah_akhir + subrincian.jumlah_pengajuan_barang_jasa;
              subrincian_aset_tetap_nilai_akhir = subrincian_aset_tetap_nilai_akhir + subrincian.total_nilai_pengajuan_barang_jasa;
              subrincian_aset_tetap_index  = subrincian_aset_tetap_index  + 1;
            }

            if(index === this.dataRekapSubRincian.length - 1 && subrincian_aset_tetap_index >= 20){
              subrincian_aset_tetap_label.push(subrincian_aset_tetap_label_akhir);
              subrincian_aset_tetap_nilai.push(subrincian_aset_tetap_nilai_akhir);
              subrincian_aset_tetap_jumlah.push(subrincian_aset_tetap_jumlah_akhir);
              subrincian_aset_tetap_warna.push(subrincian_aset_tetap_warna_akhir);
              subrincian_aset_tetap_hover.push(subrincian_aset_tetap_warna_akhir);
            }
            
            index = index + 1;

          });

          this.total_pengajuan_diproses_temp = this.total_pengajuan_diproses_temp + diproses
          this.total_pengajuan_disetujui_temp = this.total_pengajuan_disetujui_temp + disetujui
          this.total_pengajuan_ditolak_temp = this.total_pengajuan_ditolak_temp + ditolak

          if(subrincian_aset_tetap_index > 0){
            console.log("BUILD CHART ASET TETAP");
            this.dataChartSubRincianAsetTetap = {
              labels: subrincian_aset_tetap_label,
              datasets: [
                  {
                      data: subrincian_aset_tetap_nilai,
                      backgroundColor: subrincian_aset_tetap_warna,
                      hoverBackgroundColor: subrincian_aset_tetap_warna
                  }
              ],
            };
            this.optionChartSubRincianAsetTetap =  {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            };
            this.loadedChartSubRincianAsetTetap = true;
          }

      } else {
        this.loadedChartSubRincianAsetTetap = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Grafik mengalami galat untuk Rekap Sub Rincian Aset Tetap",
          life: 3000,
        });
      }
    },
    async chartBuilderRekapTahunanPersediaan(){
      if (
        this.dataRekapTahunan.length > 0
      ) {
          // variabel subrincian persediaan
          var tahunan_persediaan_index = 0;
          var tahunan_persediaan_label = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustust', 'September', 'Oktober', 'November', 'Desember'];
          var tahunan_persediaan_nilai = new Array(12).fill(0);
          var tahunan_persediaan_warna = [];

          await this.dataRekapTahunan.forEach(tahunan => {
            if(parseInt(tahunan.kode_rekening_neraca_akun) == 5){
              tahunan_persediaan_nilai[parseInt(tahunan.month) - 1] = parseInt(tahunan.total_nilai_pengajuan_barang_jasa);
              tahunan_persediaan_warna[parseInt(tahunan.month) - 1] = this.warna_hijau[parseInt(tahunan.month)];
              this.tahun_rekap_persediaan = parseInt(tahunan.year);
              tahunan_persediaan_index = tahunan_persediaan_index + 1;
            }
          });

          if(tahunan_persediaan_index > 0){
            console.log("BUILD CHART PERSEDIAAN");
            this.dataChartTahunanPersediaan = {
              labels: tahunan_persediaan_label,
              datasets: [
                  {
                      data: tahunan_persediaan_nilai,
                      backgroundColor: tahunan_persediaan_warna,
                      hoverBackgroundColor: tahunan_persediaan_warna
                  }
              ],
            };
            this.optionChartTahunanPersediaan =  {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            };
            this.loadedChartTahunanPersediaan = true;
          }

      } else {
        this.loadedChartTahunanPersediaan = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Grafik Rekap Tahunan untuk Persediaan mengalami galat",
          life: 3000,
        });
      }
    },
    async chartBuilderRekapTahunanAsetTetap(){
      if (
        this.dataRekapTahunan.length > 0
      ) {
          // variabel subrincian persediaan
          var tahunan_aset_tetap_index = 0;
          var tahunan_aset_tetap_label = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustust', 'September', 'Oktober', 'November', 'Desember'];
          var tahunan_aset_tetap_nilai = new Array(12).fill(0);
          var tahunan_aset_tetap_warna = [];

          await this.dataRekapTahunan.forEach(tahunan => {
            if(parseInt(tahunan.kode_rekening_neraca_akun) == 1){
              tahunan_aset_tetap_nilai[parseInt(tahunan.month) - 1] = parseInt(tahunan.total_nilai_pengajuan_barang_jasa);
              tahunan_aset_tetap_warna[parseInt(tahunan.month) - 1] = this.warna_hijau[parseInt(tahunan.month)];
              this.tahun_rekap_aset_tetap = parseInt(tahunan.year);
              tahunan_aset_tetap_index = tahunan_aset_tetap_index + 1;
            }
          });

          if(tahunan_aset_tetap_index > 0){
            console.log("BUILD CHART ASET TETAP");
            this.dataChartTahunanAsetTetap = {
              labels: tahunan_aset_tetap_label,
              datasets: [
                  {
                      data: tahunan_aset_tetap_nilai,
                      backgroundColor: tahunan_aset_tetap_warna,
                      hoverBackgroundColor: tahunan_aset_tetap_warna
                  }
              ],
            };
            this.optionChartTahunanAsetTetap =  {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            };
            this.loadedChartTahunanAsetTetap = true;
          }

      } else {
        this.loadedChartTahunanAsetTetap = false;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Grafik Rekap Tahunan untuk Aset Tetap mengalami galat",
          life: 3000,
        });
      }
    },
    async animateValue(id, start, end, duration) {
        if (start === end) return;
        var range = end - start;
        var current = start;
        var increment = end > start? 1 : -1;
        var stepTime = Math.abs(Math.floor(duration / range));
        var obj = document.getElementById(id);
        var timer = setInterval(function() {
            current += increment;
            obj.innerHTML = current;
            if (current == end) {
                clearInterval(timer);
            }
        }, stepTime);
    },
  }
};
</script>

<style lang="scss" scoped>
  .counter-board{
    background-color: rgb(214, 180, 138);
    border: 5px;
    border-radius: 3px;
  }
  div.dashboard-number-big{
    font-size: 150pt;
  }
  .wordcloud-dashboard{
    min-height: 450px;
    width: 90vw;
    height: 80vh;
    border-radius: 20px;
  }

  .content-section{
    background-color: antiquewhite;
    margin-top: 20px;
    border-radius: 20px;
  }

  .full-layout{
    width: 80%;
  }

  @media screen and (min-width: 1000px) {
    .full-layout{
      width: 25%;
    }
  }

  .grid-4-custom{
    width: 80%;
  }

  @media screen and (min-width: 1000px) {
    .grid-4-custom{
      width: 35%;
    }
  }

</style>
