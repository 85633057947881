import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://sirbmdbackend.gaweyan.com/api/satuan-kerja-eselon-dua";
class SatuanKerjaEselonDuaService {
  async getSatuanKerjaEselonDua(search = null) {
    var url = '';
    if(search == null){
        url = API_URL;
    }else{
        url = API_URL +"/?search="+search
    }
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getSatuanKerjaEselonDuaById(satker, members = false) {
    var url = '';
    if(members){
      url = API_URL + "/" + satker.id_satuan_kerja_eselon_dua + "/?members=" + members;
    }else{
      url = API_URL + "/" + satker.id_satuan_kerja_eselon_dua;
    }
    return await axios
      .get(url, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async submitNewSatuanKerjaEselonDua(data) {
    // console.log(data);
    return await axios
      .post(
        API_URL,
        {
          nomenklatur_satuan_kerja_eselon_dua: data.nomenklatur_satuan_kerja_eselon_dua,
          nama_satuan_kerja_eselon_dua: data.nama_satuan_kerja_eselon_dua,
          alamat_satuan_kerja_eselon_dua: data.alamat_satuan_kerja_eselon_dua,
          klasifikasi_satuan_kerja_eselon_dua: data.klasifikasi_satuan_kerja_eselon_dua,
          keterangan_satuan_kerja_eselon_dua : data.keterangan_satuan_kerja_eselon_dua,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error.toJSON();
      });
  }

  async submitEditSatuanKerjaEselonDua(data) {
    return await axios
      .put(
        API_URL + "/" + data.id_satuan_kerja_eselon_dua,
        {
            id_satuan_kerja_eselon_dua:data.id_satuan_kerja_eselon_dua,
            nomenklatur_satuan_kerja_eselon_dua: data.nomenklatur_satuan_kerja_eselon_dua,
            nama_satuan_kerja_eselon_dua: data.nama_satuan_kerja_eselon_dua,
            alamat_satuan_kerja_eselon_dua: data.alamat_satuan_kerja_eselon_dua,
            klasifikasi_satuan_kerja_eselon_dua: data.klasifikasi_satuan_kerja_eselon_dua,
            keterangan_satuan_kerja_eselon_dua : data.keterangan_satuan_kerja_eselon_dua,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async deleteSatuanKerjaEselonDuaById(data) {
    return await axios
      .delete(API_URL + "-delete/" + data.id_satuan_kerja_eselon_dua, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async deleteSatuanKerjaEselonDuaBySelectedIds(ids) {
    // console.log(ids);
    return await axios
      .post(
        API_URL + "-delete",
        {
          selected_ids: JSON.stringify(ids),
        },
        {
          headers: authHeader(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // 'Access-Control-Allow-Headers': '*',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async addMember(satker,user) {
    return await axios
      .post(API_URL + "/add/" + satker.id_satuan_kerja_eselon_dua, 
      {
        id: JSON.stringify(user.id),
      },
      {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async addMembers(satker, ids) {
    return await axios
      .post(API_URL + "/add/" + satker.id_satuan_kerja_eselon_dua, 
      {
        selected_ids: JSON.stringify(ids),
      },
      {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async removeMember(satker, user) {
    return await axios
      .post(API_URL + "/remove/" + satker.id_satuan_kerja_eselon_dua, 
      {
        id_user: user.id,
      },
      {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async removeMembers(satker, selected_ids) {
    return await axios
      .post(API_URL + "/remove/" + satker.id_satuan_kerja_eselon_dua, 
      {
        selected_ids: JSON.stringify(selected_ids),
      },
      {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
}
export default new SatuanKerjaEselonDuaService();
