import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://sirbmdbackend.gaweyan.com/api/pengajuan-barang-jasa-rekap";
class RekapPengajuanBarangJasaService {
    // mengambil data rekap berdasarkan subrincian
    async getRekapBySubRincianObjek(kode = null) {
        var url = "";
        if(kode != null){
            url = API_URL+"/sub-rincian-objek/" + kode;
        }else{
            url = API_URL+"/sub-rincian-objek";
        }
        return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    }

    // mengambil rekap subrincian, satker, tanggal
    async getRekapBySubrincianSatkerTanggal({kode_akun = null, tanggal_awal = null, tanggal_akhir =  null}) {
        var url = "";
        // if(tanggal_awal != null & tanggal_akhir != null){
        //     url = API_URL+`/subrincian-satker-tanggal/?kode_akun=${kode_akun}&tanggal_awal=${tanggal_awal}&tanggal_akhir=${tanggal_akhir}`;
        // }else{
        //     url = API_URL+"/subrincian-satker-tanggal";
        // }
        url = API_URL+"/subrincian-satker-tanggal";
        return await axios
        .post(url, 
            {
                kode_akun: kode_akun,
                tanggal_awal: tanggal_awal,
                tanggal_akhir: tanggal_akhir,
            },
            { 
                headers: authHeader(),
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    }

    // download rekap
    async downloadRekapBySubrincianSatkerTanggal({kode_akun = null, tanggal_awal = null, tanggal_akhir =  null}) {
        var url = "";
        // if(tanggal_awal != null & tanggal_akhir != null){
        //     url = API_URL+`/subrincian-satker-tanggal/download/?kode_akun=${kode_akun}&tanggal_awal=${tanggal_awal}&tanggal_akhir=${tanggal_akhir}`;
        // }else{
        //     url = API_URL+"/subrincian-satker-tanggal/download";
        // }
        url = API_URL+"/subrincian-satker-tanggal/download";
        return await axios
        .post(url, 
            {
                kode_akun: kode_akun,
                tanggal_awal: tanggal_awal,
                tanggal_akhir: tanggal_akhir,
            },
            { headers: authHeader(), responseType: "blob" })
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            return error;
        });
      }

    // mengambil rekap rincian, satker, tanggal
    async getRekapByRincianSatkerTanggal({kode_akun = null, tanggal_awal = null, tanggal_akhir =  null}) {
        var url = "";
        // if(tanggal_awal != null & tanggal_akhir != null){
        //     url = API_URL+`/rincian-satker-tanggal/?kode_akun=${kode_akun}&tanggal_awal=${tanggal_awal}&tanggal_akhir=${tanggal_akhir}`;
        // }else{
        //     url = API_URL+"/rincian-satker-tanggal";
        // }
        url = API_URL+"/rincian-satker-tanggal";
        return await axios
        .post(url, 
            {
                kode_akun: kode_akun,
                tanggal_awal: tanggal_awal,
                tanggal_akhir: tanggal_akhir,
            },
            { headers: authHeader() })
            .then((response) => {
                return response.data;
            }
        )
        .catch(function (error) {
            return error;
        });
    }

    // download rekap
    async downloadRekapByRincianSatkerTanggal({kode_akun = null, tanggal_awal = null, tanggal_akhir =  null}) {
        var url = "";
        // if(tanggal_awal != null & tanggal_akhir != null){
        //     url = API_URL+`/rincian-satker-tanggal/download/?kode_akun=${kode_akun}&tanggal_awal=${tanggal_awal}&tanggal_akhir=${tanggal_akhir}`;
        // }else{
        //     url = API_URL+"/rincian-satker-tanggal/download";
        // }
        url = API_URL+"/rincian-satker-tanggal/download";
        return await axios
        .post(url, 
            {
                kode_akun: kode_akun,
                tanggal_awal: tanggal_awal,
                tanggal_akhir: tanggal_akhir,
            },
            { headers: authHeader(), responseType: "blob" })
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            return error;
        });
    }

    // mengambil rekap barang, satker, tanggal
    async getRekapByBarangSatkerTanggal({kode_akun = null, tanggal_awal = null, tanggal_akhir =  null}) {
        var url = "";
        // if(tanggal_awal != null & tanggal_akhir != null){
        //     url = API_URL+`/barang-satker-tanggal/?kode_akun=${kode_akun}&tanggal_awal=${tanggal_awal}&tanggal_akhir=${tanggal_akhir}`;
        // }else{
        //     url = API_URL+"/barang-satker-tanggal";
        // }
        url = API_URL+"/barang-satker-tanggal";
        return await axios
        .post(url, 
            {
                kode_akun: kode_akun,
                tanggal_awal: tanggal_awal,
                tanggal_akhir: tanggal_akhir,
            },
            { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    }

    // download rekap
    async downloadRekapByBarangSatkerTanggal({kode_akun = null, tanggal_awal = null, tanggal_akhir =  null}) {
        var url = "";
        // if(tanggal_awal != null & tanggal_akhir != null){
        //     url = API_URL+`/barang-satker-tanggal/download/?kode_akun=${kode_akun}&tanggal_awal=${tanggal_awal}&tanggal_akhir=${tanggal_akhir}`;
        // }else{
        //     url = API_URL+"/barang-satker-tanggal/download";
        // }
        url = API_URL+"/barang-satker-tanggal/download";
        return await axios
        .post(url, 
            {
                kode_akun: kode_akun,
                tanggal_awal: tanggal_awal,
                tanggal_akhir: tanggal_akhir,
            },
            { headers: authHeader(), responseType: "blob" })
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            return error;
        });
    }

    // mengambil rekap tahunan ke n
    async getRekapTahunan(tahun = null, akun = null) {
        var url = "";
        if(tahun != null && akun != null){
            url = API_URL+`/rekap-tahunan/?tahun=${tahun}&akun=${akun}`;
        }else if(akun != null){
            url = API_URL+`/rekap-tahunan/?akun=${akun}`;
        }else if(tahun != null){
            url = API_URL+`/rekap-tahunan/?tahun=${tahun}`;
        }else{
            url = API_URL+"/rekap-tahunan";
            console.log(url);
        }
        return await axios
            .get(url, { headers: authHeader() })
            .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    }

    // mengambil data rekap berdasarkan rincian
    async getRekapByRincianObjek(kode = null) {
        var url = "";
        if(kode != null){
            url = API_URL+"/rincian-objek/" + kode;
        }else{
            url = API_URL+"/rincian-objek";
        }
        return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    }

    // mengambil data nama barang
    async getNamaBarang(kode = null) {
        var url = "";
        if(kode != null){
            url = API_URL+"/rekap-nama-barang/" + kode;
        }else{
            url = API_URL+"/rekap-nama-barang";
        }
        return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    }
}
export default new RekapPengajuanBarangJasaService();
