import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://sirbmdbackend.gaweyan.com/api/notifikasi";
class NotifikasiService {
  async getNotifikasiAll() {
    return await axios
      .get(API_URL, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
}
export default new NotifikasiService();
