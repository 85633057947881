<template>
  <Menubar :model="items" style="background-color: white">
    <template #start>
      <img alt="logo" src="@/assets/logo.png" height="40" class="mr-2" />
    </template>
    <!-- <template #item="{ item }">
      <router-link
        :to="item.to"
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <Button
          class="p-button-text"
          style="color: black"
          :href="href"
          @click="navigate"
          :icon="item.icon"
          :label="item.label"
          :class="{
            'active-link': isActive,
            'active-link-exact': isExactActive,
          }"
        ></Button>
      </router-link>
    </template>  -->
    <template #end>
      <!-- <Button
        label="Profil"
        class="p-button-info p-button-text"
        @click="changeRoute('/manajemen-user')"
      /> -->

      <Button
        label="Logout"
        class="p-button-danger p-button-text"
        @click="logout"
      />
      <!-- <Avatar icon="pi pi-user" size="large" v-badge.danger="4" /> -->
    </template>
  </Menubar>
  <ConfirmDialog id="logout_dialog" style="color: black"></ConfirmDialog>
</template>

<script>
import Menubar from "primevue/menubar";
import AuthService from "../services/auth.service";

export default {
  components: {
    Menubar,
    AuthService,
  },
  data() {
    return {
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "dashboard",
        },
        {
          label: "Manajemen Pengajuan",
          icon: "pi pi-fw pi-arrow-circle-up",
          items: [
                  {
                    label: "Manajemen Persediaan",
                    icon: "pi pi-fw pi-box",
                    to: "manajemen-pengajuan-barang-jasa",
                  },
                  {
                    label: "Manajemen Aset Tetap",
                    icon: "pi pi-fw pi-building",
                    to: "manajemen-pengajuan-barang-jasa-aset",
                  },
                  {
                    label: "Rekap Data",
                    icon: "pi pi-fw pi-folder-open",
                    to: "manajemen-rekap",
                  },
                ]
        },
        {
          label: "Konfirmasi Pengajuan dan Jurnal",
          icon: "pi pi-fw pi-arrow-circle-up",
          items: [
                  {
                    label: "Konfirmasi BPKAD",
                    icon: "pi pi-fw pi-check-square",
                    to: "manajemen-konfirmasi-bpkad",
                  },
                  {
                    label: "Pengaturan Jurnal",
                    icon: "pi pi-fw pi-book",
                    to: "manajemen-kode-aset",
                  },
                ]
        },
        {
          label: "Manajemen Pengguna",
          icon: "pi pi-fw pi-users",
          to: "manajemen-pengguna",
        },
        
        
        // {
        //   label: "Mitra",
        //   icon: "pi pi-fw pi-pencil",
        //   to: "manajemen-mitra",
        // },
        // {
        //   label: "Kegiatan",
        //   icon: "pi pi-fw pi-user",
        //   to: "manajemen-kegiatan",
        // },
        // {
        //   label: "Perjanjian Kerja",
        //   icon: "pi pi-fw pi-calendar",
        //   to: "manajemen-perjanjian-kerja",
        // },
      ],
    };
  },
  methods: {
    async logout() {
      this.$confirm.require({
        message: "Apakah yakin ingin keluar dari aplikasi?",
        header: "LOGOUT",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Logout",
        rejectLabel: "Cancel",
        accept: async () => {
          //callback to execute when user confirms the action
          await AuthService.logout();
          this.$router.push("/login");
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    changeRoute(route) {
      // `route` is either a string or object
      this.$router.push(route);
    },
  },
};
</script>
