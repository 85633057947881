import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://sirbmdbackend.gaweyan.com/api/rekening-neraca-objek";
class RekeningNeracaObjekService {
  async getRekeningNeracaObjekAll() {
    return await axios
      .get(API_URL, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getRekeningNeracaObjekById(data) {
    return await axios
      .get(API_URL + "/" + data.id_rekening_neraca_objek, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async getRekeningNeracaObjekBySearch(search) {
    return await axios
      .get(
        API_URL,
        { search: search },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async submitNewRekeningNeracaObjek(data) {
    console.log(data);
    return await axios
      .post(
        API_URL,
        {
          kode_rekening_neraca_akun: data.kode_rekening_neraca_akun,
          kode_rekening_neraca_kelompok: data.kode_rekening_neraca_kelompok,
          kode_rekening_neraca_jenis: data.kode_rekening_neraca_jenis,
          kode_rekening_neraca_objek: data.kode_rekening_neraca_objek,
          nama_rekening_neraca_objek: data.nama_rekening_neraca_objek,
          detail_rekening_neraca_objek: data.detail_rekening_neraca_objek,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error.toJSON();
      });
  }

  async submitEditedRekeningNeracaObjek(data) {
    return await axios
      .put(
        API_URL + "/" + data.id_rekening_neraca_objek,
        {
          id_rekening_neraca_objek: data.id_rekening_neraca_objek,
          kode_rekening_neraca_akun: data.kode_rekening_neraca_akun,
          kode_rekening_neraca_kelompok: data.kode_rekening_neraca_kelompok,
          kode_rekening_neraca_jenis: data.kode_rekening_neraca_jenis,
          kode_rekening_neraca_objek: data.kode_rekening_neraca_objek,
          nama_rekening_neraca_objek: data.nama_rekening_neraca_objek,
          detail_rekening_neraca_objek: data.detail_rekening_neraca_objek,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async submitUploadTemplateRekeningNeracaObjek(data) {
    return await axios
      .post(API_URL + "-template", data, {
        headers: authHeader(),
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async submitDownloadTemplateRekeningNeracaObjek() {
    return await axios
      .get(API_URL + "-template", {
        headers: authHeader(),
        responseType: "blob",
      })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async deleteRekeningNeracaObjekById(data) {
    return await axios
      .delete(API_URL + "-delete/" + data.id_rekening_neraca_objek, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
  async deleteRekeningNeracaObjekBySelectedIds(ids) {
    console.log(ids);
    return await axios
      .post(
        API_URL + "-delete",
        {
          selected_ids: JSON.stringify(ids),
        },
        {
          headers: authHeader(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
          // 'Access-Control-Allow-Headers': '*',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
}
export default new RekeningNeracaObjekService();
