<template>
  <TabView style="margin-top: 20px">
    <!-- Panel profile -->
    <TabPanel>
      <template #header>
        <i class="pi pi-calendar" style="margin-right:5px;"></i>
        <span> Profile</span>
      </template>

      <div class="card">
        <div class="field grid">
          <label for="firstname4" class="col-12 mb-2 md:col-2 md:mb-0"
            >Email</label
          >
          <div class="col-12 md:col-10">
            <span class="p-input-icon-left">
              <i class="pi pi-at" />
              <InputText
                id="email"
                v-model="profil.email"
                required="true"
                disabled
                class="w-full"
              />
            </span>
          </div>
        </div>
        <div class="field grid">
          <label for="lastname4" class="col-12 mb-2 md:col-2 md:mb-0"
            >Nama</label
          >
          <div class="col-12 md:col-10">
            <span class="p-input-icon-left">
              <i class="pi pi-id-card" />
              <InputText
                id="name"
                v-model="profil.name"
                required="true"
                style="width: 100%; box-sizing: border-box"
              />
            </span>
          </div>
        </div>
        <div class="field grid">
          <label for="lastname4" class="col-12 mb-2 md:col-2 md:mb-0"
            >Password</label
          >
          <div class="col-12 md:col-10">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <Password
                v-model="profil.password"
                required="true"
                toggleMask
              ></Password>
            </span>
          </div>
        </div>
        <div class="field grid">
          <label for="lastname4" class="col-12 mb-2 md:col-2 md:mb-0"></label>
          <div class="col-12 md:col-10 right-0">
            <Button
              type="button"
              icon="pi pi-send"
              class="p-button-success"
              label="Kirim Perubahan Profil"
              @click="submitPemutahiranProfile"
            ></Button>
          </div>
        </div>
      </div>
    </TabPanel>
    <!-- Panel untuk Histori -->
    <TabPanel>
      <template #header>
        <i class="pi pi-calendar" style="margin-right:5px;"></i>
        <span> Histori Penggunaan</span>
      </template>

      <div class="card">
        <DataTable
          :value="notifikasiData"
          :paginator="true"
          :rows="10"
          v-model:filters="notifikasiFilterPencarian"
          filterDisplay="menu"
          :globalFilterFields="['kategori_notifikasi', 'konten_notifikasi', 'updated_at']"
        >

          <template #header>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="notifikasiFilterPencarian['global'].value"
                placeholder="Cari notifikasi . . ."
              />
            </span>
          </template>

          <Column
            field="updated_at"
            header="Tanggal Update"
            footer="Tanggal Update"
            style="min-width:150px;"
            frozen
          >
            <template #body="slotProps">
              {{ momentChange(slotProps.data) }}
            </template>
          </Column>
          <Column
            field="kategori_notifikasi"
            header="Kategori"
            footer="Kategori"
            style="min-width:150px;"
          ></Column>
          <Column
            field="konten_notifikasi"
            header="Konten"
            footer="Konten"
            style="min-width:500px"
          ></Column>
        </DataTable>

      </div>
    </TabPanel>
    <!-- Panel Pengguna -->
    <TabPanel>
      <template #header>
        <i class="pi pi-user" style="margin-right:5px;"></i>
        <span>Daftar Pengguna </span>
      </template>
      <div class="card">
        <div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="userFilterPencarian['global'].value"
              placeholder="Cari pengguna . . ."
            />
          </span>
          <Button
            @click="openUserBaruDialog"
            class="my-2 float-right"
            label="Tambah User"
            icon="pi pi-plus"
            style="
              background-color: black;
              color: red;
              border: 5px solid white;
              border-radius: 6px;
              float: right;
              position: relative;
              z-index: 99;
              margin-right: 5px;
            "
          ></Button>
        </div>
        <DataTable
          :value="users"
          :paginator="true"
          :rows="10"
          v-model:filters="userFilterPencarian"
          filterDisplay="menu"
          :globalFilterFields="['name', 'email', 'roles']"
        >
          <Column
            field="id"
            header="ID"
            footer="ID"
            :style="{ width: '50px' }"
            frozen
          ></Column>
          <Column
            field="name"
            header="Nama"
            footer="Nama"
            :style="{ width: '300px' }"
          ></Column>
          <Column
            field="email"
            header="Email"
            footer="Email"
            :style="{ width: '200px' }"
          ></Column>
          <Column
            field="roles"
            header="Role"
            footer="Role"
            :style="{ width: '200px' }"
          ></Column>
          <Column :style="{ width: '200px' }" bodyStyle="text-align: left">
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Action</Button
              >
            </template>
            <template #body="slotProps">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-warning"
                style="margin: 0.5em"
                label="Edit Pengguna"
                @click="openUserPemutakhirDialog(slotProps.data)"
              ></Button>
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-danger"
                label="Hapus Pengguna"
                style="margin: 0.5em"
                @click="konfirmasiHapusUser(slotProps.data)"
              ></Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!--  Panel Satker -->
    <TabPanel>
      <template #header>
        <i class="pi pi-building" style="margin-right:5px;"></i>
        <span>Daftar Satuan Kerja </span>
      </template>
      <div class="card">
        <div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="satkerFilterPencarian['global'].value"
              placeholder="Cari satuan kerja . . ."
            />
          </span>
          <Button
            @click="openSatkerBaruDialog"
            class="my-2 float-right"
            label="Tambah Satuan Kerja"
            icon="pi pi-plus"
            style="
              background-color: black;
              color: red;
              border: 5px solid white;
              border-radius: 6px;
              float: right;
              position: relative;
              z-index: 99;
              margin-right: 5px;
            "
          ></Button>
        </div>
        <DataTable
          :value="satkers"
          :paginator="true"
          :rows="10"
          v-model:filters="satkerFilterPencarian"
          filterDisplay="menu"
          :globalFilterFields="['name', 'email', 'roles']"
        >
          <Column
            field="id_satuan_kerja_eselon_dua"
            header="ID"
            footer="ID"
            :style="{ width: '50px' }"
            frozen
          ></Column>
          <Column
            field="nomenklatur_satuan_kerja_eselon_dua"
            header="Nomenklatur"
            footer="Nomenklatur"
            :style="{ width: '300px' }"
          ></Column>
          <Column
            field="nama_satuan_kerja_eselon_dua"
            header="Nama"
            footer="Nama"
            :style="{ width: '200px' }"
          ></Column>
          <Column
            field="alamat_satuan_kerja_eselon_dua"
            header="Alamat"
            footer="Alamat"
            :style="{ width: '200px' }"
          ></Column>
          <Column :style="{ width: '200px' }" bodyStyle="text-align: left">
            <template #header>
              <Button type="button" class="p-button-text" style="color: black"
                >Action</Button
              >
            </template>
            <template #body="slotProps">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-warning"
                style="margin: 0.5em"
                label="Edit Satuan Kerja"
                @click="openSatkerPemutakhirDialog(slotProps.data)"
              ></Button>
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-danger"
                label="Hapus Satuan Kerja"
                style="margin: 0.5em"
                @click="konfirmasiHapusSatker(slotProps.data)"
              ></Button>
              <Button
                type="button"
                icon="pi pi-users"
                class="p-button-info"
                label="Pengaturan Anggota"
                style="margin: 0.5em"
                @click="openDialogMemberSatker(slotProps.data)"
              ></Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
  </TabView>

  <!-- <Button type="button" label="Pencarian" icon="pi pi-search" style="margin:5px;background-color:black;color:red;border: 5px solid white;border-radius: 6px;"/> -->
  <ConfirmDialog style="color: black"></ConfirmDialog>

  <!-- dialog pendaftaran user -->
  <Dialog
    v-model:visible="userDialog"
    :style="{ width: '80vh' }"
    :header="userBaru ? 'Pendaftaran Baru' : 'Pemutakhiran Data User'"
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!userBaru">
      <label for="id">ID</label>
      <InputText id="id" v-model="user.id" required="true" disabled />
    </div>
    <div class="field">
      <label for="name">Nama</label>
      <InputText id="name" v-model="user.name" required="true" />
    </div>
    <div class="field">
      <label for="email">Email</label>
      <InputText
        id="email"
        v-model="user.email"
        required="true"
        class="email"
      />
    </div>
    <div class="field">
      <label for="password">Password</label>
      <Password
        id="password"
        v-model="user.password"
        required="true"
        toggleMask
      ></Password>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="userDialog = false"
        style="color: red"
      />
      <Button
        v-if="userBaru"
        label="Submit User Baru"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataUserBaru"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitPemutahiranDataUser"
      />
    </template>
  </Dialog>

  <!-- dialog pendaftaran satker -->
  <Dialog
    v-model:visible="satkerDialog"
    :style="{ width: '80vh' }"
    :header="satkerBaru ? 'Pendaftaran Baru Satuan Kerja' : 'Pemutakhiran Data Satuan Kerja'"
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!satkerBaru">
      <label for="id_satuan_kerja_eselon_dua">ID</label>
      <InputText id="id_satuan_kerja_eselon_dua" v-model="satker.id_satuan_kerja_eselon_dua" required="true" disabled />
    </div>
    <div class="field">
      <label for="nomenklatur_satuan_kerja_eselon_dua">Nomenklatur Satuan Kerja</label>
      <InputText id="nomenklatur_satuan_kerja_eselon_dua" v-model="satker.nomenklatur_satuan_kerja_eselon_dua" required="true" />
    </div>
    <div class="field">
      <label for="nama_satuan_kerja_eselon_dua">Nama Satuan Kerja</label>
      <InputText id="nama_satuan_kerja_eselon_dua" v-model="satker.nama_satuan_kerja_eselon_dua" required="true" />
    </div>
    <div class="field">
      <label for="alamat_satuan_kerja_eselon_dua">Alamat Satuan Kerja</label>
      <InputText id="alamat_satuan_kerja_eselon_dua" v-model="satker.alamat_satuan_kerja_eselon_dua" required="true" />
    </div>
    <div class="field">
      <label for="keterangan_satuan_kerja_eselon_dua">Keterangan Satuan Kerja</label>
      <InputText id="keterangan_satuan_kerja_eselon_dua" v-model="satker.keterangan_satuan_kerja_eselon_dua" required="true" />
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="satkerDialog = false"
        style="color: red"
      />
      <Button
        v-if="satkerBaru"
        label="Submit Satker Baru"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDataSatkerBaru"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitPemutahiranDataSatker"
      />
    </template>
  </Dialog>

  <!-- Dialog untuk melihat anggota dalam suatu satker -->
  <Dialog
    :paginator="true"
    :rows="5"
    v-model:visible="dialogMemberSatker"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '80vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <Toolbar class="mb-4">
        <template #start>
          <Button
            icon="pi pi-plus"
            label="Tambah Anggota"
            style="background-color: black; color: red; float: left"
            class="mr-2"
            @click="openDialogCalonMemberSatker"
          ></Button>
          <!-- <Button
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            @click="removeMemberSatker"
            :disabled="
              !selectedMemberSatker || !selectedMemberSatker.length
            "
          /> -->
        </template>

        <template #end>
          <InputText
            v-model="filterMemberSatker['global'].value"
            placeholder="Cari anggota"
            style="text-align: right; float: right; position: relative"
          />
        </template>
      </Toolbar>
    </template>
    <DataTable
      :value="memberSatkers"
      v-model:filters="filterMemberSatker"
      dataKey="id"
      filterDisplay="menu"
      :globalFilterFields="['nama', 'email']"
      responsiveLayout="scroll"
      v-model:selection="selectedMemberSatker"
      ref="dt"
    >
      <template #empty> Belum ada mitra untuk kegiatan ini </template>
      <template #loading> Loading data mitra untuk kegiatan . . . </template>
      <!-- <Column
        selectionMode="multiple"
        style="width: 3rem"
        :exportable="false"
      ></Column> -->
      <!-- <Column
        field="id"
        header="ID"
        footer="ID"
        style="min-width: 200px"
        frozen
      ></Column> -->
      <Column
        field="name"
        header="Nama"
        footer="Nama"
        style="min-width: 200px"
        frozen
      ></Column>
      <Column
        field="email"
        header="Email"
        footer="Email"
        style="min-width: 200px"
      ></Column>
      <Column :style="{ width: '200px' }" bodyStyle="text-align: center">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Action</Button
          >
        </template>
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-times"
            class="p-button-danger"
            style="margin-right: 0.5em"
            label="Hapus dari Satker"
            @click="removeMemberSatker(slotProps.data)"
          ></Button>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Toolbar class="mb-4">
        <template #end>
          <Button
          label="Close"
          class="p-button-danger"
          icon="pi pi-times" 
          iconPos="right"
          @click="closeDialogMemberSatker"
          ></Button>
        </template>
      </Toolbar>
    </template>
  </Dialog>
  <!-- end -->

  <!-- start dialog untuk pencarian daftar anggota untuk satker -->
  <Dialog
    v-model:visible="dialogCalonMemberSatker"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
  >
    <template #header>
      <h3>Pencarian Anggota untuk Satker : {{ satker.nama_satuan_kerja_eselon_dua }}</h3>
      <div class="flex justify-content-between">
        <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilterPencarianMitra()"/> -->
        <InputText
          v-model="keywordCalonMemberSatker"
          placeholder="Cari anggota berdasarkan email atau nama"
        />
        <Button
          icon="pi pi-search"
          label="Cari"
          style="background-color: black; color: red; float: left"
          class="ml-2"
          @click="searchCalonMemberSatker"
        ></Button>
      </div>
    </template>
    <template #empty> Belum ada anggota dengan spesifikasi </template>
    <template #loading> Pencarian data mitra . . . </template>
    <DataTable
      :value="calonMemberSatkers"
      v-model:filters="filterCalonMemberSatker"
      dataKey="id"
      filterDisplay="menu"
      :globalFilterFields="['nama', 'email']"
      :paginator="true"
      :rows="10"
    >
      <Column
        field="name"
        header="Nama"
        footer="Nama"
        :style="{ width: '200px' }"
        frozen
      ></Column>
      <Column
        field="email"
        header="Email"
        footer="Email"
        :style="{ width: '100px' }"
      ></Column>
      <Column :style="{ width: '200px' }" bodyStyle="text-align: center">
        <template #header>
          <Button type="button" class="p-button-text" style="color: black"
            >Action</Button
          >
        </template>
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-plus"
            class="p-button-info"
            style="margin-right: 0.5em"
            label="Tambahkan ke Satker"
            @click="addMemberSatker(slotProps.data)"
          ></Button>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="dialogCalonMemberSatker = false"
        style="color: red"
      />
    </template>
  </Dialog>
  <!-- end -->
</template>
<script>
import UserService from "@/services/user.service";
import SatuanKerjaEselonDuaService from "@/services/satuan-kerja-eselon-dua.service";
import NotifikasiService from "@/services/notifikasi.service";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from 'moment';

export default {
  components: {},
  data() {
    return {
      // data users
      users: [],
      userDialog: false,
      userBaru: true,
      loaderOverlay: null,
      // notifikasi
      notifikasiData: [],
      notifikasiFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        konten_notifikasi: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kategori_notifikasi: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        updated_at: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      // user
      user: {
        id: null,
        name: null,
        email: null,
      },
      profil: {
        id: null,
        name: null,
        email: null,
        password: null,
      },
      userFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        roles: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      // data satuan kerja
      satkers: [],
      satkerDialog: false,
      satkerBaru: true,
      satker: {
        id_satuan_kerja_eselon_dua: null,
        nomenklatur_satuan_kerja_eselon_dua: null,
        nama_satuan_kerja_eselon_dua: null,
        alamat_satuan_kerja_eselon_dua: null,
        klasifikasi_satuan_kerja_eselon_dua: null,
        keterangan_satuan_kerja_eselon_dua: null,
      },
      satkerFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_satuan_kerja_eselon_dua: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        alamat_satuan_kerja_eselon_dua: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        keterangan_satuan_kerja_eselon_dua: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      // member satker
      memberSatkers: [],
      selectedMemberSatker: [],
      dialogMemberSatker: false,
      filterMemberSatker: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      // pencarian anggota
      keywordCalonMemberSatker: '',
      calonMemberSatkers: [],
      selectedCalonMemberSatker: [],
      dialogCalonMemberSatker: false,
      filterCalonMemberSatker: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  async created() {},
  async mounted() {
    this.loaderOverlay = this.$loading.show();
    await this.initUsers();
    await this.initializationSatker();
    await this.getNotifikasiSatker();

    // console.log(this.notifikasiData);

    this.$toast.add({
      severity: "success",
      summary: "Sukses dung",
      detail: "Berhasil loading",
      life: 3000,
    });
    this.loaderOverlay.hide();
    // setTimeout(() => {
    //   this.$toast.add({severity:'success', summary: 'Sukses dung', detail: "Berhasil loading", life: 3000});
    // },
    // 5000);
  },
  methods: {
    // date format
    momentChange(date){
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    // inisasi pengambilan data pengguna
    async initUsers() {
      var data = await UserService.getUsers();
      this.users = data.data;
      console.log(this.users);

      var profil_temp = await UserService.getUserDetail();
      this.profil = profil_temp.data;
    },
    // inisiasi data anggota dari suatu satker
    async initializationMemberSatkers(){
      var response_temp = await SatuanKerjaEselonDuaService.getSatuanKerjaEselonDuaById(this.satker, true);
      this.memberSatkers = response_temp.data;
    },
    // membuka dialog untuk menghapus pengguna/user
    konfirmasiHapusUser(data) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus data user ini?",
        header: "Konfirmas Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var response_temp = await UserService.deleteUserById(data);

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.initUsers();
            this.loaderOverlay.hide();
            this.userDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus user",
              life: 5000,
            });
          } else {
            this.loaderOverlay.hide();
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi membuka dialog untuk pendaftaran pengguna baru
    openUserBaruDialog() {
      this.userBaru = true;
      this.user = {
        id: null,
        name: null,
        email: null,
        password: null,
      };
      this.userDialog = true;
    },
    // fungsi membuka dialog untuk pemutakhiran data pengguna
    openUserPemutakhirDialog(data) {
      this.user = data;
      this.userBaru = false;
      this.userDialog = true;
    },
    // fungsi submit data penggna baru
    async submitDataUserBaru() {
      this.loaderOverlay = this.$loading.show();
      var user_temp = this.user;
      var response_temp = await UserService.submitUserBaru(user_temp);

      if (response_temp.status != null && response_temp.status == "success") {
        await this.initUsers();
        this.loaderOverlay.hide();
        this.userDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambahkan user",
          life: 5000,
        });
        this.user = {
          name: null,
          email: null,
          alamat_email_user: null,
          nomor_handphone_user: null,
          nik_user: null,
          jenis_kelamin_user: null,
          tanggal_lahir_user: null,
        };
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi submit pemutakhiran data pengguna
    async submitPemutahiranDataUser() {
      this.loaderOverlay = this.$loading.show();
      var user_temp = this.user;
      var response_temp = await UserService.submitPemutakhiranUser(user_temp);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initUsers();
        this.loaderOverlay.hide();
        this.userDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan user",
          life: 5000,
        });
        this.user = {
          id: null,
          name: null,
          email: null,
          password: null,
        };
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // pemutakhiran data akun (profile)
    async submitPemutahiranProfile() {
      this.loaderOverlay = this.$loading.show();
      var user_temp = this.profil;
      var response_temp = await UserService.submitPemutakhiranUser(user_temp);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initUsers();
        this.loaderOverlay.hide();
        this.userDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan user",
          life: 5000,
        });
        this.user = {
          id: null,
          name: null,
          email: null,
          password: null,
        };
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi upload template user
    async uploadTemplateUser(event) {
      const formData = new FormData();
      formData.append("user_file", event.files[0]);
      var response_temp = await UserService.submitUploadTemplateUser(formData);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initUsers();
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengupload data user",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi upload template user
    async downloadTemplateUser() {
      var response_temp = await UserService.submitDownloadTemplateUser();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "percobaan.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // SATKER
    // inisasi pengambilan data satker
    async initializationSatker() {
      var data = await SatuanKerjaEselonDuaService.getSatuanKerjaEselonDua();
      this.satkers = data.data;
      // console.log(this.users);
    },
    // membuka dialog untuk menghapus satker
    konfirmasiHapusSatker(data) {
      this.$confirm.require({
        message: "Apa yakin ingin menghapus data satuan kerja ini?",
        header: "Konfirmasi Penghapusan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var response_temp = await SatuanKerjaEselonDuaService.deleteSatuanKerjaEselonDuaById(data);

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.initializationSatker();
            this.loaderOverlay.hide();
            this.satkerDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data satker",
              life: 5000,
            });
          } else {
            this.loaderOverlay.hide();
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi membuka dialog untuk pendaftaran satker baru
    openSatkerBaruDialog() {
      this.satkerBaru = true;
      this.satker = {
        id_satuan_kerja_eselon_dua: null,
        nomenklatur_satuan_kerja_eselon_dua: null,
        nama_satuan_kerja_eselon_dua: null,
        alamat_satuan_kerja_eselon_dua: null,
        keterangan_satuan_kerja_eselon_dua: null,
        klasifikasi_satuan_kerja_eselon_dua: null,
      };
      this.satkerDialog = true;
    },
    // fungsi membuka dialog untuk pemutakhiran data satker
    openSatkerPemutakhirDialog(data) {
      this.satker = data;
      this.satkerBaru = false;
      this.satkerDialog = true;
    },
    // fungsi submit data penggna baru
    async submitDataSatkerBaru() {
      this.loaderOverlay = this.$loading.show();
      var satker_temp = this.satker;
      var response_temp = await SatuanKerjaEselonDuaService.submitNewSatuanKerjaEselonDua(satker_temp);

      if (response_temp.status != null && response_temp.status == "success") {
        await this.initializationSatker();
        this.loaderOverlay.hide();
        this.satkerDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil menambahkan satuan kerja",
          life: 5000,
        });
        this.satker = {
          id_satuan_kerja_eselon_dua: null,
          nomenklatur_satuan_kerja_eselon_dua: null,
          nama_satuan_kerja_eselon_dua: null,
          alamat_satuan_kerja_eselon_dua: null,
          keterangan_satuan_kerja_eselon_dua: null,
          klasifikasi_satuan_kerja_eselon_dua: null,
        };
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi submit pemutakhiran data satker
    async submitPemutahiranDataSatker() {
      this.loaderOverlay = this.$loading.show();
      var satker_temp = this.satker;
      var response_temp = await SatuanKerjaEselonDuaService.submitEditSatuanKerjaEselonDua(satker_temp);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initializationSatker();
        this.loaderOverlay.hide();
        this.satkerDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data satker",
          life: 5000,
        });
        this.satker = {
          id_satuan_kerja_eselon_dua: null,
          nomenklatur_satuan_kerja_eselon_dua: null,
          nama_satuan_kerja_eselon_dua: null,
          alamat_satuan_kerja_eselon_dua: null,
          keterangan_satuan_kerja_eselon_dua: null,
          klasifikasi_satuan_kerja_eselon_dua: null,
        };
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // fungsi membuka dialog untuk penambahan pengguna ke satker
    async openDialogMemberSatker(data){
      this.loaderOverlay = this.$loading.show();
      this.satker = data;
      await this.initializationMemberSatkers();
      this.dialogMemberSatker = true;
      this.loaderOverlay.hide();
    },
    // menutup dialog member satker
    async closeDialogMemberSatker(){
      this.dialogMemberSatker = false; 
      this.keywordCalonMemberSatker = '';
      this.calonMemberSatkers = [];
      this.memberSatkers = [];
    },
    // fungsi membuka dialog untuk membuka dialog pencarian anggota ke satker
    async openDialogCalonMemberSatker(){
      this.dialogCalonMemberSatker = true;
    },
    // fungsi untuk pencarian pengguna
    async searchCalonMemberSatker(){
      this.loaderOverlay = this.$loading.show();
      var response_temp = await UserService.getUsersBySearch(this.keywordCalonMemberSatker, false);

      if (response_temp.status != null && response_temp.status == "success") {
        this.calonMemberSatkers = response_temp.data;
        this.loaderOverlay.hide();
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // tambah pengguna ke satker
    async addMemberSatker(user){
      this.loaderOverlay = this.$loading.show();
      var response_temp = await SatuanKerjaEselonDuaService.addMember(this.satker, user);

      if (response_temp.status != null && response_temp.status == "success") {
        var findIndex = -1;
        findIndex = this.calonMemberSatkers.findIndex(a => a.id === user.id)
        findIndex !== -1 && this.calonMemberSatkers.splice(findIndex , 1)
        await this.initializationMemberSatkers();
        this.loaderOverlay.hide();
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // hapus pengguna dari satker
    async removeMemberSatker(user){
      this.loaderOverlay = this.$loading.show();
      var response_temp = await SatuanKerjaEselonDuaService.removeMember(this.satker, user);
      // console.log(response_temp);
      // console.log(user);
      if (response_temp.status != null && response_temp.status == "success") {
        await this.initializationMemberSatkers(); 
        this.loaderOverlay.hide();
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
    // NOTIFIKASI
    // mengambil data notifikasi satker
    async getNotifikasiSatker(){
      var response_temp = await NotifikasiService.getNotifikasiAll();

      if (response_temp.status != null && response_temp.status == "success") {
        this.loaderOverlay.hide();
        this.notifikasiData = response_temp.data;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil mengambil data notifikasi",
          life: 5000,
        });
      } else {
        this.loaderOverlay.hide();
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.field {
  width: 100%;
}
</style>
