<template>
  <TabView>
    <!-- Tab untuk Persediaan -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-boxes-stacked" />
        <span>&nbsp; REKAP PERSEDIAAN</span>
      </template>
      <div class="card">
        <Accordion>
            <!-- Accordion Subrincian Persediaan -->
            <AccordionTab
              header="Rekap Berdasarkan Sub Rincian"
            >
              <DataTable :value="dataRekapSubrincianSatkerPersediaan" :paginator="true" :rows="10">
                <template #header>
                  <div class="card flex justify-content-center grid col-12">
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Awal</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAwalSubrincianSatkerPersediaan" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Akhir</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAkhirSubrincianSatkerPersediaan" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Export Data</h5>
                      <Button icon="pi pi-file" label="Download Laporan" @click="downloadRekapSubrincianSatkerTanggalPersediaan"></Button>
                    </div>
                  </div>
                </template>
                <template #empty>Data rekap belum ada</template>
                <!-- <Column field="kode_rekap_subrincian" header="Kode Subrincian" :sortable="true"></Column> -->
                <Column header="Kode Sub Rincian" headerStyle="width:200px">
                    <template #body="slotProps">
                        {{slotProps.data.kode_akun + "." + slotProps.data.kode_kelompok + "." + slotProps.data.kode_jenis + "." +slotProps.data.kode_objek + "." + slotProps.data.kode_rincian_objek + "." + slotProps.data.kode_sub_rincian_objek }}
                    </template>
                </Column>
                <Column field="nama_sub_rincian_objek" header="Nama Subrincian" :sortable="true"></Column>
                <Column field="total_nilai_pengajuan_barang_jasa" header="Nilai Subrincian" :sortable="true"></Column>
              </DataTable>
            </AccordionTab>
            <!-- Accordion Rincian Persediaan -->
            <AccordionTab
              header="Rekap Berdasarkan Rincian"
            >
            <DataTable :value="dataRekapRincianSatkerPersediaan" :paginator="true" :rows="10">
                <template #header>
                  <div class="card flex justify-content-center grid col-12">
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Awal</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAwalRincianSatkerPersediaan" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Akhir</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAkhirRincianSatkerPersediaan" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Export Data</h5>
                      <Button icon="pi pi-file" label="Download Laporan" @click="downloadRekapRincianSatkerTanggalPersediaan"></Button>
                    </div>
                  </div>
                </template>
                <template #empty>Data rekap belum ada</template>
                <Column header="Kode Sub Rincian" headerStyle="width:200px">
                    <template #body="slotProps">
                        {{slotProps.data.kode_akun + "." + slotProps.data.kode_kelompok + "." + slotProps.data.kode_jenis + "." +slotProps.data.kode_objek + "." + slotProps.data.kode_rincian_objek }}
                    </template>
                </Column>
                <Column field="nama_rincian_objek" header="Nama Rincian" :sortable="true"></Column>
                <Column field="total_nilai_pengajuan_barang_jasa" header="Nilai Rincian" :sortable="true"></Column>
              </DataTable>
            </AccordionTab>
            <!-- Accordion Barang Persediaan -->
            <AccordionTab
              header="Rekap Berdasarkan Barang"
            >
            <DataTable :value="dataRekapBarangSatkerPersediaan" :paginator="true" :rows="10">
                <template #header>
                  <div class="card flex justify-content-center grid col-12">
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Awal</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAwalBarangSatkerPersediaan" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Akhir</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAkhirBarangSatkerPersediaan" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Export Data</h5>
                      <Button icon="pi pi-file" label="Download Laporan" @click="downloadRekapBarangSatkerTanggalPersediaan"></Button>
                    </div>
                  </div>
                </template>
                <template #empty>Data rekap belum ada</template>
                <Column header="Kode Sub Barang" headerStyle="width:200px">
                    <template #body="slotProps">
                        {{slotProps.data.kode_akun + "." + slotProps.data.kode_kelompok + "." + slotProps.data.kode_jenis + "." +slotProps.data.kode_objek + "." + slotProps.data.kode_rincian_objek + "." + slotProps.data.kode_sub_rincian_objek }}
                    </template>
                </Column>
                <Column field="nama_barang" header="Nama Rincian" :sortable="true"></Column>
                <Column field="satuan_barang" header="Satuan Rincian" :sortable="true"></Column>
                <Column field="volume_barang" header="Volume Rincian" :sortable="true"></Column>
                <Column field="harga_satuan_barang" header="Harga Satuan Rincian" :sortable="true"></Column>
                <Column field="harga_total_barang" header="Harga Total Rincian" :sortable="true"></Column>
                <Column field="nama_satuan_kerja_eselon_dua" header="Nama Satker" :sortable="true"></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
      </div>
    </TabPanel>
    <!-- Tab untuk Aset Tetap -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-boxes-stacked" />
        <span>&nbsp; REKAP ASET TETAP</span>
      </template>
      <div class="card">
        <Accordion>
            <!-- Accordion Input Satu Per Satu Aset Tetap -->
            <AccordionTab
              header="Rekap Berdasarkan Sub Rincian"
            >
              <DataTable :value="dataRekapSubrincianSatkerAsetTetap" :paginator="true" :rows="10">
                <template #header>
                  <div class="card flex justify-content-center grid col-12">
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Awal</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAwalSubrincianSatkerAsetTetap" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Akhir</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAkhirSubrincianSatkerAsetTetap" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Export Data</h5>
                      <Button icon="pi pi-file" label="Download Laporan" @click="downloadRekapSubrincianSatkerTanggalAsetTetap"></Button>
                    </div>
                  </div>
                </template>
                <template #empty>Data rekap belum ada</template>
                <!-- <Column field="kode_rekap_subrincian" header="Kode Subrincian" :sortable="true"></Column> -->
                <Column header="Kode Sub Rincian" headerStyle="width:200px">
                    <template #body="slotProps">
                        {{slotProps.data.kode_akun + "." + slotProps.data.kode_kelompok + "." + slotProps.data.kode_jenis + "." +slotProps.data.kode_objek + "." + slotProps.data.kode_rincian_objek + "." + slotProps.data.kode_sub_rincian_objek }}
                    </template>
                </Column>
                <Column field="nama_sub_rincian_objek" header="Nama Subrincian" :sortable="true"></Column>
                <Column field="total_nilai_pengajuan_barang_jasa" header="Nilai Subrincian" :sortable="true"></Column>
              </DataTable>
            </AccordionTab>
            <!-- Accordion Upload File AsetTetap -->
            <AccordionTab
              header="Rekap Berdasarkan Rincian"
            >
            <DataTable :value="dataRekapRincianSatkerAsetTetap" :paginator="true" :rows="10">
                <template #header>
                  <div class="card flex justify-content-center grid col-12">
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Awal</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAwalRincianSatkerAsetTetap" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Akhir</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAkhirRincianSatkerAsetTetap" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Export Data</h5>
                      <Button icon="pi pi-file" label="Download Laporan" @click="downloadRekapRincianSatkerTanggalAsetTetap"></Button>
                    </div>
                  </div>
                </template>
                <template #empty>Data rekap belum ada</template>
                <Column header="Kode Sub Rincian" headerStyle="width:200px">
                    <template #body="slotProps">
                        {{slotProps.data.kode_akun + "." + slotProps.data.kode_kelompok + "." + slotProps.data.kode_jenis + "." +slotProps.data.kode_objek + "." + slotProps.data.kode_rincian_objek }}
                    </template>
                </Column>
                <Column field="nama_rincian_objek" header="Nama Rincian" :sortable="true"></Column>
                <Column field="total_nilai_pengajuan_barang_jasa" header="Nilai Rincian" :sortable="true"></Column>
              </DataTable>
            </AccordionTab>
            <!-- Accordion Barang Aset Tetap -->
            <AccordionTab
              header="Rekap Berdasarkan Barang"
            >
            <DataTable :value="dataRekapBarangSatkerAsetTetap" :paginator="true" :rows="10">
                <template #header>
                  <div class="card flex justify-content-center grid col-12">
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Awal</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAwalBarangSatkerAsetTetap" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Tanggal Akhir</h5>
                      <div class="col-12 md:col-4">
                          <Calendar v-model="tanggalAkhirBarangSatkerAsetTetap" inputId="icon" :showIcon="true" style="min-width: 300px;"/>
                      </div>
                    </div>
                    <div class="col-3 my-3 mx-3" style="min-width: 300px;">
                      <h5>Export Data</h5>
                      <Button icon="pi pi-file" label="Download Laporan" @click="downloadRekapBarangSatkerTanggalAsetTetap"></Button>
                    </div>
                  </div>
                </template>
                <template #empty>Data rekap belum ada</template>
                <Column header="Kode Sub Barang" headerStyle="width:200px">
                    <template #body="slotProps">
                        {{slotProps.data.kode_akun + "." + slotProps.data.kode_kelompok + "." + slotProps.data.kode_jenis + "." +slotProps.data.kode_objek + "." + slotProps.data.kode_rincian_objek + "." + slotProps.data.kode_sub_rincian_objek }}
                    </template>
                </Column>
                <Column field="nama_barang" header="Nama Rincian" :sortable="true"></Column>
                <Column field="satuan_barang" header="Satuan Rincian" :sortable="true"></Column>
                <Column field="volume_barang" header="Volume Rincian" :sortable="true"></Column>
                <Column field="harga_satuan_barang" header="Harga Satuan Rincian" :sortable="true"></Column>
                <Column field="harga_total_barang" header="Harga Total Rincian" :sortable="true"></Column>
                <Column field="nama_satuan_kerja_eselon_dua" header="Nama Satker" :sortable="true"></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
      </div>
    </TabPanel>
  </TabView>

</template>
<script>
import UserService from "@/services/user.service";
import moment from 'moment';
import RekapPengajuanBarangJasaService from "@/services/rekap-pengajuan-barang-jasa.service";
import { read, utils, writeFile } from 'xlsx';
import { FilterMatchMode, FilterOperator } from "primevue/api";
import "primeflex/primeflex.scss";

export default {
  components: {},
  data() {
    return {
      // utils
      loaderOverlay : null,
      // data Rekap Persediaan oleh SubRincian
      dataRekapSubrincianSatkerPersediaan: [],
      tanggalAwalSubrincianSatkerPersediaan: null,
      tanggalAkhirSubrincianSatkerPersediaan: null,
      // data Rekap Persediaan oleh Rincian
      dataRekapRincianSatkerPersediaan: [],
      tanggalAwalRincianSatkerPersediaan: null,
      tanggalAkhirRincianSatkerPersediaan: null,
      // data Barang Persediaan
      dataRekapBarangSatkerPersediaan: [],
      tanggalAwalBarangSatkerPersediaan: null,
      tanggalAkhirBarangSatkerPersediaan: null,
      // data Rekap Aset Tetap oleh SubRincian
      dataRekapSubrincianSatkerAsetTetap: [],
      tanggalAwalSubrincianSatkerAsetTetap: null,
      tanggalAkhirSubrincianSatkerAsetTetap: null,
      // data Rekap Aset Tetap oleh Rincian
      dataRekapRincianSatkerAsetTetap: [],
      tanggalAwalRincianSatkerAsetTetap: null,
      tanggalAkhirRincianSatkerAsetTetap: null,
      // data Barang Aset Tetap oleh Barang
      dataRekapBarangSatkerAsetTetap: [],
      tanggalAwalBarangSatkerAsetTetap: null,
      tanggalAkhirBarangSatkerAsetTetap: null,
      // dataRekapSubrincianPersediaan : [],
      // filteringRekapSubrincianPersediaan: {
      //   global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      //     nama_rekening_neraca_sub_rincian_objek: {
      //       operator: FilterOperator.AND,
      //       constraints: [
      //         { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      //       ],
      //     },
      //     nama_satuan_kerja_eselon_dua: {
      //       operator: FilterOperator.AND,
      //       constraints: [
      //         { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      //       ],
      //     },
      // },
      
    };
  },
  async created() {
  },
  async mounted() {
    
  },
  watch:{
    tanggalAwalSubrincianSatkerPersediaan: function(){
      if(this.tanggalAkhirSubrincianSatkerPersediaan != null & this.tanggalAwalSubrincianSatkerPersediaan != null){
        this.initializationDataRekapSubrincianSatkerPersediaan();
      }
    },
    tanggalAkhirSubrincianSatkerPersediaan: function(){
      if(this.tanggalAkhirSubrincianSatkerPersediaan != null & this.tanggalAwalSubrincianSatkerPersediaan != null){
        this.initializationDataRekapSubrincianSatkerPersediaan();
      }
    },
    tanggalAwalRincianSatkerPersediaan: function(){
      if(this.tanggalAkhirRincianSatkerPersediaan != null & this.tanggalAwalRincianSatkerPersediaan != null){
        this.initializationDataRekapRincianSatkerPersediaan();
      }
    },
    tanggalAkhirRincianSatkerPersediaan: function(){
      if(this.tanggalAkhirRincianSatkerPersediaan != null & this.tanggalAwalRincianSatkerPersediaan != null){
        this.initializationDataRekapRincianSatkerPersediaan();
      }
    },
    tanggalAwalBarangSatkerPersediaan: function(){
      if(this.tanggalAkhirBarangSatkerPersediaan != null & this.tanggalAwalBarangSatkerPersediaan != null){
        this.initializationDataRekapBarangSatkerPersediaan();
      }
    },
    tanggalAkhirBarangSatkerPersediaan: function(){
      if(this.tanggalAkhirBarangSatkerPersediaan != null & this.tanggalAwalBarangSatkerPersediaan != null){
        this.initializationDataRekapBarangSatkerPersediaan();
      }
    },
    tanggalAwalSubrincianSatkerAsetTetap: function(){
      if(this.tanggalAkhirSubrincianSatkerAsetTetap != null & this.tanggalAwalSubrincianSatkerAsetTetap != null){
        this.initializationDataRekapSubrincianSatkerAsetTetap();
      }
    },
    tanggalAkhirSubrincianSatkerAsetTetap: function(){
      if(this.tanggalAkhirSubrincianSatkerAsetTetap != null & this.tanggalAwalSubrincianSatkerAsetTetap != null){
        this.initializationDataRekapSubrincianSatkerAsetTetap();
      }
    },
    tanggalAwalRincianSatkerAsetTetap: function(){
      if(this.tanggalAkhirRincianSatkerAsetTetap != null & this.tanggalAwalRincianSatkerAsetTetap != null){
        this.initializationDataRekapRincianSatkerAsetTetap();
      }
    },
    tanggalAkhirRincianSatkerAsetTetap: function(){
      if(this.tanggalAkhirRincianSatkerAsetTetap != null & this.tanggalAwalRincianSatkerAsetTetap != null){
        this.initializationDataRekapRincianSatkerAsetTetap();
      }
    },
    tanggalAwalBarangSatkerAsetTetap: function(){
      if(this.tanggalAkhirBarangSatkerAsetTetap != null & this.tanggalAwalBarangSatkerAsetTetap != null){
        this.initializationDataRekapBarangSatkerAsetTetap();
      }
    },
    tanggalAkhirBarangSatkerAsetTetap: function(){
      if(this.tanggalAkhirBarangSatkerAsetTetap != null & this.tanggalAwalBarangSatkerAsetTetap != null){
        this.initializationDataRekapBarangSatkerAsetTetap();
      }
    },
  },
  methods: {
    // mengambil data Rekap Persediaan yang dikelompokkan berdasarkan Subrincian, Satker, Tanggal
    async initializationDataRekapSubrincianSatkerPersediaan(){
      this.loaderOverlay = this.$loading.show();
      var kode_akun = 5;
      var response_temp = await RekapPengajuanBarangJasaService.getRekapBySubrincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalSubrincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirSubrincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss')})

      if (
        response_temp.status != null &&
        response_temp.status == "success"
      ) {
        this.dataRekapSubrincianSatkerPersediaan = response_temp.data
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // mengambil data Rekap Persediaan yang dikelompokkan berdasarkan Rincian, Satker, Tanggal
    async initializationDataRekapRincianSatkerPersediaan(){
      this.loaderOverlay = this.$loading.show();
      var kode_akun = 5;
      var response_temp = await RekapPengajuanBarangJasaService.getRekapByRincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalRincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirRincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss')})

      if (
        response_temp.status != null &&
        response_temp.status == "success"
      ) {
        this.dataRekapRincianSatkerPersediaan = response_temp.data
        
      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp.data,
          life: 3000,
        });
      }

      this.loaderOverlay.hide();
    },
    // mengambil data Barang Persediaan yang dikelompokkan berdasarkan Barang, Satker, Tanggal
    async initializationDataRekapBarangSatkerPersediaan(){
      this.loaderOverlay = this.$loading.show();
      var kode_akun = 5;
      var response_temp = await RekapPengajuanBarangJasaService.getRekapByBarangSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalBarangSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirBarangSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss')})

      if (
        response_temp.status != null &&
        response_temp.status == "success"
      ) {
        this.dataRekapBarangSatkerPersediaan = response_temp.data
        
      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp.data,
          life: 3000,
        });
      }

      this.loaderOverlay.hide();
    },
    // mengambil data Rekap Aset Tetap yang dikelompokkan berdasarkan Subrincian, Satker, Tanggal
    async initializationDataRekapSubrincianSatkerAsetTetap(){
      this.loaderOverlay = this.$loading.show();
      var kode_akun = 1;
      var response_temp = await RekapPengajuanBarangJasaService.getRekapBySubrincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalSubrincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirSubrincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss')})

      if (
        response_temp.status != null &&
        response_temp.status == "success"
      ) {
        this.dataRekapSubrincianSatkerAsetTetap = response_temp.data
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp.data,
          life: 3000,
        });
      }
      this.loaderOverlay.hide();
    },
    // mengambil data Rekap Aset Tetap yang dikelompokkan berdasarkan Rincian, Satker, Tanggal
    async initializationDataRekapRincianSatkerAsetTetap(){
      this.loaderOverlay = this.$loading.show();
      var kode_akun = 1;
      var response_temp = await RekapPengajuanBarangJasaService.getRekapByRincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalRincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirRincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss')})

      if (
        response_temp.status != null &&
        response_temp.status == "success"
      ) {
        this.dataRekapRincianSatkerAsetTetap = response_temp.data
        
      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp.data,
          life: 3000,
        });
      }

      this.loaderOverlay.hide();
    },
    // mengambil data Barang Aset Tetap yang dikelompokkan berdasarkan Barang, Satker, Tanggal
    async initializationDataRekapBarangSatkerAsetTetap(){
      this.loaderOverlay = this.$loading.show();
      var kode_akun = 1;
      var response_temp = await RekapPengajuanBarangJasaService.getRekapByBarangSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalBarangSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirBarangSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss')})

      if (
        response_temp.status != null &&
        response_temp.status == "success"
      ) {
        this.dataRekapBarangSatkerAsetTetap = response_temp.data
        
      } else {
        
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_temp.data,
          life: 3000,
        });
      }

      this.loaderOverlay.hide();
    },
  
  // download rekap by subrincian, satker
  async downloadRekapSubrincianSatkerTanggalPersediaan(){
    var kode_akun = 5;
    var response_temp = await RekapPengajuanBarangJasaService.downloadRekapBySubrincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalSubrincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirSubrincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss')})
    console.log(response_temp.status);  
    const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Rekap SubRincian Satker Tanggal Persediaan ${(new Date().toJSON().slice(0,10))}.docx`);
      document.body.appendChild(link);
      link.click();
  },
  // download rekap by subrincian, satker
  async downloadRekapSubrincianSatkerTanggalAsetTetap(){
    var kode_akun = 1;
    var response_temp = await RekapPengajuanBarangJasaService.downloadRekapBySubrincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalSubrincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirSubrincianSatkerAstanggalAwalSubrincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss')})
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Rekap SubRincian Satker Tanggal Aset Tetap ${(new Date().toJSON().slice(0,10))}.docx`);
      document.body.appendChild(link);
      link.click();
  },
  // download rekap by rincian, satker
  async downloadRekapRincianSatkerTanggalPersediaan(){
    var kode_akun = 5;
    var response_temp = await RekapPengajuanBarangJasaService.downloadRekapByRincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalRincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirRincianSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss')})
    console.log(response_temp.status);  
    const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Rekap Rincian Satker Tanggal Persediaan ${(new Date().toJSON().slice(0,10))}.docx`);
      document.body.appendChild(link);
      link.click();
  },
  // download rekap by rincian, satker
  async downloadRekapRincianSatkerTanggalAsetTetap(){
    var kode_akun = 1;
    var response_temp = await RekapPengajuanBarangJasaService.downloadRekapByRincianSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalRincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirRincianSatkerAstanggalAwalRincianSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss')})
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Rekap Rincian Satker Tanggal Aset Tetap ${(new Date().toJSON().slice(0,10))}.docx`);
      document.body.appendChild(link);
      link.click();
  },
  // download rekap by rincian, satker
  async downloadRekapBarangSatkerTanggalPersediaan(){
    var kode_akun = 5;
    var response_temp = await RekapPengajuanBarangJasaService.downloadRekapByBarangSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalBarangSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirBarangSatkerPersediaan).format('YYYY-MM-DD HH:mm:ss')})
    console.log(response_temp.status);  
    const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Rekap Barang Satker Tanggal Persediaan ${(new Date().toJSON().slice(0,10))}.docx`);
      document.body.appendChild(link);
      link.click();
  },
  // download rekap by Barang, satker
  async downloadRekapBarangSatkerTanggalAsetTetap(){
    var kode_akun = 1;
    var response_temp = await RekapPengajuanBarangJasaService.downloadRekapByBarangSatkerTanggal({kode_akun : kode_akun, tanggal_awal : moment(this.tanggalAwalBarangSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss'), tanggal_akhir : moment(this.tanggalAkhirBarangSatkerAstanggalAwalBarangSatkerAsetTetap).format('YYYY-MM-DD HH:mm:ss')})
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Rekap Barang Satker Tanggal Aset Tetap ${(new Date().toJSON().slice(0,10))}.docx`);
      document.body.appendChild(link);
      link.click();
  },
},
};
</script>
<style lang="scss" scoped>
</style>
