<template>
  <main id="technical">
    <Toast />
    <MenuBar />
    <section>
      <router-view></router-view>
    </section>
  </main>
</template>
<script>
import MenuBar from "../components/MenuBar.vue";
import Toast from "primevue/toast";
import UserService from "@/services/user.service";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    MenuBar,
    Toast,
    UserService,
  },
  async created() {
    var user = await UserService.getUserDetail();
    if (user == null || user == 0) {
      this.$router.push("/login");
    }
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  background-color: rgb(223, 209, 171);
}

#technical {
  padding: 10px;
}
</style>
