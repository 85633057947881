<template>
    <div class="card">

        <Toolbar class="my-2 align-content-center">
            <template #start>
                <h3>
                    Daftar Pengajuan Barang Jasa Aset Tetap
                </h3>
            </template>
        </Toolbar>

        <!-- datatable untuk temporary data yang bisa di edit -->
        <DataTable
            :value="pengajuanBarangJasaData"
            v-model:filters="pengajuanBarangJasaFilterPencarian"
            filterDisplay="menu"
            key="id_temp"
            :globalFilterFields="['nama_barang_pengajuan_barang_jasa', 'sumber_dana_pengajuan_barang_jasa', 'harga_satuan_barang_pengajuan_barang_jasa']"
            v-model:editingRows="pengajuanBarangJasaEditingRows"
            @row-edit-save="pengajuanBarangJasaOnRowEditSave"
            responsiveLayout="scroll"
            editMode="row"
            v-model:selection="pengajuanBarangJasaSelectingRows"
            ref="dt"
            :paginator="true"
            :rows="10"
        >
            <template #empty> Belum ada data Pengajuan Barang Jasa </template>
            <template #loading> Loading . . . </template>
            <template #header>
                <Toolbar>
                    <template #start>
                        <div class="grid">
                            <div class="col-4 md:col-4 sm:col-12">
                                <Button
                                    label="Delete"
                                    icon="pi pi-trash"
                                    class="p-button-danger "
                                    style="width:100%;"
                                    @click="pengajuanBarangJasaConfirmationDeleteSelected"
                                    :disabled="
                                    !pengajuanBarangJasaSelectingRows ||
                                    !pengajuanBarangJasaSelectingRows.length
                                    "
                                ></Button>
                            </div>
                            <div class="col-4 md:col-4 sm:col-12">
                                <Button
                                    label="Konfirmasi Persetujuan BPKAD"
                                    icon="pi pi-send"
                                    class="p-button-success"
                                    style="width:100%;"
                                    @click="pengajuanBarangJasaConfirmationBPKADSelected(1)"
                                    :disabled="
                                    !pengajuanBarangJasaSelectingRows ||
                                    !pengajuanBarangJasaSelectingRows.length
                                    "
                                ></Button>
                            </div>
                            <div class="col-4 md:col-4 sm:col-12">
                                <Button
                                    label="Konfirmasi Persetujuan Laporan"
                                    icon="pi pi-send"
                                    class="p-button-success"
                                    style="width:100%;"
                                    @click="pengajuanBarangJasaConfirmationLaporanSelected(1)"
                                    :disabled="
                                    !pengajuanBarangJasaSelectingRows ||
                                    !pengajuanBarangJasaSelectingRows.length
                                    "
                                ></Button>
                            </div>
                        </div>
                    </template>
                    <template #end>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText
                            v-model="pengajuanBarangJasaFilterPencarian['global'].value"
                            placeholder="Cari kode atau detail aset"
                            />
                        </span>
                    </template>
                </Toolbar>
            </template>
            <!-- start bagian kolom -->
            <Column
                selectionMode="multiple"
                style="width: 3rem"
                :exportable="false"
            ></Column>
            <Column
                field="nama_satuan_kerja_eselon_dua"
                header="Nama Satuan Kerja"
                footer="Nama Satuan Kerja"
                style="min-width:450px;"
            >
                <!-- <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template> -->
            </Column>
            <Column
                header="Kode Rekening Neraca"
                footer="Kode Rekening Neraca"
                :style="{ width: '200px' }"
            >
                <template #body="slotProps">
                {{ slotProps.data.kode_rekening_neraca_akun +'.'+ slotProps.data.kode_rekening_neraca_kelompok +'.'+ slotProps.data.kode_rekening_neraca_jenis +'.'+ slotProps.data.kode_rekening_neraca_objek +'.'+ slotProps.data.kode_rekening_neraca_rincian_objek +'.'+ slotProps.data.kode_rekening_neraca_sub_rincian_objek}}
                </template>
            </Column>

            <Column
                field="nama_rekening_neraca_rincian_objek"
                header="Nama Rincian Objek"
                footer="Nama Rincian Objek"
                style="min-width:300px"
            >
            </Column>
            
            <Column
                field="rekening_neraca"
                header="Nama Sub Rincian Objek"
                footer="Nama Sub Rincian Objek"
                style="min-width:300px"
            >
                <template #editor="{ data, field }">
                <Dropdown
                    v-model="data[field]"
                    :options="rekeningNeracaSubRincianObjekData"
                    optionLabel="nama_rekening_neraca_sub_rincian_objek"
                    style="text-align: start"
                    class="flex"
                    :filter="true">
                    <template #option="slotProps">
                        <span>{{slotProps.option.kode_rekening_neraca_akun+'.'+slotProps.option.kode_rekening_neraca_kelompok+'.'+slotProps.option.kode_rekening_neraca_jenis+'.'+slotProps.option.kode_rekening_neraca_objek+'.'+slotProps.option.kode_rekening_neraca_rincian_objek+'.'+slotProps.option.kode_rekening_neraca_sub_rincian_objek+' '+slotProps.option.nama_rekening_neraca_sub_rincian_objek}}</span>
                    </template>
                </Dropdown>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.rekening_neraca.nama_rekening_neraca_sub_rincian_objek }}
                </template>
            </Column>
            <Column
                field="kategori_pengajuan_barang_jasa"
                header="Kategori Pengajuan"
                footer="Kategori Pengajuan"
                style="min-width:300px"
            >
                <template #body="slotProps">
                    {{ slotProps.data.kategori_pengajuan_barang_jasa == 'persediaan' ? 'PERSEDIAAN' : 'ASET TETAP' }}
                </template>
            </Column>
            <Column
                field="nama_barang_pengajuan_barang_jasa"
                header="Nama Barang Pengajuan"
                footer="Nama Barang Pengajuan"
                style="min-width:450px"
            >
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
            </Column>
            <Column
                field="ba_srt_nomor"
                header="Nomor BA SRT Pengajuan"
                footer="Nomor BA SRT Pengajuan"
                :style="{ width: '200px' }"
            >
                <!-- <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template> -->
            </Column>
            <Column
                field="ba_srt_tanggal"
                header="Tanggal BA SRT Pengajuan"
                footer="Tanggal BA SRT Pengajuan"
                style="min-width: 200px"
            >
                <!-- <template #editor="{ data, field }">
                <Calendar
                    v-model="data[field]"
                    dateFormat="yy/mm/dd"
                    :showTime="false"
                />
                </template> -->
            </Column>
            <Column
                field="volume_barang_pengajuan_barang_jasa"
                header="Volume Pengajuan"
                footer="Volume Pengajuan"
                :style="{ width: '200px' }"
            >
                <!-- <template #editor="{ data, field }">
                    <InputNumber  
                    v-model="data[field]" 
                    mode="decimal"
                    locale="de-DE"
                    :maxFractionDigits="4" />
                </template> -->
            </Column>
            <Column
                field="satuan_barang_pengajuan_barang_jasa"
                header="Satuan Barang Pengajuan"
                footer="Satuan Barang Pengajuan"
                :style="{ width: '200px' }"
            >
                <!-- <template #editor="{ data, field }">
                    <InputText v-model="data[field]"/>
                </template> -->
            </Column>
            <Column
                field="harga_satuan_barang_pengajuan_barang_jasa"
                header="Harga Satuan Barang"
                footer="Harga Satuan Barang"
                :style="{ width: '200px' }"
            >
                <!-- <template #editor="{ data, field }">
                    <InputNumber  
                        v-model="data[field]" 
                        mode="decimal"
                        locale="de-DE"
                        :maxFractionDigits="4" />
                </template> -->
            </Column>
            <Column
                field="sumber_dana_pengajuan_barang_jasa"
                header="Sumber Dana Pengajuan"
                footer="Sumber Dana Pengajuan"
                style="min-width:250px"
            >
                <template #editor="{ data, field }">
                <Dropdown
                    v-model="data[field]"
                    :options="sumber_dana_pengajuan_barang_jasas"
                    optionLabel="name"
                    optionValue="value"
                    style="text-align: start"
                    class="flex"
                />
                </template>
                <template #body="slotProps">
                    {{ (sumber_dana_pengajuan_barang_jasas.find(item => item.value == slotProps.data.sumber_dana_pengajuan_barang_jasa)).name }}
                </template>
            </Column>
            <Column
                field="keterangan_pengajuan_barang_jasa"
                header="Keterangan Pengajuan"
                footer="Keterangan Pengajuan"
                style="min-width:500px"
            >
                <template #editor="{ data, field }">
                    <InputText style="width:100%" v-model="data[field]" />
                </template>
            </Column>
            <Column
                field="status_persetujuan_satker_pengajuan_barang_jasa"
                header="Status Persetujuan SATKER"
                footer="Status Persetujuan SATKER"
                style="min-width:250px"
            >
                <!-- <template #editor="{ data, field }">
                    <Dropdown
                        v-model="data[field]"
                        :options="persetujuans"
                        optionLabel="name"
                        optionValue="value"
                        style="text-align: start"
                        class="flex"
                    />
                </template> -->
                <template #body="slotProps">
                  <span :class="getStatusClass(parseInt(slotProps.data.status_persetujuan_satker_pengajuan_barang_jasa))">{{ (persetujuans.find(item => item.value == parseInt(slotProps.data.status_persetujuan_satker_pengajuan_barang_jasa))).name }}</span>
              </template>
            </Column>
            <Column
                field="status_persetujuan_bpkad_pengajuan_barang_jasa"
                header="Status Persetujuan BPKAD"
                footer="Status Persetujuan BPKAD"
                style="min-width:250px"
            >
                <template #editor="{ data, field }">
                <Dropdown
                    v-model="data[field]"
                    :options="pengajuans"
                    optionLabel="name"
                    optionValue="value"
                    style="text-align: start"
                    class="flex"
                />
                </template>
                <template #body="slotProps">
                    <span :class="getStatusClass(parseInt(slotProps.data.status_persetujuan_bpkad_pengajuan_barang_jasa))">{{ (persetujuans.find(item => item.value == parseInt(slotProps.data.status_persetujuan_bpkad_pengajuan_barang_jasa))).name }}</span>
                </template>
            </Column>
            <Column
                field="status_persetujuan_laporan_pengajuan_barang_jasa"
                header="Status Persetujuan LAPORAN"
                footer="Status Persetujuan LAPORAN"
                style="min-width:250px"
            >
                <template #editor="{ data, field }">
                <Dropdown
                    v-model="data[field]"
                    :options="pengajuans"
                    optionLabel="name"
                    optionValue="value"
                    style="text-align: start"
                    class="flex"
                />
                </template>
                <template #body="slotProps">
                    <span :class="getStatusClass(parseInt(slotProps.data.status_persetujuan_laporan_pengajuan_barang_jasa))">{{ (persetujuans.find(item => item.value == parseInt(slotProps.data.status_persetujuan_laporan_pengajuan_barang_jasa))).name }}</span>
                </template>
            </Column>
            <Column
                :rowEditor="true"
                style="width: 20%; min-width: 10rem"
                bodyStyle="text-align:center"
            >
                <template #header>
                <Button
                    type="button"
                    class="p-button-text"
                    style="color: black"
                    >Edit Pengajuan</Button
                >
                </template>
            </Column>
            <!-- end bagian kolom -->
        </DataTable>
    </div>
  
    <!-- <Button type="button" label="Pencarian" icon="pi pi-search" style="margin:5px;background-color:black;color:red;border: 5px solid white;border-radius: 6px;"/> -->
    <ConfirmDialog style="color: black"></ConfirmDialog>
  
    <!-- dialog pendaftaran user -->
    <Dialog
      v-model:visible="rekeningNeracaSubRincianObjekDialog"
      :style="{ width: '80vh' }"
      :header="rekeningNeracaSubRincianObjekBaru ? 'Pendaftaran Baru' : 'Pemutakhiran Data User'"
      :modal="true"
      class="p-fluid"
    >
      <div class="field" v-if="!rekeningNeracaSubRincianObjekBaru">
        <label for="id">ID</label>
        <InputText id="id" v-model="user.id" required="true" disabled />
      </div>
      <div class="field">
        <label for="name">Nama</label>
        <InputText id="name" v-model="user.name" required="true" />
      </div>
      <div class="field">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model="user.email"
          required="true"
          class="email"
        />
      </div>
      <div class="field">
        <label for="password">Password</label>
        <Password
          id="password"
          v-model="user.password"
          required="true"
          toggleMask
        ></Password>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="rekeningNeracaSubRincianObjekDialog = false"
          style="color: red"
        />
        <Button
          v-if="rekeningNeracaSubRincianObjekBaru"
          label="Submit User Baru"
          icon="pi pi-check"
          class="p-button-text"
          style="color: black"
          @click="submitDatarekeningNeracaSubRincianObjekBaru"
        />
        <Button
          v-else
          label="Submit Pemutakhiran Data"
          icon="pi pi-check"
          class="p-button-text"
          style="color: black"
          @click="submitPemutahiranDataUser"
        />
      </template>
    </Dialog>
  </template>
  <script>
  import UserService from "@/services/user.service";
  import RekeningNeracaSubRincianObjekService from "@/services/rekening-neraca-sub-rincian-objek.service";
  import PengajuanBarangJasaService from "@/services/pengajuan-barang-jasa.service";
  import { read, utils, writeFile } from 'xlsx';
  import { FilterMatchMode, FilterOperator } from "primevue/api";
  import "primeflex/primeflex.scss";
  
  export default {
    components: {},
    data() {
      return {
        // basic
        loaderOverlay: null,
        user: {
          id: null,
          name: null,
          email: null,
        },
        users: [],
        sumber_dana_pengajuan_barang_jasas: [
          { 
            name : 'Pembelian',
            value: 'pembelian',
          },
          { 
            name : 'Penjualan Aset',
            value: 'penjualan_aset',
          },
          { 
            name : 'Hibah',
            value: 'hibah',
          },
        ],
        persetujuans: [
          { 
            name : 'SETUJU',
            value: 1,
          },
          { 
            name : 'BELUM DISETUJUI',
            value: 0,
          },
          { 
            name : 'DITOLAK',
            value: 2,
          },
          { 
            name : 'PENGAJUAN KEMBALI',
            value: 4,
          },
        ],
        pengajuans: [
          { 
            name : 'SETUJU',
            value: 1,
          },
          { 
            name : 'BELUM DISETUJUI',
            value: 0,
          },
          { 
            name : 'DITOLAK',
            value: 2,
          },
        ],
        // End basic
        // Variabel Pengajuan Barang Jasa
        pengajuanBarangJasaDialog: false,
        pengajuanBarangJasaBaru: true,
        pengajuanBarangJasaData: [],
        pengajuanBarangJasaEditingRows: [],
        pengajuanBarangJasaSelectingRows: [],
        pengajuanBarangJasaDropdown: null,
        pengajuanBarangJasaFilterPencarian: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          nama_rekening_neraca_rincian_objek: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          nama_rekening_neraca_sub_rincian_objek: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          nama_barang_pengajuan_barang_jasa: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          sumber_dana_pengajuan_barang_jasa: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          kategori_pengajuan_barang_jasa: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          keterangan_pengajuan_barang_jasa: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
        },
        pengajuanBarangJasaDatum: {
          id_rekening_neraca_sub_rincian_objek: null,
          id_rekening_neraca_rincian_objek: null,
          ba_srt_nomor: null,
          ba_srt_tanggal: null,
          nama_barang_pengajuan_barang_jasa: null,
          volume_barang_pengajuan_barang_jasa: null,
          harga_satuan_barang_pengajuan_barang_jasa: null,
          satuan_barang_pengajuan_barang_jasa: null,
          kategori_pengajuan_barang_jasa: null,
          keterangan_pengajuan_barang_jasa: null,
          sumber_dana_pengajuan_barang_jasa: null,
        },
        pengajuanBarangJasa: {
          id_rekening_neraca_sub_rincian_objek: null,
          id_rekening_neraca_rincian_objek: null,
          ba_srt_nomor: null,
          ba_srt_tanggal: null,
          nama_barang_pengajuan_barang_jasa: null,
          volume_barang_pengajuan_barang_jasa: null,
          harga_satuan_barang_pengajuan_barang_jasa: null,
          satuan_barang_pengajuan_barang_jasa: null,
          kategori_pengajuan_barang_jasa: null,
          keterangan_pengajuan_barang_jasa: null,
          sumber_dana_pengajuan_barang_jasa: null,
        },
        // end
        // Variabel Rekening Neraca Sub Rincian Objek
        rekeningNeracaSubRincianObjekDialog: false,
        rekeningNeracaSubRincianObjekBaru: true,
        rekeningNeracaSubRincianObjekData: [],
        rekeningNeracaSubRincianObjekEditingRows: [],
        rekeningNeracaSubRincianObjekSelectingRows: [],
        rekeningNeracaSubRincianObjekDropdown: null,
        rekeningNeracaSubRincianObjekFilterPencarian: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          nama_rekening_neraca_akun: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          nama_rekening_neraca_kelompok: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          nama_rekening_neraca_jenis: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          nama_rekening_neraca_objek: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          nama_rekening_neraca_rincian_objek: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
          nama_rekening_neraca_sub_rincian_objek: {
            operator: FilterOperator.AND,
            constraints: [
              { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
          },
        },
        rekeningNeracaSubRincianObjekDatum: {
          kode_rekening_neraca_akun: null,
          kode_rekening_neraca_kelompok: null,
          kode_rekening_neraca_jenis: null,
          kode_rekening_neraca_objek: null,
          kode_rekening_neraca_rincian_objek: null,
          kode_rekening_neraca_sub_rincian_objek: null,
          nama_rekening_neraca_akun: null,
          nama_rekening_neraca_kelompok: null,
          nama_rekening_neraca_jenis: null,
          nama_rekening_neraca_objek: null,
          nama_rekening_neraca_rincian_objek: null,
          nama_rekening_neraca_sub_rincian_objek: null,
          detail_rekening_neraca_sub_rincian_objek: null,
        },
        rekeningNeracaSubRincianObjek: {
          kode_rekening_neraca_akun: null,
          kode_rekening_neraca_kelompok: null,
          kode_rekening_neraca_jenis: null,
          kode_rekening_neraca_objek: null,
          kode_rekening_neraca_rincian_objek: null,
          kode_rekening_neraca_sub_rincian_objek: null,
          nama_rekening_neraca_akun: null,
          nama_rekening_neraca_kelompok: null,
          nama_rekening_neraca_jenis: null,
          nama_rekening_neraca_objek: null,
          nama_rekening_neraca_rincian_objek: null,
          nama_rekening_neraca_sub_rincian_objek: null,
          detail_rekening_neraca_sub_rincian_objek: null,
        },
        //End
      };
    },
    async created() {},
    async mounted() {
      this.loaderOverlay = this.$loading.show();
      // await this.initUsers();
      await this.rekeningNeracaSubRincianObjekInitialization();
      await this.pengajuanBarangJasaInitialization();
  
      this.$toast.add({
        severity: "success",
        summary: "Sukses dung",
        detail: "Berhasil loading",
        life: 3000,
      });
      this.loaderOverlay.hide();
      // setTimeout(() => {
      //   this.$toast.add({severity:'success', summary: 'Sukses dung', detail: "Berhasil loading", life: 3000});
      // },
      // 5000);
    },
    computed:{
      rekeningNeracaAkunKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun +" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_akun
        }else{
          return null
        }
      },
      rekeningNeracaKelompokKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_kelompok
        }else{
          return null
        }
      },
      rekeningNeracaJenisKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis+" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_jenis
        }else{
          return null
        }
      },
      rekeningNeracaObjekKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_objek+" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_objek
        }else{
          return null
        }
      },
      rekeningNeracaRincianObjekKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_objek+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_rincian_objek+" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_rincian_objek
        }else{
          return null
        }
      },
      pengajuanBarangJasaTotalHarga: function(){
        if(this.pengajuanBarangJasa.volume_barang_pengajuan_barang_jasa != null){
          return this.pengajuanBarangJasa.volume_barang_pengajuan_barang_jasa * this.pengajuanBarangJasa.harga_satuan_barang_pengajuan_barang_jasa
        }else{
          return 0
        }
      },
      rekeningNeracaAkunKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun +" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_akun
        }else{
          return null
        }
      },
      rekeningNeracaKelompokKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_kelompok
        }else{
          return null
        }
      },
      rekeningNeracaJenisKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis+" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_jenis
        }else{
          return null
        }
      },
      rekeningNeracaObjekKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_objek+" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_objek
        }else{
          return null
        }
      },
      rekeningNeracaRincianObjekKodeNama: function(){
        if(this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun != null){
          return this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_objek+"."+this.rekeningNeracaSubRincianObjek.kode_rekening_neraca_rincian_objek+" "+this.rekeningNeracaSubRincianObjek.nama_rekening_neraca_rincian_objek
        }else{
          return null
        }
      },
      pengajuanBarangJasaTotalHarga: function(){
        if(this.pengajuanBarangJasa.volume_barang_pengajuan_barang_jasa != null){
          return this.pengajuanBarangJasa.volume_barang_pengajuan_barang_jasa * this.pengajuanBarangJasa.harga_satuan_barang_pengajuan_barang_jasa
        }else{
          return 0
        }
      },
    },
    methods: {
      async initUsers() {
        var data = await UserService.getUsers();
        this.users = data.data;
        console.log(this.users);
  
        var profil_temp = await UserService.getUserDetail();
        this.profil = profil_temp.data;
      },
      getStatusClass(status){
        if (status == 1) {
          return 'setuju'
        } else if(status == 0){
          return 'belum_disetujui'
        } else if(status == 2){
          return 'ditolak'
        } else if(status == 4){
          return 'pengajuan_kembali'
        } else {
          return 'undefined_status'
        }
      },
      //  Start bagian Rekening Neraca Sub Rincian Objek
      //  Inisiasi data Rekening Neraca Sub Rincian Objek
      async rekeningNeracaSubRincianObjekInitialization() {
        var data =
          await RekeningNeracaSubRincianObjekService.getRekeningNeracaSubRincianObjekAll();
        this.rekeningNeracaSubRincianObjekData = data.data;
      },
      //  fungsi reset isian
      rekeningNeracaSubRincianObjekClear() {
        this.rekeningNeracaSubRincianObjek = {
          kode_rekening_neraca_akun: null,
          kode_rekening_neraca_jenis: null,
          kode_rekening_neraca_kelompok: null,
          kode_rekening_neraca_objek: null,
          kode_rekening_neraca_rincian_objek: null,
          kode_rekening_neraca_sub_rincian_objek: null,
          nama_rekening_neraca_akun: null,
          nama_rekening_neraca_kelompok: null,
          nama_rekening_neraca_jenis: null,
          nama_rekening_neraca_objek: null,
          nama_rekening_neraca_rincian_objek: null,
          nama_rekening_neraca_sub_rincian_objek: null,
          detail_rekening_neraca_sub_rincian_objek: null,
        };
      },
      //  fungsi untuk merubah value lain saat dropdown di klik
      rekeningNeracaSubRincianObjekChangeDropdown(){
        // console.log(this.rekeningNeracaSubRincianObjekDropdown);
        this.rekeningNeracaSubRincianObjek = this.rekeningNeracaSubRincianObjekDropdown
      },
      //  End
      //  Start bagian untuk Pengajuan Barang Jasa 
      pengajuanBarangJasaClear(){
        this.pengajuanBarangJasa = {
          nama_barang_pengajuan_barang_jasa : null,
          ba_srt_nomor : null,
          ba_srt_tanggal : null,
          volume_barang_pengajuan_barang_jasa : null,
          harga_satuan_barang_pengajuan_barang_jasa : null,
          satuan_barang_pengajuan_barang_jasa : null,
          sumber_dana_pengajuan_barang_jasa : null,
          keterangan_pengajuan_barang_jasa : null,
          kategori_pengajuan_barang_jasa : null,
        }
      },
      // fungsi upload template Rekening Neraca Jenis
      async downloadTemplatePengajuanBarangJasa() {
        this.loaderOverlay = this.$loading.show();
        var response_temp =
          await PengajuanBarangJasaService.submitDownloadTemplatePengajuanBarangJasa();
        const url = URL.createObjectURL(
          new Blob([response_temp.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "pengajuan_barang_jasa_template.xlsx");
        document.body.appendChild(link);
        link.click();
        this.loaderOverlay.hide();
      },
      // temporari Pengajuan Barang Jasa
      // Upload data temporari Pengajuan 
      async pengajuanBarangJasaUploadTemplate(event) {
        this.loaderOverlay = this.$loading.show();
        
        try{
          const file = event.files[0];
          const bf = await file.arrayBuffer();
          const wb = read(bf);
          const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          console.log(data);
          var data_temp = [];
          var status_error = false;
          var id = 0;
          data.forEach(datum => {
            var rekening_neraca = this.rekeningNeracaSubRincianObjekData.find(item => (item.kode_rekening_neraca_akun == datum.kode_rekening_neraca_akun && item.kode_rekening_neraca_kelompok == datum.kode_rekening_neraca_kelompok && item.kode_rekening_neraca_jenis == datum.kode_rekening_neraca_jenis && item.kode_rekening_neraca_objek == datum.kode_rekening_neraca_objek && item.kode_rekening_neraca_rincian_objek == datum.kode_rekening_neraca_rincian_objek && item.kode_rekening_neraca_sub_rincian_objek == datum.kode_rekening_neraca_sub_rincian_objek));
            data_temp.push({
              id_temp : id,
              rekening_neraca : rekening_neraca,
              kode_rekening_neraca_akun : rekening_neraca.kode_rekening_neraca_akun == null ? null : rekening_neraca.kode_rekening_neraca_akun,
              kode_rekening_neraca_kelompok : rekening_neraca.kode_rekening_neraca_kelompok == null ? null : rekening_neraca.kode_rekening_neraca_kelompok,
              kode_rekening_neraca_jenis : rekening_neraca.kode_rekening_neraca_jenis == null ? null : rekening_neraca.kode_rekening_neraca_jenis,
              kode_rekening_neraca_objek : rekening_neraca.kode_rekening_neraca_objek == null ? null : rekening_neraca.kode_rekening_neraca_objek,
              kode_rekening_neraca_rincian_objek : rekening_neraca.kode_rekening_neraca_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_rincian_objek,
              nama_rekening_neraca_rincian_objek: rekening_neraca.nama_rekening_neraca_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_rincian_objek,
              kode_rekening_neraca_sub_rincian_objek : rekening_neraca.kode_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_sub_rincian_objek,
              nama_rekening_neraca_sub_rincian_objek: rekening_neraca.nama_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_sub_rincian_objek,
              nama_barang_pengajuan_barang_jasa : datum.nama_barang_pengajuan_barang_jasa == null ? null : datum.nama_barang_pengajuan_barang_jasa,
              ba_srt_nomor : datum.ba_srt_nomor == null ? null : datum.ba_srt_nomor,
              ba_srt_tanggal : datum.ba_srt_tanggal == null ? null : datum.ba_srt_tanggal,
              volume_barang_pengajuan_barang_jasa : datum.volume_barang_pengajuan_barang_jasa == null ? null : datum.volume_barang_pengajuan_barang_jasa,
              satuan_barang_pengajuan_barang_jasa : datum.satuan_barang_pengajuan_barang_jasa == null ? null : datum.satuan_barang_pengajuan_barang_jasa,
              harga_satuan_barang_pengajuan_barang_jasa : datum.harga_satuan_barang_pengajuan_barang_jasa == null ? null : datum.harga_satuan_barang_pengajuan_barang_jasa,
              // sumber_dana_pengajuan_barang_jasa: this.sumber_dana_pengajuan_barang_jasas.find(item => item.value == datum.sumber_dana_pengajuan_barang_jasa),
              sumber_dana_pengajuan_barang_jasa: datum.sumber_dana_pengajuan_barang_jasa == null ? null : datum.sumber_dana_pengajuan_barang_jasa,
              keterangan_pengajuan_barang_jasa : datum.keterangan_pengajuan_barang_jasa == null ? null : datum.keterangan_pengajuan_barang_jasa,
              kategori_pengajuan_barang_jasa : '',
            });
            id = id + 1;
            if(rekening_neraca == null){
              status_error = true;
            }
          });
          console.log(data_temp);
          this.pengajuanBarangJasaTempData = data_temp;
          if (status_error == false) {
           this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil me LOAD data template",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Terdapat kode rekening yang tidak cocok",
              life: 3000,
            });
          }
        }catch(err) {
          this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              });
        }
        this.loaderOverlay.hide();
      },
      // edit data di datatable pengajuan
      async pengajuanBarangJasaOnRowEditSave(event) {
        this.loaderOverlay = this.$loading.show();
        let { newData, index } = event;
        var rekening_neraca = newData.rekening_neraca;
        try{
            var pengajuanBarangJasaTemp = {};
            pengajuanBarangJasaTemp = newData;
            pengajuanBarangJasaTemp.nama_rekening_neraca_rincian_objek = rekening_neraca.nama_rekening_neraca_rincian_objek;
            pengajuanBarangJasaTemp.nama_rekening_neraca_sub_rincian_objek = rekening_neraca.nama_rekening_neraca_sub_rincian_objek;
            pengajuanBarangJasaTemp.kode_rekening_neraca_akun = rekening_neraca.kode_rekening_neraca_akun;
            pengajuanBarangJasaTemp.kode_rekening_neraca_kelompok = rekening_neraca.kode_rekening_neraca_kelompok;
            pengajuanBarangJasaTemp.kode_rekening_neraca_jenis = rekening_neraca.kode_rekening_neraca_jenis;
            pengajuanBarangJasaTemp.kode_rekening_neraca_objek = rekening_neraca.kode_rekening_neraca_objek;
            pengajuanBarangJasaTemp.kode_rekening_neraca_rincian_objek = rekening_neraca.kode_rekening_neraca_rincian_objek;
            pengajuanBarangJasaTemp.kode_rekening_neraca_sub_rincian_objek = rekening_neraca.kode_rekening_neraca_sub_rincian_objek;
            pengajuanBarangJasaTemp.status_persetujuan_satker_pengajuan_barang_jasa = parseInt(newData.status_persetujuan_satker_pengajuan_barang_jasa);
            pengajuanBarangJasaTemp.status_persetujuan_bpkad_pengajuan_barang_jasa = parseInt(newData.status_persetujuan_bpkad_pengajuan_barang_jasa);
            pengajuanBarangJasaTemp.status_persetujuan_laporan_pengajuan_barang_jasa = parseInt(newData.status_persetujuan_laporan_pengajuan_barang_jasa);
            pengajuanBarangJasaTemp.id_pengajuan_barang_jasa = parseInt(newData.id_pengajuan_barang_jasa);
            
            console.log(pengajuanBarangJasaTemp);
            var response_temp = await PengajuanBarangJasaService.submitEditedPengajuanBarangJasa(pengajuanBarangJasaTemp);
  
            if (
                response_temp.status != null &&
                response_temp.status == "success"
                ) {
                    await this.pengajuanBarangJasaInitialization();
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukses",
                        detail: "Berhasil Edit Pengajuan Barang Jasa ",
                        life: 5000,
                    });
                } else {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: response_temp,
                    life: 3000,
                });
                }
          
        }catch(err) {
          this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              });
        }
        this.loaderOverlay.hide();
      },
      // penghapusan baris dalam posisi pengeditan
      async pengajuanBarangJasaConfirmationDeleteSelected(){
        this.$confirm.require({
          message:
            "Apa yakin ingin menghapus data pengajuan terpilih?",
          header: "Konfirmasi Penghapusan Pengajuan",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Hapus Data",
          rejectLabel: "Cancel",
          acceptClass: "orange--text",
          accept: async () => {
            this.loaderOverlay = this.$loading.show();
            //callback to execute when user confirms the action
            var selected_ids = [];
            this.pengajuanBarangJasaSelectingRows.forEach((element) => {
                selected_ids.push(element.id_pengajuan_barang_jasa);
            });
            var response_temp =
                await PengajuanBarangJasaService.deletePengajuanBarangJasaBySelectedIds(
                    selected_ids
                );

            if (
                response_temp.status != null &&
                response_temp.status == "success"
            ) {
                await this.pengajuanBarangJasaInitialization();
                this.pengajuanBarangJasaSelectingRows = [];
                this.$toast.add({
                    severity: "success",
                    summary: "Sukses",
                    detail: response_temp.message,
                    life: 5000,
                });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: response_temp,
                    life: 3000,
                });
            }
            this.loaderOverlay.hide();
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
        });
      },
      // konfirmasi pengajuan barang jasa BPKAD
      async pengajuanBarangJasaConfirmationBPKADSelected(status){
        this.$confirm.require({
          message:
            "Apa yakin ingin konfirmasi BPKAD pada data pengajuan terpilih menjadi setuju?",
          header: "Konfirmasi Persetujuan BPKAD",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Konfirmasi BPKAD",
          rejectLabel: "Cancel",
          acceptClass: "orange--text",
          accept: async () => {
            this.loaderOverlay = this.$loading.show();
            //callback to execute when user confirms the action
            var selected_ids = [];
            this.pengajuanBarangJasaSelectingRows.forEach((element) => {
                selected_ids.push(element.id_pengajuan_barang_jasa);
            });
            var response_temp =
                await PengajuanBarangJasaService.konfirmasiBPKADPengajuanBarangJasaBySelectedIds(
                    selected_ids, status
                );

            if (
                response_temp.status != null &&
                response_temp.status == "success"
            ) {
                await this.pengajuanBarangJasaInitialization();
                this.pengajuanBarangJasaSelectingRows = [];
                this.$toast.add({
                    severity: "success",
                    summary: "Sukses",
                    detail: "Berhasil konfirmasi BPKAD",
                    life: 5000,
                });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: response_temp,
                    life: 3000,
                });
            }
            this.loaderOverlay.hide();
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
        });
      },
      // konfirmasi pengajuan barang jasa Laporan
      async pengajuanBarangJasaConfirmationLaporanSelected(status){
        this.$confirm.require({
          message:
            "Apa yakin ingin konfirmasi Laporan pada data pengajuan terpilih menjadi setuju?",
          header: "Konfirmasi Persetujuan Laporan",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Konfirmasi Laporan",
          rejectLabel: "Cancel",
          acceptClass: "orange--text",
          accept: async () => {
            this.loaderOverlay = this.$loading.show();
            //callback to execute when user confirms the action
            var selected_ids = [];
            this.pengajuanBarangJasaSelectingRows.forEach((element) => {
                selected_ids.push(element.id_pengajuan_barang_jasa);
            });
            var response_temp =
                await PengajuanBarangJasaService.konfirmasiLaporanPengajuanBarangJasaBySelectedIds(
                    selected_ids, status
                );

            if (
                response_temp.status != null &&
                response_temp.status == "success"
            ) {
                await this.pengajuanBarangJasaInitialization();
                this.pengajuanBarangJasaSelectingRows = [];
                this.$toast.add({
                    severity: "success",
                    summary: "Sukses",
                    detail: "Berhasil konfirmasi BPKAD",
                    life: 5000,
                });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: response_temp,
                    life: 3000,
                });
            }
            this.loaderOverlay.hide();
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
        });
      },
      // fungsi kirim data dari datatable terperiksa ke server
      async pengajuanBarangJasaSendData(){
        this.loaderOverlay = this.$loading.show();
        const formData = new FormData();
        formData.append("data", JSON.stringify(this.pengajuanBarangJasaTempSelectingRows));
        try{
          var response_temp =
          await PengajuanBarangJasaService.submitDataBulkStorePengajuanBarangJasa(
              formData
            );
          if (response_temp.status != null && response_temp.status == "success") {
            this.pengajuanBarangJasaTempSelectingRows.forEach((element) => {
              var findIndex = this.pengajuanBarangJasaTempData.findIndex(a => a.id_temp === element.id_temp)
              findIndex !== -1 && this.pengajuanBarangJasaTempData.splice(findIndex , 1)
            });
            await this.pengajuanBarangJasaTerkirimInitialization();
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil mengirim data Pengajuan, silahkan check di Rincian",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
        }catch(err) {
          this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              });
        }
        this.loaderOverlay.hide();
      },
      // KUMPULAN FUNGSI UNTUK RINCIAN
      // inisiasi data
      async pengajuanBarangJasaInitialization(){
        var data = await PengajuanBarangJasaService.getPengajuanBarangJasaAll();
        
        var data_temp = [];
        var id = 0;
        data.data.forEach(datum => {
          var rekening_neraca = this.rekeningNeracaSubRincianObjekData.find(item => (item.kode_rekening_neraca_akun == datum.kode_rekening_neraca_akun && item.kode_rekening_neraca_kelompok == datum.kode_rekening_neraca_kelompok && item.kode_rekening_neraca_jenis == datum.kode_rekening_neraca_jenis && item.kode_rekening_neraca_objek == datum.kode_rekening_neraca_objek && item.kode_rekening_neraca_rincian_objek == datum.kode_rekening_neraca_rincian_objek && item.kode_rekening_neraca_sub_rincian_objek == datum.kode_rekening_neraca_sub_rincian_objek));
          data_temp.push({
            id_temp : id,
            id_pengajuan_barang_jasa : datum.id_pengajuan_barang_jasa,
            rekening_neraca : rekening_neraca,
            kode_rekening_neraca_akun : rekening_neraca.kode_rekening_neraca_akun == null ? null : rekening_neraca.kode_rekening_neraca_akun,
            kode_rekening_neraca_kelompok : rekening_neraca.kode_rekening_neraca_kelompok == null ? null : rekening_neraca.kode_rekening_neraca_kelompok,
            kode_rekening_neraca_jenis : rekening_neraca.kode_rekening_neraca_jenis == null ? null : rekening_neraca.kode_rekening_neraca_jenis,
            kode_rekening_neraca_objek : rekening_neraca.kode_rekening_neraca_objek == null ? null : rekening_neraca.kode_rekening_neraca_objek,
            kode_rekening_neraca_rincian_objek : rekening_neraca.kode_rekening_neraca_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_rincian_objek,
            nama_rekening_neraca_rincian_objek: rekening_neraca.nama_rekening_neraca_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_rincian_objek,
            kode_rekening_neraca_sub_rincian_objek : rekening_neraca.kode_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_sub_rincian_objek,
            nama_rekening_neraca_sub_rincian_objek: rekening_neraca.nama_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_sub_rincian_objek,
            nama_barang_pengajuan_barang_jasa : datum.nama_barang_pengajuan_barang_jasa == null ? null : datum.nama_barang_pengajuan_barang_jasa,
            ba_srt_nomor : datum.ba_srt_nomor == null ? null : datum.ba_srt_nomor,
            ba_srt_tanggal : datum.ba_srt_tanggal == null ? null : datum.ba_srt_tanggal,
            volume_barang_pengajuan_barang_jasa : datum.volume_barang_pengajuan_barang_jasa == null ? null : datum.volume_barang_pengajuan_barang_jasa,
            satuan_barang_pengajuan_barang_jasa : datum.satuan_barang_pengajuan_barang_jasa == null ? null : datum.satuan_barang_pengajuan_barang_jasa,
            harga_satuan_barang_pengajuan_barang_jasa : datum.harga_satuan_barang_pengajuan_barang_jasa == null ? null : datum.harga_satuan_barang_pengajuan_barang_jasa,
            // sumber_dana_pengajuan_barang_jasa: this.sumber_dana_pengajuan_barang_jasas.find(item => item.value == datum.sumber_dana_pengajuan_barang_jasa),
            sumber_dana_pengajuan_barang_jasa: datum.sumber_dana_pengajuan_barang_jasa == null ? null : datum.sumber_dana_pengajuan_barang_jasa,
            keterangan_pengajuan_barang_jasa : datum.keterangan_pengajuan_barang_jasa == null ? null : datum.keterangan_pengajuan_barang_jasa,
            kategori_pengajuan_barang_jasa : datum.kategori_pengajuan_barang_jasa,
            status_persetujuan_satker_pengajuan_barang_jasa : parseInt(datum.status_persetujuan_satker_pengajuan_barang_jasa),
            status_persetujuan_bpkad_pengajuan_barang_jasa : parseInt(datum.status_persetujuan_bpkad_pengajuan_barang_jasa),
            status_persetujuan_laporan_pengajuan_barang_jasa : parseInt(datum.status_persetujuan_laporan_pengajuan_barang_jasa),
            nama_satuan_kerja_eselon_dua : datum.nama_satuan_kerja_eselon_dua,
          });
          id = id + 1;
        });
        this.pengajuanBarangJasaData = data_temp;
        // console.log(data_temp);
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .field {
    width: 100%;
  }
  .ditolak {
      font-weight: 900;
      color: #ac1818;
  }

  .belum_disetujui {
      font-weight: 900;
      color: #ab6d11;
  }

  .setuju {
      font-weight: 900;
      color: #17501a;
  }

  .undefined_status {
      font-weight: 900;
      color: #4d0f60;
  }
  
  .pengajuan_kembali {
      font-weight: 900;
      color: #945c0e;
  }
  </style>
  