<template>
  <TabView style="margin-top: 20px">
    <!-- Panel Ringkasan Persediaan -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-city" />
        <span>&nbsp; Rincian dan Status Pengajuan Persediaan</span>
      </template>
      <div class="card">
        <h3 class="my-4">
          Daftar Pengajuan Barang Jasa Persediaan
        </h3>

        <!-- datatable untuk temporary data yang bisa di edit -->
        <DataTable
          :value="pengajuanBarangJasaPersediaanTerkirimData"
          v-model:filters="pengajuanBarangJasaPersediaanTerkirimFilterPencarian"
          filterDisplay="menu"
          key="id_temp"
          :globalFilterFields="['nama_barang_pengajuan_barang_jasa', 'sumber_dana_pengajuan_barang_jasa', 'harga_satuan_barang_pengajuan_barang_jasa']"
          v-model:editingRows="pengajuanBarangJasaPersediaanTerkirimEditingRows"
          @row-edit-save="pengajuanBarangJasaPersediaanTerkirimOnRowEditSave"
          responsiveLayout="scroll"
          editMode="row"
          v-model:selection="pengajuanBarangJasaPersediaanTerkirimSelectingRows"
          ref="dt"
          :paginator="true"
          :rows="10"
        >
          <template #empty> Belum ada data Pengajuan Barang Jasa </template>
          <template #loading> Loading . . . </template>
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between"
            >
              <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger my-1"
                @click="pengajuanBarangJasaPersediaanTerkirimConfirmationDeleteSelected"
                :disabled="
                  !pengajuanBarangJasaPersediaanTerkirimSelectingRows ||
                  !pengajuanBarangJasaPersediaanTerkirimSelectingRows.length
                "
              ></Button>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="pengajuanBarangJasaPersediaanTerkirimFilterPencarian['global'].value"
                  placeholder="Cari kode atau detail aset"
                />
              </span>
            </div>
          </template>
          <!-- <div  v-if="data.status_persetujuan_bpkad_pengajuan_barang_jasa != 1">
            <Column
              selectionMode="multiple"
              style="width: 3rem"
              :exportable="false"
            ></Column>
          </div>
          <div v-else>
            <Column
              selectionMode="multiple"
              style="width: 3rem;display: none;"
              :exportable="false"
            ></Column>
          </div> -->
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            header="Kode Rekening Neraca"
            footer="Kode Rekening Neraca"
            :style="{ width: '200px' }"
          >
            <template #body="slotProps">
              {{ slotProps.data.kode_rekening_neraca_akun +'.'+ slotProps.data.kode_rekening_neraca_kelompok +'.'+ slotProps.data.kode_rekening_neraca_jenis +'.'+ slotProps.data.kode_rekening_neraca_objek +'.'+ slotProps.data.kode_rekening_neraca_rincian_objek +'.'+ slotProps.data.kode_rekening_neraca_sub_rincian_objek}}
            </template>
          </Column>

          <Column
            field="nama_rekening_neraca_rincian_objek"
            header="Nama Rincian Objek"
            footer="Nama Rincian Objek"
            style="min-width:300px"
          >
          </Column>
          
          <Column
            field="rekening_neraca"
            header="Nama Sub Rincian Objek"
            footer="Nama Sub Rincian Objek"
            style="min-width:300px"
          >
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="rekeningNeracaSubRincianObjekPersediaanData"
                optionLabel="nama_rekening_neraca_sub_rincian_objek"
                style="text-align: start"
                class="flex"
                :filter="true">
                <template #option="slotProps">
                    <span>{{slotProps.option.kode_rekening_neraca_akun+'.'+slotProps.option.kode_rekening_neraca_kelompok+'.'+slotProps.option.kode_rekening_neraca_jenis+'.'+slotProps.option.kode_rekening_neraca_objek+'.'+slotProps.option.kode_rekening_neraca_rincian_objek+'.'+slotProps.option.kode_rekening_neraca_sub_rincian_objek+' '+slotProps.option.nama_rekening_neraca_sub_rincian_objek}}</span>
                </template>
              </Dropdown>
            </template>
            <template #body="slotProps">
                <!-- {{ slotProps.data.rekening_neraca.nama_rekening_neraca_sub_rincian_objek }} -->
                {{slotProps.data.kode_rekening_neraca_akun+'.'+slotProps.data.kode_rekening_neraca_kelompok+'.'+slotProps.data.kode_rekening_neraca_jenis+'.'+slotProps.data.kode_rekening_neraca_objek+'.'+slotProps.data.kode_rekening_neraca_rincian_objek+'.'+slotProps.data.kode_rekening_neraca_sub_rincian_objek+' '+slotProps.data.nama_rekening_neraca_sub_rincian_objek}}
            </template>
          </Column>

          <Column
            field="nama_barang_pengajuan_barang_jasa"
            header="Nama Barang Pengajuan"
            footer="Nama Barang Pengajuan"
            style="min-width:450px"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="ba_srt_nomor"
            header="Nomor BA SRT Pengajuan"
            footer="Nomor BA SRT Pengajuan"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="ba_srt_tanggal"
            header="Tanggal BA SRT Pengajuan"
            footer="Tanggal BA SRT Pengajuan"
            style="min-width: 200px"
          >
            <template #editor="{ data, field }">
              <Calendar
                v-model="data[field]"
                dateFormat="yy/mm/dd"
                :showTime="false"
              />
            </template>
          </Column>
          <Column
            field="volume_barang_pengajuan_barang_jasa"
            header="Volume Pengajuan"
            footer="Volume Pengajuan"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputNumber  
                v-model="data[field]" 
                mode="decimal"
                locale="de-DE"
                :maxFractionDigits="4" />
            </template>
          </Column>
          <Column
            field="satuan_barang_pengajuan_barang_jasa"
            header="Satuan Barang Pengajuan"
            footer="Satuan Barang Pengajuan"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>
            </template>
          </Column>
          <Column
            field="harga_satuan_barang_pengajuan_barang_jasa"
            header="Harga Satuan Barang"
            footer="Harga Satuan Barang"
            :style="{ width: '200px' }"
          >
            <template #editor="{ data, field }">
              <InputNumber  
                v-model="data[field]" 
                mode="decimal"
                locale="de-DE"
                :maxFractionDigits="4" />
            </template>
          </Column>
          <Column
            field="sumber_dana_pengajuan_barang_jasa"
            header="Sumber Dana Pengajuan"
            footer="Sumber Dana Pengajuan"
            style="min-width:250px"
          >
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="sumber_dana_pengajuan_barang_jasas"
                optionLabel="name"
                optionValue="value"
                style="text-align: start"
                class="flex"
              />
            </template>
            <template #body="slotProps">
                {{ (sumber_dana_pengajuan_barang_jasas.find(item => item.value == slotProps.data.sumber_dana_pengajuan_barang_jasa)).name }}
            </template>
          </Column>
          <Column
            field="keterangan_pengajuan_barang_jasa"
            header="Keterangan Pengajuan"
            footer="Keterangan Pengajuan"
            style="min-width:500px"
          >
            <template #editor="{ data, field }">
              <InputText class="field" v-model="data[field]" />
            </template>
          </Column>
          <Column
            field="status_persetujuan_satker_pengajuan_barang_jasa"
            header="Status Persetujuan Satker"
            footer="Status Persetujuan Satker"
            style="min-width:500px"
          >
              <template #editor="{ data, field }">
                <Dropdown
                    v-model="data[field]"
                    :options="pengajuans"
                    optionLabel="name"
                    optionValue="value"
                    style="text-align: start"
                    class="flex"
                />
              </template>
              <template #body="slotProps">
                  <span :class="getStatusClass(parseInt(slotProps.data.status_persetujuan_satker_pengajuan_barang_jasa))">{{ (persetujuans.find(item => item.value == parseInt(slotProps.data.status_persetujuan_satker_pengajuan_barang_jasa))).name }}</span>
              </template>
          </Column>

          <Column
            field="status_persetujuan_bpkad_pengajuan_barang_jasa"
            header="Status Persetujuan BPKAD"
            footer="Status Persetujuan BPKAD"
            style="min-width:500px"
          >
            <template #body="slotProps">
                <span :class="getStatusClass(parseInt(slotProps.data.status_persetujuan_bpkad_pengajuan_barang_jasa))">{{ (persetujuans.find(item => item.value == parseInt(slotProps.data.status_persetujuan_bpkad_pengajuan_barang_jasa))).name }}</span>
            </template>
          </Column>
          <Column
            field="status_persetujuan_laporan_pengajuan_barang_jasa"
            header="Status Persetujuan Laporan"
            footer="Status Persetujuan Laporan"
            style="min-width:500px"
          >
            <template #body="slotProps">
                <span :class="getStatusClass(parseInt(slotProps.data.status_persetujuan_laporan_pengajuan_barang_jasa))">{{ (persetujuans.find(item => item.value == parseInt(slotProps.data.status_persetujuan_laporan_pengajuan_barang_jasa))).name }}</span>
            </template>
          </Column>
          <Column
            :rowEditor="true"
            style="width: 20%; min-width: 10rem"
            bodyStyle="text-align:center"
          >
            <template #header>
              <Button
                type="button"
                class="p-button-text"
                style="color: black"
                >Edit Pengajuan</Button
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <!-- Panel Pengajuan Persediaan -->
    <TabPanel>
      <template #header>
        <font-awesome-icon icon="fa-solid fa-boxes-stacked" />
        <span>&nbsp; Pengajuan Persediaan </span>
      </template>
      <div class="card">
        <Accordion>
          <!-- Accordion Input Satu Per Satu Persediaan -->
          <AccordionTab
            header="Formulir Pengajuan Persediaan (Opsi 1)"
          >
            <div class="flex-1 mt-2">
              <h3>Rincian Akun (Otomatis)</h3>
              <p>
                <strong
                  >Otomatis terisi dari hasil pencarian Sub Rincian
                  Objek</strong
                >
              </p>
            </div>
            <div class="card">
                <div class="grid ">
                  <div class="col-4 md:col-4 sm:col-12 mt-2">
                    <span class="p-float-label">
                      <InputText class="w-full" readonly id="nama_rekening_neraca_akun" type="text" v-model="rekeningNeracaAkunPersediaanKodeNama" />
                      <label for="nama_rekening_neraca_akun">Nama Akun</label>
                    </span>
                  </div>
                  <div class="col-4 md:col-4 sm:col-12 mt-2">
                    <span class="p-float-label">
                      <InputText class="w-full" readonly id="nama_rekening_neraca_kelompok" type="text" v-model="rekeningNeracaKelompokPersediaanKodeNama" />
                      <label for="nama_rekening_neraca_kelompok">Nama Kelompok</label>
                    </span>
                  </div>
                  <div class="col-4 md:col-4 sm:col-12 mt-2">
                    <span class="p-float-label">
                      <InputText class="w-full" readonly id="nama_rekening_neraca_jenis" type="text" v-model="rekeningNeracaJenisPersediaanKodeNama" />
                      <label for="nama_rekening_neraca_jenis">Nama Jenis</label>
                    </span>
                  </div>
                  <div class="col-4 md:col-4 sm:col-12 mt-2">
                    <span class="p-float-label">
                      <InputText class="w-full" readonly id="nama_rekening_neraca_objek" type="text" v-model="rekeningNeracaObjekPersediaanKodeNama" />
                      <label for="nama_rekening_neraca_objek">Nama Objek</label>
                    </span>
                  </div>
                  <div class="col-8 md:col-8 sm:col-12 mt-2">
                    <span class="p-float-label">
                      <InputText class="w-full" readonly id="nama_rekening_neraca_rincian_objek" type="text" v-model="rekeningNeracaRincianObjekPersediaanKodeNama" />
                      <label for="nama_rekening_neraca_rincian_objek">Nama Rincian Objek</label>
                    </span>
                  </div>
                </div>
            </div>
            <div class="grid">
              <div class="col-12 md:col-12 sm:col-12">
                <h3>Rincian Pengajuan</h3>
              </div>
              <div class="col-12 md:col-12 sm:col-12 mt-2">
                <span class="p-float-label">
                  <!-- DROPDOWN pencarian -->
                  <Dropdown
                    id="rekeningNeracaSubRincianObjekPersediaan"
                    v-model="rekeningNeracaSubRincianObjekPersediaanDropdown"
                    :options="rekeningNeracaSubRincianObjekPersediaanData"
                    @change="rekeningNeracaSubRincianObjekPersediaanChangeDropdown"
                    optionLabel="nama_rekening_neraca_sub_rincian_objek"
                    style="text-align: start"
                    class="flex"
                    :filter="true"
                  >
                  <template #option="slotProps">
                      <span>{{slotProps.option.kode_rekening_neraca_akun+'.'+slotProps.option.kode_rekening_neraca_kelompok+'.'+slotProps.option.kode_rekening_neraca_jenis+'.'+slotProps.option.kode_rekening_neraca_objek+'.'+slotProps.option.kode_rekening_neraca_rincian_objek+'.'+slotProps.option.kode_rekening_neraca_sub_rincian_objek+' '+slotProps.option.nama_rekening_neraca_sub_rincian_objek}}</span>
                  </template>
                  <template #body="slotProps">
                      <span>{{slotProps.data.kode_rekening_neraca_akun+'.'+slotProps.data.kode_rekening_neraca_kelompok+'.'+slotProps.data.kode_rekening_neraca_jenis+'.'+slotProps.data.kode_rekening_neraca_objek+'.'+slotProps.data.kode_rekening_neraca_rincian_objek+'.'+slotProps.data.kode_rekening_neraca_sub_rincian_objek+' '+slotProps.data.nama_rekening_neraca_sub_rincian_objek}}</span>
                  </template>
                  </Dropdown>
                  <label for="rekeningNeracaSubRincianObjekPersediaan"
                    >Sub Rincinan Objek Persediaan</label
                  >
                </span>
              </div>
              <div class="col-6 md:col-6 sm:col-12 mt-2">
                <span class="p-float-label">
                  <InputText
                    id="persediaanbasrtnomor"
                    v-model="pengajuanBarangJasaPersediaan.ba_srt_nomor"
                    style="text-align: start;"
                    class="flex w-full"
                  />
                  <label for="persediaanbasrtnomor">BA/SRT Nomor</label>
                </span>
              </div>
              <div class="col-6 md:col-6 sm:col-12 mt-2">
                <span class="p-float-label">
                  <!-- <InputText
                    id="persediaanbasrttanggal"
                    v-model="pengajuanBarangJasaPersediaan.ba_srt_tanggal"
                    style="text-align: start;"
                    class="flex w-full"
                  /> -->
                  <Calendar class="flex w-full" v-model="pengajuanBarangJasaPersediaan.ba_srt_tanggal" inputId="persediaanbasrttanggal" dateFormat="yy/mm/dd" />

                  <label for="persediaanbasrttanggal">BA/SRT Tanggal</label>
                </span>
              </div>
              <div class="col-6 md:col-6 sm:col-12 mt-2">
                <span class="p-float-label">
                  <InputText
                    id="persediaanTKDN"
                    v-model="pengajuanBarangJasaPersediaan.sertifikat_tkdn_pengajuan_barang_jasa"
                    style="text-align: start;"
                    class="flex w-full"
                  />
                  <label for="persediaanTKDN">Nomor Sertifikat TKDN Barang (Opsional)</label>
                </span>
              </div>
              <div class="col-6 md:col-6 sm:col-12 mt-2">
                <span class="p-float-label">
                  <InputText
                    id="persediaanPDN"
                    v-model="pengajuanBarangJasaPersediaan.persentase_pdn_pengajuan_barang_jasa"
                    style="text-align: start;"
                    class="flex w-full"
                  />
                  <label for="persediaanPDN">Persentase Produksi Dalam Negeri (PDN) (Opsional)</label>
                </span>
              </div>
              <div class="col-12 md:col-12 sm:col-12 mt-2">
                <span class="p-float-label flex">
                  <InputText
                    id="persediaanAkun"
                    v-model="pengajuanBarangJasaPersediaan.nama_barang_pengajuan_barang_jasa"
                    style="text-align: start; width: 100%"
                  />
                  <label for="persediaanAkun">Nama Barang</label>
                </span>
              </div>
              <div class="col-4 md:col-4 sm:col-12 mt-2">
                <span class="p-float-label flex">
                  <InputNumber
                    mode="decimal"
                    locale="de-DE"
                    :maxFractionDigits="4"
                    id="persediaanAkun"
                    v-model="pengajuanBarangJasaPersediaan.volume_barang_pengajuan_barang_jasa"
                    style="text-align: start;"
                    class="w-full"
                  />
                  <label for="persediaanAkun">Volume</label>
                </span>
              </div>
              <div class="col-4 md:col-4 sm:col-12 mt-2">
                <span class="p-float-label flex">
                  <InputText
                    id="persediaanAkun"
                    v-model="pengajuanBarangJasaPersediaan.satuan_barang_pengajuan_barang_jasa"
                    style="text-align: start;"
                    class="w-full"
                  />
                  <label for="persediaanAkun">Satuan</label>
                </span>
              </div>
              <div class="col-4 md:col-4 sm:col-12 mt-2">
                <span class="p-float-label flex">
                  <InputNumber
                    mode="decimal"
                    locale="de-DE"
                    :maxFractionDigits="4"
                    id="persediaanAkun"
                    v-model="pengajuanBarangJasaPersediaan.harga_satuan_barang_pengajuan_barang_jasa"
                    style="text-align: start;"
                    class="w-full"
                  />
                  <label for="persediaanAkun">Harga Satuan</label>
                </span>
              </div>
              <div class="col-12 md:col-12 sm:col-12 mt-2">
                <span class="p-float-label flex">
                  <InputNumber
                    mode="decimal"
                    locale="de-DE"
                    :maxFractionDigits="4"
                    disabled
                    id="persediaanAkun"
                    v-model="pengajuanBarangJasaPersediaanTotalHarga"
                    style="text-align: start;"
                    class="w-full"
                    readonly
                  />
                  <label for="persediaanAkun">Harga Total</label>
                </span>
              </div>
              <div class="col-6 md:col-6 sm:col-12 mt-2">
                <span class="p-float-label">
                  <Dropdown
                    id="persediaanAkun"
                    v-model="pengajuanBarangJasaPersediaan.sumber_dana_pengajuan_barang_jasa"
                    :options="sumber_dana_pengajuan_barang_jasas"
                    optionLabel="name"
                    style="text-align: start"
                    class="flex"
                  />
                  <label for="persediaanAkun">Sumber Dana</label>
                </span>
              </div>
              <div class="col-6 md:col-6 sm:col-12 mt-2">
                <span class="p-float-label">
                  <TextArea
                    rows="5"
                    id="persediaanAkun"
                    v-model="pengajuanBarangJasaPersediaan.keterangan_pengajuan_barang_jasa"
                    style="text-align: start;"
                    class="w-full"
                  />
                  <label for="persediaanAkun">Keterangan Pengajuan</label>
                </span>
              </div>
              <div class="col-12 md:col-12 sm:col-12 mt-2">
                <Button
                  class="p-button-success right-0"
                  label="Kirim Pengajuan"
                  @click="pengajuanBarangJasaPersediaanSubmitDataBaru"
                ></Button>
              </div>
            </div>
          </AccordionTab>
          <!-- Accordion Upload File Persediaan -->
          <AccordionTab
            header="Unggah File Pengajuan persediaan (Opsi 2)"
          >
            <Toolbar
              class="fluid flex flex-column md:flex-row md:justify-content-between"
            >
              <template #start>
                <FileUpload
                  mode="basic"
                  name="pengajuan_barang_jasa_file[]"
                  :maxFileSize="1000000"
                  :customUpload="true"
                  @uploader="pengajuanBarangJasaPersediaanTempUploadTemplate"
                  class="my-2 mr-2 p-button-help"
                  chooseLabel="Load Data dari Template Pengajuan Persediaan"
                  chooseIcon="pi pi-upload"
                />
                <Button
                  label="Download Template Pengajuan Persediaan"
                  icon="pi pi-download"
                  class="p-button-help my-1"
                  @click="downloadTemplatePengajuanBarangJasaPersediaan()"
                ></Button>
              </template>
            </Toolbar>
            <h3 class="my-4">
              Data Pengajuan Barang Jasa yang akan dikirimkan
            </h3>

            <!-- datatable untuk temporary data yang bisa di edit -->
            <DataTable
              :value="pengajuanBarangJasaPersediaanTempData"
              v-model:filters="pengajuanBarangJasaPersediaanTempFilterPencarian"
              filterDisplay="menu"
              key="id_temp"
              :globalFilterFields="['nama_barang_pengajuan_barang_jasa', 'sumber_dana_pengajuan_barang_jasa', 'harga_satuan_barang_pengajuan_barang_jasa']"
              v-model:editingRows="pengajuanBarangJasaPersediaanTempEditingRows"
              @row-edit-save="pengajuanBarangJasaPersediaanTempOnRowEditSave"
              responsiveLayout="scroll"
              editMode="row"
              v-model:selection="pengajuanBarangJasaPersediaanTempSelectingRows"
              ref="dt"
              :paginator="true"
              :rows="10"
            >
              <template #empty> Belum ada data Pengajuan Barang Jasa </template>
              <template #loading> Loading . . . </template>
              <template #header>
                <div
                  class="table-header flex flex-column md:flex-row md:justify-content-between"
                >
                  <Button
                    label="Delete"
                    icon="pi pi-trash"
                    class="p-button-danger my-1"
                    @click="pengajuanBarangJasaPersediaanTempConfirmationDeleteSelected"
                    :disabled="
                      !pengajuanBarangJasaPersediaanTempSelectingRows ||
                      !pengajuanBarangJasaPersediaanTempSelectingRows.length
                    "
                  ></Button>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText
                      v-model="pengajuanBarangJasaPersediaanTempFilterPencarian['global'].value"
                      placeholder="Cari kode atau detail aset"
                    />
                  </span>
                </div>
              </template>
              <Column
                selectionMode="multiple"
                style="width: 3rem"
                :exportable="false"
              ></Column>
              <Column
                header="Kode Rekening Neraca"
                footer="Kode Rekening Neraca"
                :style="{ width: '200px' }"
              >
                <template #body="slotProps">
                  {{ slotProps.data.kode_rekening_neraca_akun +'.'+ slotProps.data.kode_rekening_neraca_kelompok +'.'+ slotProps.data.kode_rekening_neraca_jenis +'.'+ slotProps.data.kode_rekening_neraca_objek +'.'+ slotProps.data.kode_rekening_neraca_rincian_objek +'.'+ slotProps.data.kode_rekening_neraca_sub_rincian_objek}}
                </template>
              </Column>
              <!-- <Column
                field="kode_rekening_neraca_akun"
                header="Kode Akun"
                footer="Kode Akun"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                field="kode_rekening_neraca_kelompok"
                header="Kode Kelompok"
                footer="Kode Kelompok"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                field="kode_rekening_neraca_jenis"
                header="Kode Jenis"
                footer="Kode Jenis"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                field="kode_rekening_neraca_objek"
                header="Kode Objek"
                footer="Kode Objek"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                field="kode_rekening_neraca_rincian_objek"
                header="Kode Rincian Objek"
                footer="Kode Rincian Objek"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                field="kode_rekening_neraca_sub_rincian_objek"
                header="Kode Sub Rincian Objek"
                footer="Kode Sub Rincian Objek"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column> -->
              <Column
                field="nama_rekening_neraca_rincian_objek"
                header="Nama Rincian Objek"
                footer="Nama Rincian Objek"
                style="min-width:300px"
              >
              </Column>
              
              <Column
                field="rekening_neraca"
                header="Nama Sub Rincian Objek"
                footer="Nama Sub Rincian Objek"
                style="min-width:300px"
              >
                <template #editor="{ data, field }">
                  <Dropdown
                    v-model="data[field]"
                    :options="rekeningNeracaSubRincianObjekPersediaanData"
                    optionLabel="nama_rekening_neraca_sub_rincian_objek"
                    style="text-align: start"
                    class="flex"
                    :filter="true">
                    <template #option="slotProps">
                        <span>{{slotProps.option.kode_rekening_neraca_akun+'.'+slotProps.option.kode_rekening_neraca_kelompok+'.'+slotProps.option.kode_rekening_neraca_jenis+'.'+slotProps.option.kode_rekening_neraca_objek+'.'+slotProps.option.kode_rekening_neraca_rincian_objek+'.'+slotProps.option.kode_rekening_neraca_sub_rincian_objek+' '+slotProps.option.nama_rekening_neraca_sub_rincian_objek}}</span>
                    </template>
                  </Dropdown>
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.rekening_neraca.nama_rekening_neraca_sub_rincian_objek }}
                </template>
              </Column>
              <Column
                field="nama_barang_pengajuan_barang_jasa"
                header="Nama Barang Pengajuan"
                footer="Nama Barang Pengajuan"
                style="min-width:450px"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                field="ba_srt_nomor"
                header="Nomor BA SRT Pengajuan"
                footer="Nomor BA SRT Pengajuan"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                field="ba_srt_tanggal"
                header="Tanggal BA SRT Pengajuan"
                footer="Tanggal BA SRT Pengajuan"
                style="min-width: 200px"
              >
                <template #editor="{ data, field }">
                  <Calendar
                    v-model="data[field]"
                    dateFormat="yy/mm/dd"
                    :showTime="false"
                  />
                </template>
              </Column>
              <Column
                field="volume_barang_pengajuan_barang_jasa"
                header="Volume Pengajuan"
                footer="Volume Pengajuan"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputNumber  
                    v-model="data[field]" 
                    mode="decimal"
                    locale="de-DE"
                    :maxFractionDigits="4" />
                </template>
              </Column>
              <Column
                field="satuan_barang_pengajuan_barang_jasa"
                header="Satuan Barang Pengajuan"
                footer="Satuan Barang Pengajuan"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]"/>
                </template>
              </Column>
              <Column
                field="harga_satuan_barang_pengajuan_barang_jasa"
                header="Harga Satuan Barang"
                footer="Harga Satuan Barang"
                :style="{ width: '200px' }"
              >
                <template #editor="{ data, field }">
                  <InputNumber  
                    v-model="data[field]" 
                    mode="decimal"
                    locale="de-DE"
                    :maxFractionDigits="4" />
                </template>
              </Column>
              <Column
                field="sumber_dana_pengajuan_barang_jasa"
                header="Sumber Dana Pengajuan"
                footer="Sumber Dana Pengajuan"
                style="min-width:250px"
              >
                <template #editor="{ data, field }">
                  <Dropdown
                    v-model="data[field]"
                    :options="sumber_dana_pengajuan_barang_jasas"
                    optionLabel="name"
                    optionValue="value"
                    style="text-align: start"
                    class="flex"
                  />
                </template>
                <template #body="slotProps">
                    {{ (sumber_dana_pengajuan_barang_jasas.find(item => item.value == slotProps.data.sumber_dana_pengajuan_barang_jasa)).name }}
                </template>
              </Column>
              <Column
                field="keterangan_pengajuan_barang_jasa"
                header="Keterangan Pengajuan"
                footer="Keterangan Pengajuan"
                style="min-width:500px"
              >
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" />
                </template>
              </Column>
              <Column
                :rowEditor="true"
                style="width: 20%; min-width: 10rem"
                bodyStyle="text-align:center"
              >
                <template #header>
                  <Button
                    type="button"
                    class="p-button-text"
                    style="color: black"
                    >Edit Pengajuan</Button
                  >
                </template>
              </Column>
            </DataTable>
            
            <Toolbar
              class="fluid flex flex-column md:flex-row md:justify-content-between"
            >
              <template #end>
                <Button
                  label="Kirim Data Pengajuan Terpilih"
                  icon="pi pi-send"
                  class="p-button-success my-2 pa-2"
                  @click="pengajuanBarangJasaPersediaanTempSendData()"
                  :disabled="
                    !pengajuanBarangJasaPersediaanTempSelectingRows ||
                    !pengajuanBarangJasaPersediaanTempSelectingRows.length
                  "
                ></Button>
              </template>
            </Toolbar>
          </AccordionTab>
        </Accordion>
      </div>
    </TabPanel>
  </TabView>

  <!-- <Button type="button" label="Pencarian" icon="pi pi-search" style="margin:5px;background-color:black;color:red;border: 5px solid white;border-radius: 6px;"/> -->
  <ConfirmDialog style="color: black"></ConfirmDialog>

  <!-- dialog pendaftaran user -->
  <Dialog
    v-model:visible="rekeningNeracaSubRincianObjekPersediaanDialog"
    :style="{ width: '80vh' }"
    :header="rekeningNeracaSubRincianObjekPersediaanBaru ? 'Pendaftaran Baru' : 'Pemutakhiran Data User'"
    :modal="true"
    class="p-fluid"
  >
    <div class="field" v-if="!rekeningNeracaSubRincianObjekPersediaanBaru">
      <label for="id">ID</label>
      <InputText id="id" v-model="user.id" required="true" disabled />
    </div>
    <div class="field">
      <label for="name">Nama</label>
      <InputText id="name" v-model="user.name" required="true" />
    </div>
    <div class="field">
      <label for="email">Email</label>
      <InputText
        id="email"
        v-model="user.email"
        required="true"
        class="email"
      />
    </div>
    <div class="field">
      <label for="password">Password</label>
      <Password
        id="password"
        v-model="user.password"
        required="true"
        toggleMask
      ></Password>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="rekeningNeracaSubRincianObjekPersediaanDialog = false"
        style="color: red"
      />
      <Button
        v-if="rekeningNeracaSubRincianObjekPersediaanBaru"
        label="Submit User Baru"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitDatarekeningNeracaSubRincianObjekPersediaanBaru"
      />
      <Button
        v-else
        label="Submit Pemutakhiran Data"
        icon="pi pi-check"
        class="p-button-text"
        style="color: black"
        @click="submitPemutahiranDataUser"
      />
    </template>
  </Dialog>
</template>
<script>
import UserService from "@/services/user.service";
import moment from 'moment';
import RekeningNeracaSubRincianObjekService from "@/services/rekening-neraca-sub-rincian-objek.service";
import PengajuanBarangJasaService from "@/services/pengajuan-barang-jasa.service";
import { read, utils, writeFile } from 'xlsx';
import { FilterMatchMode, FilterOperator } from "primevue/api";
import "primeflex/primeflex.scss";

export default {
  components: {},
  data() {
    return {
      // basic
      loaderOverlay: null,
      user: {
        id: null,
        name: null,
        email: null,
      },
      users: [],
      sumber_dana_pengajuan_barang_jasas: [
        { 
          name : 'Pembelian',
          value: 'pembelian',
        },
        { 
          name : 'Penjualan Aset',
          value: 'penjualan_aset',
        },
        { 
          name : 'Hibah',
          value: 'hibah',
        },
      ],
      persetujuans: [
        { 
          name : 'SETUJU',
          value: 1,
        },
        { 
          name : 'BELUM DISETUJUI',
          value: 0,
        },
        { 
          name : 'DITOLAK',
          value: 2,
        },
        { 
          name : 'PENGAJUAN KEMBALI',
          value: 4,
        },
      ],
      pengajuans: [
        { 
          name : 'PENGAJUAN KEMBALI',
          value: 4,
        },
        { 
          name : 'BELUM DISETUJUI',
          value: 0,
        },
      ],
      // End basic
      // Variabel Pengajuan Barang Jasa Persediaan
      pengajuanBarangJasaPersediaanDialog: false,
      pengajuanBarangJasaPersediaanBaru: true,
      pengajuanBarangJasaPersediaanData: [],
      pengajuanBarangJasaPersediaanEditingRows: [],
      pengajuanBarangJasaPersediaanSelectingRows: [],
      pengajuanBarangJasaPersediaanDropdown: null,
      pengajuanBarangJasaPersediaanFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_barang_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        sumber_dana_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kategori_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        keterangan_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      pengajuanBarangJasaPersediaanDatum: {
        id_rekening_neraca_sub_rincian_objek: null,
        id_rekening_neraca_rincian_objek: null,
        ba_srt_nomor: null,
        ba_srt_tanggal: null,
        sertifikat_tkdn_pengajuan_barang_jasa: null,
        persentase_pdn_pengajuan_barang_jasa: null,
        nama_barang_pengajuan_barang_jasa: null,
        volume_barang_pengajuan_barang_jasa: null,
        harga_satuan_barang_pengajuan_barang_jasa: null,
        satuan_barang_pengajuan_barang_jasa: null,
        kategori_pengajuan_barang_jasa: null,
        keterangan_pengajuan_barang_jasa: null,
        sumber_dana_pengajuan_barang_jasa: null,
      },
      pengajuanBarangJasaPersediaan: {
        id_rekening_neraca_sub_rincian_objek: null,
        id_rekening_neraca_rincian_objek: null,
        ba_srt_nomor: null,
        ba_srt_tanggal: null,
        sertifikat_tkdn_pengajuan_barang_jasa: null,
        persentase_pdn_pengajuan_barang_jasa: null,
        nama_barang_pengajuan_barang_jasa: null,
        volume_barang_pengajuan_barang_jasa: null,
        harga_satuan_barang_pengajuan_barang_jasa: null,
        satuan_barang_pengajuan_barang_jasa: null,
        kategori_pengajuan_barang_jasa: null,
        keterangan_pengajuan_barang_jasa: null,
        sumber_dana_pengajuan_barang_jasa: null,
      },
      // end
      // Variabel Pengajuan Barang Jasa Persediaan yang dilakukan checking terlebih dahulu
      pengajuanBarangJasaPersediaanTempDialog: false,
      pengajuanBarangJasaPersediaanTempBaru: true,
      pengajuanBarangJasaPersediaanTempData: [],
      pengajuanBarangJasaPersediaanTempEditingRows: [],
      pengajuanBarangJasaPersediaanTempSelectingRows: [],
      pengajuanBarangJasaPersediaanTempDropdown: null,
      pengajuanBarangJasaPersediaanTempFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_barang_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        sumber_dana_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kategori_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        keterangan_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      pengajuanBarangJasaPersediaanTempDatum: {
        id_rekening_neraca_sub_rincian_objek: null,
        id_rekening_neraca_rincian_objek: null,
        ba_srt_nomor: null,
        ba_srt_tanggal: null,
        nama_barang_pengajuan_barang_jasa: null,
        volume_barang_pengajuan_barang_jasa: null,
        harga_satuan_barang_pengajuan_barang_jasa: null,
        satuan_barang_pengajuan_barang_jasa: null,
        kategori_pengajuan_barang_jasa: null,
        keterangan_pengajuan_barang_jasa: null,
        sumber_dana_pengajuan_barang_jasa: null,
      },
      pengajuanBarangJasaPersediaanTemp: {
        id_rekening_neraca_sub_rincian_objek: null,
        id_rekening_neraca_rincian_objek: null,
        ba_srt_nomor: null,
        ba_srt_tanggal: null,
        nama_barang_pengajuan_barang_jasa: null,
        volume_barang_pengajuan_barang_jasa: null,
        harga_satuan_barang_pengajuan_barang_jasa: null,
        satuan_barang_pengajuan_barang_jasa: null,
        kategori_pengajuan_barang_jasa: null,
        keterangan_pengajuan_barang_jasa: null,
        sumber_dana_pengajuan_barang_jasa: null,
      },
      // End
      // Variabel Pengajuan Barang Jasa Persediaan Terkirim
      pengajuanBarangJasaPersediaanTerkirimDialog: false,
      pengajuanBarangJasaPersediaanTerkirimBaru: true,
      pengajuanBarangJasaPersediaanTerkirimData: [],
      pengajuanBarangJasaPersediaanTerkirimEditingRows: [],
      pengajuanBarangJasaPersediaanTerkirimSelectingRows: [],
      pengajuanBarangJasaPersediaanTerkirimDropdown: null,
      pengajuanBarangJasaPersediaanTerkirimFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_barang_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        sumber_dana_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        kategori_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        keterangan_pengajuan_barang_jasa: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      pengajuanBarangJasaPersediaanTerkirimDatum: {
        id_rekening_neraca_sub_rincian_objek: null,
        id_rekening_neraca_rincian_objek: null,
        ba_srt_nomor: null,
        ba_srt_tanggal: null,
        sertifikat_tkdn_pengajuan_barang_jasa: null,
        persentase_pdn_pengajuan_barang_jasa: null,
        nama_barang_pengajuan_barang_jasa: null,
        volume_barang_pengajuan_barang_jasa: null,
        harga_satuan_barang_pengajuan_barang_jasa: null,
        satuan_barang_pengajuan_barang_jasa: null,
        kategori_pengajuan_barang_jasa: null,
        keterangan_pengajuan_barang_jasa: null,
        sumber_dana_pengajuan_barang_jasa: null,
      },
      pengajuanBarangJasaPersediaanTerkirim: {
        id_rekening_neraca_sub_rincian_objek: null,
        id_rekening_neraca_rincian_objek: null,
        ba_srt_nomor: null,
        ba_srt_tanggal: null,
        sertifikat_tkdn_pengajuan_barang_jasa: null,
        persentase_pdn_pengajuan_barang_jasa: null,
        nama_barang_pengajuan_barang_jasa: null,
        volume_barang_pengajuan_barang_jasa: null,
        harga_satuan_barang_pengajuan_barang_jasa: null,
        satuan_barang_pengajuan_barang_jasa: null,
        kategori_pengajuan_barang_jasa: null,
        keterangan_pengajuan_barang_jasa: null,
        sumber_dana_pengajuan_barang_jasa: null,
      },
      // End
      // Variabel Rekening Neraca Sub Rincian Objek Persediaan
      rekeningNeracaSubRincianObjekPersediaanDialog: false,
      rekeningNeracaSubRincianObjekPersediaanBaru: true,
      rekeningNeracaSubRincianObjekPersediaanData: [],
      rekeningNeracaSubRincianObjekPersediaanEditingRows: [],
      rekeningNeracaSubRincianObjekPersediaanSelectingRows: [],
      rekeningNeracaSubRincianObjekPersediaanDropdown: null,
      rekeningNeracaSubRincianObjekPersediaanFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaSubRincianObjekPersediaanDatum: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_akun: null,
        nama_rekening_neraca_kelompok: null,
        nama_rekening_neraca_jenis: null,
        nama_rekening_neraca_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      },
      rekeningNeracaSubRincianObjekPersediaan: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_akun: null,
        nama_rekening_neraca_kelompok: null,
        nama_rekening_neraca_jenis: null,
        nama_rekening_neraca_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      },
      // End
      // Variabel Rekening Neraca Sub Rincian Objek AsetTetap
      rekeningNeracaSubRincianObjekAsetTetapDialog: false,
      rekeningNeracaSubRincianObjekAsetTetapBaru: true,
      rekeningNeracaSubRincianObjekAsetTetapData: [],
      rekeningNeracaSubRincianObjekAsetTetapEditingRows: [],
      rekeningNeracaSubRincianObjekAsetTetapSelectingRows: [],
      rekeningNeracaSubRincianObjekAsetTetapDropdown: null,
      rekeningNeracaSubRincianObjekAsetTetapFilterPencarian: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama_rekening_neraca_akun: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_kelompok: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_jenis: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nama_rekening_neraca_sub_rincian_objek: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      rekeningNeracaSubRincianObjekAsetTetapDatum: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_akun: null,
        nama_rekening_neraca_kelompok: null,
        nama_rekening_neraca_jenis: null,
        nama_rekening_neraca_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      },
      rekeningNeracaSubRincianObjekAsetTetap: {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_akun: null,
        nama_rekening_neraca_kelompok: null,
        nama_rekening_neraca_jenis: null,
        nama_rekening_neraca_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      },
      //End
    };
  },
  async created() {},
  async mounted() {
    this.loaderOverlay = this.$loading.show();
    // await this.initUsers();
    await this.rekeningNeracaSubRincianObjekPersediaanInitialization();
    await this.pengajuanBarangJasaPersediaanTerkirimInitialization();

    this.$toast.add({
      severity: "success",
      summary: "Sukses dung",
      detail: "Berhasil loading",
      life: 3000,
    });
    this.loaderOverlay.hide();
    // setTimeout(() => {
    //   this.$toast.add({severity:'success', summary: 'Sukses dung', detail: "Berhasil loading", life: 3000});
    // },
    // 5000);
  },
  computed:{
    rekeningNeracaAkunPersediaanKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun != null){
        return this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun +" "+this.rekeningNeracaSubRincianObjekPersediaan.nama_rekening_neraca_akun
      }else{
        return null
      }
    },
    rekeningNeracaKelompokPersediaanKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_kelompok != null){
        return this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_kelompok +" "+this.rekeningNeracaSubRincianObjekPersediaan.nama_rekening_neraca_kelompok
      }else{
        return null
      }
    },
    rekeningNeracaJenisPersediaanKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_jenis != null){
        return this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_jenis+" "+this.rekeningNeracaSubRincianObjekPersediaan.nama_rekening_neraca_jenis
      }else{
        return null
      }
    },
    rekeningNeracaObjekPersediaanKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun != null){
        return this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_objek+" "+this.rekeningNeracaSubRincianObjekPersediaan.nama_rekening_neraca_objek
      }else{
        return null
      }
    },
    rekeningNeracaRincianObjekPersediaanKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun != null){
        return this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_objek+"."+this.rekeningNeracaSubRincianObjekPersediaan.kode_rekening_neraca_rincian_objek+" "+this.rekeningNeracaSubRincianObjekPersediaan.nama_rekening_neraca_rincian_objek
      }else{
        return null
      }
    },
    pengajuanBarangJasaPersediaanTotalHarga: function(){
      if(this.pengajuanBarangJasaPersediaan.volume_barang_pengajuan_barang_jasa != null){
        return this.pengajuanBarangJasaPersediaan.volume_barang_pengajuan_barang_jasa * this.pengajuanBarangJasaPersediaan.harga_satuan_barang_pengajuan_barang_jasa
      }else{
        return 0
      }
    },
    rekeningNeracaAkunAsetTetapKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun != null){
        return this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun +" "+this.rekeningNeracaSubRincianObjekAsetTetap.nama_rekening_neraca_akun
      }else{
        return null
      }
    },
    rekeningNeracaKelompokAsetTetapKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_kelompok != null){
        return this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_kelompok +" "+this.rekeningNeracaSubRincianObjekAsetTetap.nama_rekening_neraca_kelompok
      }else{
        return null
      }
    },
    rekeningNeracaJenisAsetTetapKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_jenis != null){
        return this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_jenis+" "+this.rekeningNeracaSubRincianObjekAsetTetap.nama_rekening_neraca_jenis
      }else{
        return null
      }
    },
    rekeningNeracaObjekAsetTetapKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun != null){
        return this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_objek+" "+this.rekeningNeracaSubRincianObjekAsetTetap.nama_rekening_neraca_objek
      }else{
        return null
      }
    },
    rekeningNeracaRincianObjekAsetTetapKodeNama: function(){
      if(this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun != null){
        return this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_akun+"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_kelompok +"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_jenis+"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_objek+"."+this.rekeningNeracaSubRincianObjekAsetTetap.kode_rekening_neraca_rincian_objek+" "+this.rekeningNeracaSubRincianObjekAsetTetap.nama_rekening_neraca_rincian_objek
      }else{
        return null
      }
    },
    pengajuanBarangJasaAsetTetapTotalHarga: function(){
      if(this.pengajuanBarangJasaAsetTetap.volume_barang_pengajuan_barang_jasa != null){
        return this.pengajuanBarangJasaAsetTetap.volume_barang_pengajuan_barang_jasa * this.pengajuanBarangJasaAsetTetap.harga_satuan_barang_pengajuan_barang_jasa
      }else{
        return 0
      }
    },
  },
  methods: {
    async initUsers() {
      var data = await UserService.getUsers();
      this.users = data.data;
      console.log(this.users);

      var profil_temp = await UserService.getUserDetail();
      this.profil = profil_temp.data;
    },
    getStatusClass(status){
      if (status == 1) {
        return 'setuju'
      } else if(status == 0){
        return 'belum_disetujui'
      } else if(status == 2){
        return 'ditolak'
      } else if(status == 4){
        return 'pengajuan_kembali'
      } else {
        return 'undefined_status'
      }
    },
    getKodeNamaRekeningNeraca(data){
      return data.kode_rekening_neraca_rincian_objek+'.'+data.kode_rekening_neraca_sub_rincian_objek+' '+data.nama_rekening_neraca_sub_rincian_objek
    },
    //  Start bagian Rekening Neraca Sub Rincian Objek
    //  Inisiasi data Rekening Neraca Sub Rincian Objek
    async rekeningNeracaSubRincianObjekPersediaanInitialization() {
      var data =
        await RekeningNeracaSubRincianObjekService.getRekeningNeracaSubRincianObjekByAkun(5);
      this.rekeningNeracaSubRincianObjekPersediaanData = data.data;
    },
    //  fungsi reset isian
    rekeningNeracaSubRincianObjekPersediaanClear() {
      this.rekeningNeracaSubRincianObjekPersediaan = {
        kode_rekening_neraca_akun: null,
        kode_rekening_neraca_jenis: null,
        kode_rekening_neraca_kelompok: null,
        kode_rekening_neraca_objek: null,
        kode_rekening_neraca_rincian_objek: null,
        kode_rekening_neraca_sub_rincian_objek: null,
        nama_rekening_neraca_akun: null,
        nama_rekening_neraca_kelompok: null,
        nama_rekening_neraca_jenis: null,
        nama_rekening_neraca_objek: null,
        nama_rekening_neraca_rincian_objek: null,
        nama_rekening_neraca_sub_rincian_objek: null,
        detail_rekening_neraca_sub_rincian_objek: null,
      };
    },
    //  fungsi untuk hapus baris yang dipilih Neraca Sub Rincian Objek
    rekeningNeracaSubRincianObjekPersediaanConfirmationDeleteSelected() {
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data terkait Rekening Neraca Sub Rincian Objek terpilih?",
        header: "Konfirmasi Penghapusan Rekening Neraca Sub Rincian Objek",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var selected_ids = [];
          this.rekeningNeracaSubRincianObjekPersediaanSelectingRows.forEach((element) => {
            selected_ids.push(element.id_rekening_neraca_sub_rincian_objek);
          });
          var response_temp =
            await RekeningNeracaSubRincianObjekService.deleteRekeningNeracaSubRincianObjekBySelectedIds(
              selected_ids
            );

          if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.rekeningNeracaSubRincianObjekPersediaanInitialization();
            this.rekeningNeracaSubRincianObjekPersediaanDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil menghapus data Rekening Neraca Sub Rincian Objek",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    //  fungsi untuk edit tiap baris Neraca Sub Rincian Objek
    async onRowEditSaveRekeningNeracaSubRincianObjekPersediaan(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      // console.log(newData);
      var response_data =
        await RekeningNeracaSubRincianObjekService.submitEditedRekeningNeracaSubRincianObjek(
          newData
        );
      if (response_data.status != null && response_data.status == "success") {
        await this.rekeningNeracaSubRincianObjekPersediaanInitialization();
        this.rekeningNeracaSubRincianObjekPersediaanData[index] = newData;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil memutakhirkan data Rekening Neraca Sub Rincian Objek",
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response_data,
          life: 7000,
        });
      }
      this.loaderOverlay.hide();
    },
    //  fungsi untuk merubah value lain saat dropdown di klik
    rekeningNeracaSubRincianObjekPersediaanChangeDropdown(){
      // console.log(this.rekeningNeracaSubRincianObjekPersediaanDropdown);
      this.rekeningNeracaSubRincianObjekPersediaan = this.rekeningNeracaSubRincianObjekPersediaanDropdown
    },
    //  End
    //  Start bagian untuk Pengajuan Barang Jasa Persediaan
    pengajuanBarangJasaPersediaanInitialization(){},
    pengajuanBarangJasaPersediaanClear(){
      // this.rekeningNeracaSubRincianObjekPersediaan = null;
      this.pengajuanBarangJasaPersediaan = {
        nama_barang_pengajuan_barang_jasa : null,
        ba_srt_nomor : null,
        ba_srt_tanggal : null,
        sertifikat_tkdn_pengajuan_barang_jasa: null,
        persentase_pdn_pengajuan_barang_jasa: null,
        volume_barang_pengajuan_barang_jasa : null,
        harga_satuan_barang_pengajuan_barang_jasa : null,
        satuan_barang_pengajuan_barang_jasa : null,
        sumber_dana_pengajuan_barang_jasa : null,
        keterangan_pengajuan_barang_jasa : null,
        kategori_pengajuan_barang_jasa : null,
      }
    },
    async pengajuanBarangJasaPersediaanSubmitDataBaru(){
      this.loaderOverlay = this.$loading.show();
      var datum = {
        id_rekening_neraca_rincian_objek : this.rekeningNeracaSubRincianObjekPersediaan.id_rekening_neraca_rincian_objek,
        id_rekening_neraca_sub_rincian_objek : this.rekeningNeracaSubRincianObjekPersediaan.id_rekening_neraca_sub_rincian_objek,
        nama_barang_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.nama_barang_pengajuan_barang_jasa,
        ba_srt_nomor : this.pengajuanBarangJasaPersediaan == null ? null : this.pengajuanBarangJasaPersediaan.ba_srt_nomor,
        ba_srt_tanggal : this.pengajuanBarangJasaPersediaan.ba_srt_tanggal == null ? null : moment(this.pengajuanBarangJasaPersediaan.ba_srt_tanggal).format(),
        sertifikat_tkdn_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.sertifikat_tkdn_pengajuan_barang_jasa,
        persentase_pdn_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.persentase_pdn_pengajuan_barang_jasa,
        volume_barang_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.volume_barang_pengajuan_barang_jasa,
        harga_satuan_barang_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.harga_satuan_barang_pengajuan_barang_jasa,
        satuan_barang_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.satuan_barang_pengajuan_barang_jasa,
        sumber_dana_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.sumber_dana_pengajuan_barang_jasa.value,
        keterangan_pengajuan_barang_jasa : this.pengajuanBarangJasaPersediaan.keterangan_pengajuan_barang_jasa,
        kategori_pengajuan_barang_jasa : 'persediaan',
      };
      
      // console.log(datum);

      try {
        var response_temp = await PengajuanBarangJasaService.submitNewPengajuanBarangJasa(datum)

        if (
            response_temp.status != null &&
            response_temp.status == "success"
          ) {
            await this.pengajuanBarangJasaPersediaanTerkirimInitialization();
            this.rekeningNeracaSubRincianObjekPersediaanClear();
            this.pengajuanBarangJasaPersediaanClear();
            this.$toast.add({
              severity: "success",
              summary: "Sukses",
              detail: "Berhasil Submit Pengajuan Barang Jasa Persediaan",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response_temp,
              life: 3000,
            });
          }
      } catch(err) {
        this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
              life: 3000,
            });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Jenis
    async uploadTemplatePengajuanBarangJasaPersediaan(event) {
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("pengajuan_barang_jasa_file", event.files[0]);
      try{
        var response_temp =
        await PengajuanBarangJasaService.submitUploadTemplatePengajuanBarangJasa(
            formData
          );
        if (response_temp.status != null && response_temp.status == "success") {
          await this.pengajuanBarangJasaPersediaanInitialization();
          this.$toast.add({
            severity: "success",
            summary: "Sukses",
            detail: "Berhasil mengupload data Rekening Neraca Jenis",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response_temp,
            life: 3000,
          });
        }
      }catch(err) {
        this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
              life: 3000,
            });
      }
      this.loaderOverlay.hide();
    },
    // fungsi upload template Rekening Neraca Jenis
    async downloadTemplatePengajuanBarangJasaPersediaan() {
      this.loaderOverlay = this.$loading.show();
      var response_temp =
        await PengajuanBarangJasaService.submitDownloadTemplatePengajuanBarangJasa();
      const url = URL.createObjectURL(
        new Blob([response_temp.data], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "pengajuan_barang_jasa_template.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loaderOverlay.hide();
    },
    // temporari Pengajuan Barang Jasa
    // Upload data temporari Pengajuan Persediaan
    async pengajuanBarangJasaPersediaanTempUploadTemplate(event) {
      this.loaderOverlay = this.$loading.show();
      
      try{
        const file = event.files[0];
        const bf = await file.arrayBuffer();
        const wb = read(bf);
        const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        console.log(data);
        var data_temp = [];
        var status_error = false;
        var id = 0;
        data.forEach(datum => {
          var rekening_neraca = this.rekeningNeracaSubRincianObjekPersediaanData.find(item => (item.kode_rekening_neraca_akun == datum.kode_rekening_neraca_akun && item.kode_rekening_neraca_kelompok == datum.kode_rekening_neraca_kelompok && item.kode_rekening_neraca_jenis == datum.kode_rekening_neraca_jenis && item.kode_rekening_neraca_objek == datum.kode_rekening_neraca_objek && item.kode_rekening_neraca_rincian_objek == datum.kode_rekening_neraca_rincian_objek && item.kode_rekening_neraca_sub_rincian_objek == datum.kode_rekening_neraca_sub_rincian_objek));
          if(datum.kode_rekening_neraca_akun == 5){
            data_temp.push({
              id_temp : id,
              rekening_neraca : rekening_neraca,
              kode_rekening_neraca_akun : rekening_neraca.kode_rekening_neraca_akun == null ? null : rekening_neraca.kode_rekening_neraca_akun,
              kode_rekening_neraca_kelompok : rekening_neraca.kode_rekening_neraca_kelompok == null ? null : rekening_neraca.kode_rekening_neraca_kelompok,
              kode_rekening_neraca_jenis : rekening_neraca.kode_rekening_neraca_jenis == null ? null : rekening_neraca.kode_rekening_neraca_jenis,
              kode_rekening_neraca_objek : rekening_neraca.kode_rekening_neraca_objek == null ? null : rekening_neraca.kode_rekening_neraca_objek,
              kode_rekening_neraca_rincian_objek : rekening_neraca.kode_rekening_neraca_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_rincian_objek,
              nama_rekening_neraca_rincian_objek: rekening_neraca.nama_rekening_neraca_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_rincian_objek,
              kode_rekening_neraca_sub_rincian_objek : rekening_neraca.kode_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_sub_rincian_objek,
              nama_rekening_neraca_sub_rincian_objek: rekening_neraca.nama_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_sub_rincian_objek,
              nama_barang_pengajuan_barang_jasa : datum.nama_barang_pengajuan_barang_jasa == null ? null : datum.nama_barang_pengajuan_barang_jasa,
              ba_srt_nomor : datum.ba_srt_nomor == null ? null : datum.ba_srt_nomor,
              ba_srt_tanggal : datum.ba_srt_tanggal == null ? null : datum.ba_srt_tanggal,
              volume_barang_pengajuan_barang_jasa : datum.volume_barang_pengajuan_barang_jasa == null ? null : datum.volume_barang_pengajuan_barang_jasa,
              satuan_barang_pengajuan_barang_jasa : datum.satuan_barang_pengajuan_barang_jasa == null ? null : datum.satuan_barang_pengajuan_barang_jasa,
              harga_satuan_barang_pengajuan_barang_jasa : datum.harga_satuan_barang_pengajuan_barang_jasa == null ? null : datum.harga_satuan_barang_pengajuan_barang_jasa,
              // sumber_dana_pengajuan_barang_jasa: this.sumber_dana_pengajuan_barang_jasas.find(item => item.value == datum.sumber_dana_pengajuan_barang_jasa),
              sumber_dana_pengajuan_barang_jasa: datum.sumber_dana_pengajuan_barang_jasa == null ? null : datum.sumber_dana_pengajuan_barang_jasa,
              keterangan_pengajuan_barang_jasa : datum.keterangan_pengajuan_barang_jasa == null ? null : datum.keterangan_pengajuan_barang_jasa,
              kategori_pengajuan_barang_jasa : 'persediaan',
            });
          }
          id = id + 1;
          if(rekening_neraca == null){
            status_error = true;
          }
        });
        // console.log(data_temp);
        this.pengajuanBarangJasaPersediaanTempData = data_temp;
        if (status_error == false) {
         this.$toast.add({
            severity: "success",
            summary: "Sukses",
            detail: "Berhasil me LOAD data template",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Terdapat kode rekening yang tidak cocok",
            life: 3000,
          });
        }
      }catch(err) {
        this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
              life: 3000,
            });
      }
      this.loaderOverlay.hide();
    },
    // edit data di datatable pengajuan
    async pengajuanBarangJasaPersediaanTempOnRowEditSave(event) {
      let { newData, index } = event;
      var rekening_neraca = newData.rekening_neraca;
      console.log(rekening_neraca);
      try{
        this.pengajuanBarangJasaPersediaanTempData[index] = newData;
        this.pengajuanBarangJasaPersediaanTempData[index].ba_srt_tanggal = moment(newData.ba_srt_tanggal).format(moment.HTML5_FMT.DATE);
        this.pengajuanBarangJasaPersediaanTempData[index].nama_rekening_neraca_rincian_objek = rekening_neraca.nama_rekening_neraca_rincian_objek;
        this.pengajuanBarangJasaPersediaanTempData[index].nama_rekening_neraca_sub_rincian_objek = rekening_neraca.nama_rekening_neraca_sub_rincian_objek;
        this.pengajuanBarangJasaPersediaanTempData[index].kode_rekening_neraca_akun = rekening_neraca.kode_rekening_neraca_akun;
        this.pengajuanBarangJasaPersediaanTempData[index].kode_rekening_neraca_kelompok = rekening_neraca.kode_rekening_neraca_kelompok;
        this.pengajuanBarangJasaPersediaanTempData[index].kode_rekening_neraca_jenis = rekening_neraca.kode_rekening_neraca_jenis;
        this.pengajuanBarangJasaPersediaanTempData[index].kode_rekening_neraca_objek = rekening_neraca.kode_rekening_neraca_objek;
        this.pengajuanBarangJasaPersediaanTempData[index].kode_rekening_neraca_rincian_objek = rekening_neraca.kode_rekening_neraca_rincian_objek;
        this.pengajuanBarangJasaPersediaanTempData[index].kode_rekening_neraca_sub_rincian_objek = rekening_neraca.kode_rekening_neraca_sub_rincian_objek;
        this.$toast.add({
          severity: "success",
          summary: "Sukses",
          detail: "Berhasil edit data, silahkan kirim data",
          life: 5000,
        });
      }catch(err) {
        this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
              life: 3000,
            });
      }
    },
    // penghapusan baris dalam posisi pengeditan
    async pengajuanBarangJasaPersediaanTempConfirmationDeleteSelected(){
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data yang sedang di edit",
        header: "Konfirmasi Penghapusan Persediaan",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          //callback to execute when user confirms the action
          var findIndex = -1;
          this.pengajuanBarangJasaPersediaanTempSelectingRows.forEach((element) => {
            findIndex = this.pengajuanBarangJasaPersediaanTempData.findIndex(a => a.id_temp === element.id_temp)
            findIndex !== -1 && this.pengajuanBarangJasaPersediaanTempData.splice(findIndex , 1)
          });
          this.$toast.add({
            severity: "success",
            summary: "Sukses",
            detail: "Berhasil menghapus data Persediaan",
            life: 5000,
          });
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    // fungsi kirim data dari datatable terperiksa ke server
    async pengajuanBarangJasaPersediaanTempSendData(){
      this.loaderOverlay = this.$loading.show();
      const formData = new FormData();
      formData.append("data", JSON.stringify(this.pengajuanBarangJasaPersediaanTempSelectingRows));
      try{
        var response_temp =
        await PengajuanBarangJasaService.submitDataBulkStorePengajuanBarangJasa(
            formData
          );
        if (response_temp.status != null && response_temp.status == "success") {
          var findIndex = -1;
          this.pengajuanBarangJasaPersediaanTempSelectingRows.forEach((element) => {
            findIndex = this.pengajuanBarangJasaPersediaanTempData.findIndex(a => a.id_temp === element.id_temp)
            findIndex !== -1 && this.pengajuanBarangJasaPersediaanTempData.splice(findIndex , 1)
          });
          await this.pengajuanBarangJasaPersediaanTerkirimInitialization();
          this.$toast.add({
            severity: "success",
            summary: "Sukses",
            detail: "Berhasil mengirim data Pengajuan, silahkan check di Rincian",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response_temp,
            life: 3000,
          });
        }
      }catch(err) {
        this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
              life: 3000,
            });
      }
      this.loaderOverlay.hide();
    },
    // KUMPULAN FUNGSI UNTUK RINCIAN
    // inisiasi data
    async pengajuanBarangJasaPersediaanTerkirimInitialization(){
      // kode '5' menandakan persediaan
      var data = await PengajuanBarangJasaService.getPengajuanBarangJasaByKodeAkun(5);
      
      var data_temp = [];
      var id = 0;
      data.data.forEach(datum => {
        var rekening_neraca = this.rekeningNeracaSubRincianObjekPersediaanData.find(item => (item.kode_rekening_neraca_akun == datum.kode_rekening_neraca_akun && item.kode_rekening_neraca_kelompok == datum.kode_rekening_neraca_kelompok && item.kode_rekening_neraca_jenis == datum.kode_rekening_neraca_jenis && item.kode_rekening_neraca_objek == datum.kode_rekening_neraca_objek && item.kode_rekening_neraca_rincian_objek == datum.kode_rekening_neraca_rincian_objek && item.kode_rekening_neraca_sub_rincian_objek == datum.kode_rekening_neraca_sub_rincian_objek));
        data_temp.push({
          id_temp : id,
          rekening_neraca : rekening_neraca,
          kode_rekening_neraca_akun : rekening_neraca.kode_rekening_neraca_akun == null ? null : rekening_neraca.kode_rekening_neraca_akun,
          kode_rekening_neraca_kelompok : rekening_neraca.kode_rekening_neraca_kelompok == null ? null : rekening_neraca.kode_rekening_neraca_kelompok,
          kode_rekening_neraca_jenis : rekening_neraca.kode_rekening_neraca_jenis == null ? null : rekening_neraca.kode_rekening_neraca_jenis,
          kode_rekening_neraca_objek : rekening_neraca.kode_rekening_neraca_objek == null ? null : rekening_neraca.kode_rekening_neraca_objek,
          kode_rekening_neraca_rincian_objek : rekening_neraca.kode_rekening_neraca_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_rincian_objek,
          nama_rekening_neraca_rincian_objek: rekening_neraca.nama_rekening_neraca_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_rincian_objek,
          kode_rekening_neraca_sub_rincian_objek : rekening_neraca.kode_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.kode_rekening_neraca_sub_rincian_objek,
          nama_rekening_neraca_sub_rincian_objek: rekening_neraca.nama_rekening_neraca_sub_rincian_objek == null ? null : rekening_neraca.nama_rekening_neraca_sub_rincian_objek,
          nama_barang_pengajuan_barang_jasa : datum.nama_barang_pengajuan_barang_jasa == null ? null : datum.nama_barang_pengajuan_barang_jasa,
          ba_srt_nomor : datum.ba_srt_nomor == null ? null : datum.ba_srt_nomor,
          sertifikat_tkdn_pengajuan_barang_jasa: datum.sertifikat_tkdn_pengajuan_barang_jasa == null ? null : datum.sertifikat_tkdn_pengajuan_barang_jasa,
          persentase_pdn_pengajuan_barang_jasa: datum.persentase_pdn_pengajuan_barang_jasa == null ? null : datum.persentase_pdn_pengajuan_barang_jasa,
          ba_srt_tanggal : datum.ba_srt_tanggal == null ? null : datum.ba_srt_tanggal,
          volume_barang_pengajuan_barang_jasa : datum.volume_barang_pengajuan_barang_jasa == null ? null : datum.volume_barang_pengajuan_barang_jasa,
          satuan_barang_pengajuan_barang_jasa : datum.satuan_barang_pengajuan_barang_jasa == null ? null : datum.satuan_barang_pengajuan_barang_jasa,
          harga_satuan_barang_pengajuan_barang_jasa : datum.harga_satuan_barang_pengajuan_barang_jasa == null ? null : datum.harga_satuan_barang_pengajuan_barang_jasa,
          // sumber_dana_pengajuan_barang_jasa: this.sumber_dana_pengajuan_barang_jasas.find(item => item.value == datum.sumber_dana_pengajuan_barang_jasa),
          sumber_dana_pengajuan_barang_jasa: datum.sumber_dana_pengajuan_barang_jasa == null ? null : datum.sumber_dana_pengajuan_barang_jasa,
          keterangan_pengajuan_barang_jasa : datum.keterangan_pengajuan_barang_jasa == null ? null : datum.keterangan_pengajuan_barang_jasa,
          kategori_pengajuan_barang_jasa : datum.kategori_pengajuan_barang_jasa == null ? null : datum.kategori_pengajuan_barang_jasa,
          status_persetujuan_satker_pengajuan_barang_jasa : parseInt(datum.status_persetujuan_satker_pengajuan_barang_jasa),
          status_persetujuan_bpkad_pengajuan_barang_jasa : parseInt(datum.status_persetujuan_bpkad_pengajuan_barang_jasa),
          status_persetujuan_laporan_pengajuan_barang_jasa : parseInt(datum.status_persetujuan_laporan_pengajuan_barang_jasa),
          id_pengajuan_barang_jasa : parseInt(datum.id_pengajuan_barang_jasa),
        });
        id = id + 1;
      });
      this.pengajuanBarangJasaPersediaanTerkirimData = data_temp;
    },
    // edit data di datatable pengajuan
    async pengajuanBarangJasaPersediaanTerkirimOnRowEditSave(event) {
      this.loaderOverlay = this.$loading.show();
      let { newData, index } = event;
      var rekening_neraca = newData.rekening_neraca;
      // console.log(rekening_neraca);
      // console.log(newData);
      var data_temp = {};
      try{
        data_temp = newData;
        data_temp.ba_srt_tanggal = moment(data_temp.ba_srt_tanggal).format();
        data_temp.nama_rekening_neraca_rincian_objek = rekening_neraca.nama_rekening_neraca_rincian_objek;
        data_temp.nama_rekening_neraca_sub_rincian_objek = rekening_neraca.nama_rekening_neraca_sub_rincian_objek;
        data_temp.kode_rekening_neraca_akun = rekening_neraca.kode_rekening_neraca_akun;
        data_temp.kode_rekening_neraca_kelompok = rekening_neraca.kode_rekening_neraca_kelompok;
        data_temp.kode_rekening_neraca_jenis = rekening_neraca.kode_rekening_neraca_jenis;
        data_temp.kode_rekening_neraca_objek = rekening_neraca.kode_rekening_neraca_objek;
        data_temp.kode_rekening_neraca_rincian_objek = rekening_neraca.kode_rekening_neraca_rincian_objek;
        data_temp.kode_rekening_neraca_sub_rincian_objek = rekening_neraca.kode_rekening_neraca_sub_rincian_objek;
        var response_data =
        await PengajuanBarangJasaService.submitEditedPengajuanBarangJasa(
            data_temp
          );
        if (response_data.status != null && response_data.status == "success") {
          await this.pengajuanBarangJasaPersediaanTerkirimInitialization();
          this.$toast.add({
            severity: "success",
            summary: "Sukses",
            detail: "Berhasil memutakhirkan data Pengajuan Barang Jasa (Persediaan)",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response_data,
            life: 7000,
          });
        }
      }catch(err) {
        this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
              life: 3000,
            });
      }
      this.loaderOverlay.hide();
    },
    // penghapusan baris dalam posisi pengeditan
    async pengajuanBarangJasaPersediaanTerkirimConfirmationDeleteSelected(){
      this.$confirm.require({
        message:
          "Apa yakin ingin menghapus data yang dipilih",
        header: "Konfirmasi Penghapusan Pengajuan Barang di Server",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Hapus Data",
        rejectLabel: "Cancel",
        acceptClass: "orange--text",
        accept: async () => {
          this.loaderOverlay = this.$loading.show();
          try{
            //callback to execute when user confirms the action
            var selected_ids = [];
            this.pengajuanBarangJasaPersediaanTerkirimSelectingRows.forEach((element) => {
                selected_ids.push(element.id_pengajuan_barang_jasa);
            });
            var response_temp =
                await PengajuanBarangJasaService.deletePengajuanBarangJasaBySelectedIds(
                    selected_ids
                );

            if (
                response_temp.status != null &&
                response_temp.status == "success"
            ) {
                await this.pengajuanBarangJasaPersediaanTerkirimInitialization();
                this.$toast.add({
                    severity: "success",
                    summary: "Sukses",
                    detail: response_temp.message,
                    life: 5000,
                });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: response_temp,
                    life: 3000,
                });
            }
          }catch(err) {
            this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: err,
                  life: 3000,
                });
          }
          this.loaderOverlay.hide();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.field {
  width: 100%;
}
.ditolak {
    font-weight: 900;
    color: #ac1818;
}

.belum_disetujui {
    font-weight: 900;
    color: #ab6d11;
}

.setuju {
    font-weight: 900;
    color: #17501a;
}

.undefined_status {
    font-weight: 900;
    color: #4d0f60;
}

.pengajuan_kembali {
    font-weight: 900;
    color: #945c0e;
}
</style>
